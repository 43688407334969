/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 
 * 1) Import your constant like this:
 *    // Action - description
 *    export function yourAction(var) {
 *        return { 
 * 	          type: YOUR_ACTION_CONSTANT, 
 *            var
 *        }
 *    }
 */
import * as constant from './constants';


// Action - request data
export const getDataRequested = () => ({
	type: constant.GET_HOME_DATA_REQUESTED
});

export const getDataRequestedDone = data => ({
	type: constant.GET_HOME_DATA_REQUESTED_DONE, 
	data
});
