import { injectGlobal } from 'styled-components';

/* eslint no-unused-expressions: 0 */
injectGlobal`
  * {
        text-shadow: none;
        border: none;
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    body {
        margin: 0;
        padding: 0;
        font-family: sans-serif;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 400;
    }

    button,
    input {
        appearance: none;
    }

    // ul, ol, li {
    //     margin: 0;
    //     padding: 0;
    //     list-style: none;
    // }

    .items-list {

        ul {
            margin: 0;
            padding: 0;
        }

        li {
            margin: 0;
            padding: 0;
            display: block;
            line-height: 20px;
            padding-bottom: 15px;
            padding-left: 15px;
            position: relative;
            color: inherit;
            font-size: 14px;
            list-style: none;

            &:last-of-type {
                padding-bottom: 0;
            }

            strong {
                font-weight: 900;
                color: #ff0000;
            }

            @media screen and (min-width: 941px) {
                font-size: 24px;
                padding-bottom: 20px;
                line-height: 30px;
            }

            &:before {
                content: '';
                position: absolute;
                top: 5px;
                left: 0;
                width: 4px;
                height: 4px;
                background: #191f22;
                border-radius: 50%;

                @media screen and (min-width: 941px) {
                    top: 10px;
                }
            }
        }
    }

    .hide-desktop {
        display: inline-block;

        @media screen and (min-width: 941px) {
            display: none !important;
        }
    }
    .hide-mobile {
        display: inline-block;

        @media screen and (max-width: 940px) {
            display: none !important;
        }
    }

    .scroll-to-top {
        position: fixed;
        bottom: 35px;
        right: 30px;
        cursor: pointer;
        z-index: 1200;
        width: 56px;
        height: 56px;

        @media screen and (max-width: 940px) {
            bottom: 45px;
            right: 20px;
            width: 36px;
            height: 36px;
        }
    }
`;
