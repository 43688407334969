// React librarys
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Import Assets
import './styles.css';

// Import Translations
import messages from './messages';


/* Component */
export const VideoModal = props => (
    <div className="video-modal">
        <div className="content">
            <div id={'video-close-link'} className="close" onClick={props.closeModal}>
                <FormattedMessage {...messages.close}>{msg => msg}</FormattedMessage>
            </div>
            <iframe src={`https://youtube.com/embed/${props.link}?rel=0&amp;showinfo=0`} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
        </div>
        <div id={'video-close-bg'} className="bg" onClick={props.closeModal}></div>
    </div>
);
