// React component import
import axios from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";

// Import Actions
import * as actions from "./actions";

// Import helpers
import * as helper from "./../../helpers";
import { getStorage } from "./../../utils/localStorage";
import { AvSubscriptions } from "material-ui/svg-icons";

// Worker Saga - getCountriesList
function* getCountriesList(action) {
  const requestLink = `${helper.apiLink}/api/promo/GetCountriesList/${action.selectedLanguage}`;

  try {
    // Start Data Response and run loader
    const response = yield call(axios, {
      method: "get",
      url: requestLink,
      responseType: "json",
      headers: {
        Authorization: getStorage("Authorization").authorization
      }
    });

    const data = yield response.data;
    if (response.status === 200) {
      // console.log('SAGA - getCountriesList:', data);
      yield put(actions.getCountriesListDone(data));
    } else {
      // console.log(`SAGA - getCountriesList - ERROR - ${data.ErrorMessage}`);
    }
  } catch (e) {
    // Stop Data Response on serwer errors
    // console.log(`Error: ${e.response.status} - ${e.response.statusText}`);
  }
}

// Worker Saga - getGetFleetSizeListDone
function* getGetFleetSizeList(action) {
  const requestLink = `${helper.apiLink}/api/promo/GetFleetSizes`;

  try {
    // Start Data Response and run loader
    const response = yield call(axios, {
      method: "get",
      url: requestLink,
      responseType: "json",
      headers: {
        Authorization: getStorage("Authorization").authorization
      }
    });

    const data = yield response.data;
    if (response.status === 200) {
      yield put(actions.getGetFleetSizeListDone(data));
    } else {
      // console.log(`SAGA - getGetFleetSizeListDone - ERROR - ${data.ErrorMessage}`);
    }
  } catch (e) {
    // Stop Data Response on serwer errors
    // console.log(`Error: ${e.response.status} - ${e.response.statusText}`);
  }
}

// Worker Saga - getBusinessTypesList
function* getBusinessTypesList(action) {
  const requestLink = `${helper.apiLink}/api/promo/GetBusinessTypesList/${action.selectedLanguage}`;

  try {
    // Start Data Response and run loader
    const response = yield call(axios, {
      method: "get",
      url: requestLink,
      responseType: "json",
      headers: {
        Authorization: getStorage("Authorization").authorization
      }
    });

    const data = yield response.data;
    if (response.status === 200) {
      // console.log('SAGA - getBusinessTypesList:', data);
      yield put(actions.getBusinessTypesListDone(data));
    } else {
      // console.log(`SAGA - getBusinessTypesList - ERROR - ${data.ErrorMessage}`);
    }
  } catch (e) {
    // Stop Data Response on serwer errors
    // console.log(`Error: ${e.response.status} - ${e.response.statusText}`);
  }
}

// Worker Saga - saveFleetDetails
function* saveFleetDetails(action) {
  const requestLink = `${helper.apiLink}/api/promo/SaveFleetDetails`;

  yield put(actions.setSaveFleetDetailsLoading(true));

  try {
    // Start Data Response and run loader
    const response = yield call(axios, {
      method: "post",
      url: requestLink,
      responseType: "json",
      headers: {
        Authorization: getStorage("Authorization").authorization
      },
      data: {
        FirstName: action.firstName,
        LastName: action.lastName,
        EmailAddress: action.emailAddress,
        Password: action.password,
        ConfirmPassword: action.confirmPassword,
        FleetName: action.fleetName,
        PhoneNumber: action.operatorPhone,
        InternationalCode: action.internationalCode,
        PhoneCountryCode: action.countryPhoneCode,
        Country: action.country,
        TermsConditions: action.termsCondition,
        Language: action.pageLanguage,
        AcceptMarketing: action.marketingConsent,
        FleetSize: action.fleetSize,
        BusinessType: action.businessType,
        BusinessTypeText: action.businessTypeText
      }
    });

    const data = yield response.data;
    if (response.status === 200) {
      if (data.Result) {
        window.dataLayer.push({
          event: "registration",
          registration: "success"
        });
        yield put(actions.saveFleetDetailsSuccess(true));
        window.location.href = `${helper.apiLink}/loginAfterRegistration?pretoken=${data.Id}`;
      } else {
        window.dataLayer.push({
          event: "registration",
          registration: "failure"
        });
        yield put(actions.saveFleetDetailsSuccess(false));
        yield put(
          actions.fieldError(
            "emailAddress",
            helper.errorCodes.emailAlreadyExists
          )
        );
      }
      yield put(actions.setSaveFleetDetailsLoading(false));
    } else {
      yield put(actions.setSaveFleetDetailsLoading(false));
      // console.log(`SAGA - getTCData - ERROR - ${data.ErrorMessage}`);
    }
  } catch (e) {
    yield put(actions.setSaveFleetDetailsLoading(false));
    // Stop Data Response on serwer errors
    // console.log(`Error: ${e.response.status} - ${e.response.statusText}`);
  }
}

// Worker Saga - getCountriesList
function* getCountryByIP(action) {
  const requestLink = "https://api.ipgeolocation.io/ipgeo?apiKey=5fd6fe47353f4cbcb242b1b94772afa0";

  try {
    // Start Data Response and run loader
    const response = yield call(axios, {
      method: "get",
      url: requestLink,
      responseType: "json"
    });

    const data = yield response.data;
    if (response.status === 200) {
      //console.log("getCountryByIp:", data);
      yield put(actions.getCountryByIPDone(data));
    }
  } catch (e) {
  }
}

// Watcher saga - listen for request updates
// and on specyfic actions and run our worker saga
export default function* saga() {
  yield all([
    takeLatest(actions.getCountriesList().type, getCountriesList),
    takeLatest(actions.getGetFleetSizeList().type, getGetFleetSizeList),
    takeLatest(actions.getBusinessTypesList().type, getBusinessTypesList),
    takeLatest(actions.saveFleetDetails().type, saveFleetDetails),
    takeLatest(actions.getCountryByIP().type, getCountryByIP)
  ]);
}
