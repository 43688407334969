import { defineMessages } from "react-intl";

export default defineMessages({
  metaTitle: {
    id: "page.SignUp.Meta.Title",
    defaultMessage:
      "FleetPulse fleet management solution with vehicle checklist app andTPMS | Bridgestone"
  },
  metaDescription: {
    id: "page.SignUp.Meta.Description",
    defaultMessage:
      "Let your drivers record and manage defects with an easy vehicle checklist app, while you monitor your fleet and manage service bookings from an online dashboard – helping you stay one step ahead of your vehicle maintenance activities and avoid breakdowns."
  },
  metaKeywords: {
    id: "page.SignUp.Meta.Keywords",
    defaultMessage:
      "fleet management solution, vehicle checklist, TPMS, Bridgestone, service booking, vehicle maintenance, breakdowns"
  },

  headerTitle: {
    id: "page.SignUp.Header.Title",
    defaultMessage: "Complete your details below<br/>to start your free trial"
  },
  headerTitleComplite: {
    id: "page.SignUp.Header.Title.Complite",
    defaultMessage: "Thanks for registering for FleetPulse"
  },
  headerTitleFirstStep: {
    id: "page.SignUp.Header.Title.FirstStep",
    defaultMessage: "Try FleetPulse for free"
  },
  headerTitleFirstStepSubtitle: {
    id: "page.SignUp.Header.Title.FirstStep.Subtitle",
    defaultMessage: "Enjoy FleetPulse free until 2021"
  },
  headerTitleSecondStep: {
    id: "page.SignUp.Header.Title.SecondStep",
    defaultMessage: "Try FleetPulse for free"
  },
  headerTitleSecondStepSubtitle: {
    id: "page.SignUp.Header.Title.SecondStep.Subtitle",
    defaultMessage: "Enjoy FleetPulse free until 2021"
  },
  headerSubTitle: {
    id: "page.SignUp.Header.SubTitle",
    defaultMessage: "Register for FleetPulse"
  },

  stepsTitle: {
    id: "page.SignUp.Header.stepsTitle",
    defaultMessage: "Step {count} of 2"
  },

  registerBTN: {
    id: "page.SignUp.RegisterBtn",
    defaultMessage: "Register for a free trial"
  },
  formHeader: {
    id: "page.SignUp.FormHeader",
    defaultMessage: "Register for the Bridgestone app"
  },
  businessDetails: {
    id: "page.SignUp.BusinessDetails",
    defaultMessage: "Business details"
  },
  required: {
    id: "page.SignUp.Required",
    defaultMessage: "Required fields"
  },
  firstName: {
    id: "page.SignUp.FirstName",
    defaultMessage: "First name"
  },
  lastName: {
    id: "page.SignUp.LastName",
    defaultMessage: "Last name"
  },
  emailAddress: {
    id: "page.SignUp.EmailAddress",
    defaultMessage: "Email address"
  },
  password: {
    id: "page.SignUp.password",
    defaultMessage: "Set password"
  },
  confirmPassword: {
    id: "page.SignUp.confirmPassword",
    defaultMessage: "Confirm password"
  },
  fleetName: {
    id: "page.SignUp.FleetName",
    defaultMessage: "Fleet name"
  },
  enterFleetName: {
    id: "page.SignUp.EnterFleetName",
    defaultMessage: "Enter fleet name..."
  },
  fleetSize: {
    id: "page.SignUp.FleetSize",
    defaultMessage: "Number of vehicles"
  },
  phone: {
    id: "page.SignUp.Phone",
    defaultMessage: "Phone number"
  },
  marketingConsent: {
    id: "page.SignUp.MarketingConsent",
    defaultMessage:
      "I agree to receiving promotional communications from Bridgestone Europe in accordance with our"
  },
  termsOfConsent: {
    id: "page.SignUp.TermsOfConsent",
    defaultMessage: "Terms of Consent"
  },
  businessType: {
    id: "page.SignUp.BusinessType",
    defaultMessage: "Business type"
  },
  country: {
    id: "page.SignUp.Country",
    defaultMessage: "Country"
  },
  agreements: {
    id: "page.SignUp.Agreements",
    defaultMessage:
      "By clicking sign up now, you agree to our {termsLink} and {privacyLink}."
  },
  nextForm: {
    id: "page.SignUp.nextForm",
    defaultMessage: "Next"
  },
  previousForm: {
    id: "page.SignUp.previousForm",
    defaultMessage: "Previous step"
  },
  saveForm: {
    id: "page.SignUp.SaveForm",
    defaultMessage: "Sign up now"
  },
  privacy: {
    id: "page.SignUp.Privacy",
    defaultMessage: "Privacy Policy"
  },
  privacyLink: {
    id: "pdf.privacyLink",
    defaultMessage: "EN_Privacy_Policy"
  },
  terms: {
    id: "page.SignUp.Terms",
    defaultMessage: "Terms and Conditions"
  },
  closeBTN: {
    id: "button.SignUp.Close",
    defaultMessage: "Close"
  },
  changesSaved: {
    id: "page.SignUp.ChangesSaved",
    defaultMessage: "Changes Saved"
  },
  changesNotSaved: {
    id: "page.SignUp.ChangesNotSaved",
    defaultMessage: "Changes not saved, please correct errors"
  },

  listItemTitle: {
    id: "page.SignUp.List.Title",
    defaultMessage: "The benefits from using FleetPulse:"
  },
  listItem1: {
    id: "page.SignUp.List.Item1",
    defaultMessage:
      "Track your fleet’s health with simple digital checklists, tailored to your needs."
  },
  listItem2: {
    id: "page.SignUp.List.Item2",
    defaultMessage:
      "Manage maintenance activities and bookings through a user-friendly interface."
  },
  listItem3: {
    id: "page.SignUp.List.Item3",
    defaultMessage: "Maintain optimal tyre pressure with our optional TPMS kit."
  },

  signUpConfirmCopyTitle: {
    id: "page.SignUp.ConfirmCopyTitle",
    defaultMessage: "Thank for registering for FleetPulse."
  },
  signUpConfirmCopy1: {
    id: "page.SignUp.ConfirmCopy1",
    defaultMessage:
      "You’ll shortly receive your login details so you can get started."
  },
  signUpConfirmCopy2: {
    id: "page.SignUp.ConfirmCopy2",
    defaultMessage:
      "If you want to use our Tyre Pressure Monitoring System (TPMS), please contact us to buy your dongles and sensors."
  },
  signUpConfirmBtnCopy: {
    id: "page.SignUp.Confirm.BtnCopy",
    defaultMessage: "Contact us"
  },
  signUpConfirmManualCopy: {
    id: "page.SignUp.ConfirmManualCopy",
    defaultMessage:
      'Download the <a id="signup-manual-link" class="manual-link" href="/files/FP_Manual_APP_EN.pdf" target="_blank">FleetPulse manual</a> to learn more about all the features available.'
  },

  selectPlaceholder: {
    id: "select.Placeholder",
    defaultMessage: "Select..."
  },
  selectNoResultFound: {
    id: "select.NoResultFound",
    defaultMessage: "No result found"
  },

  errorRequired: {
    id: "errors.Required",
    defaultMessage: "This is a required field"
  },
  errorInvalidPhone: {
    id: "errors.InvalidPhone",
    defaultMessage: "Invalid Phone Number Entered"
  },
  errorInvalidCharacters: {
    id: "errors.InvalidCharacters",
    defaultMessage: "Invalid Characters Entered"
  },
  errorInvalidEmail: {
    id: "errors.InvalidEmail",
    defaultMessage: "Invalid email character added"
  },
  errorInvalidPassword: {
    id: "errors.InvalidPassword",
    defaultMessage: "Invalid password"
  },
  errorInvalidConfirmPassword: {
    id: "errors.InvalidConfirmPassword",
    defaultMessage: "Passwords are not the same"
  },
  errorEmailAlreadyExists: {
    id: "errors.EmailAlreadyExists",
    defaultMessage: "This user already exists"
  },

  signUpInfoBox1Header: {
    id: "page.SignUp.infoBoxRight.Box1Header",
    defaultMessage: "3 easy steps to register your fleet with Fleetpulse."
  },
  signUpInfoBox2Header: {
    id: "page.SignUp.infoBoxRight.Box2Header",
    defaultMessage:
      "Drivers can start using the FleetPulse app in a couple of taps."
  },
  signUpInfoBox3Header: {
    id: "page.SignUp.infoBoxRight.Box3Header",
    defaultMessage:
      "We offer a range of simple pricing options for all fleet sizes once your trial is finished."
  },

  signUpInfoBoxCopy1: {
    id: "page.SignUp.infoBoxRight.Copy1",
    defaultMessage: "Register your fleet at fleetpulse.app."
  },
  signUpInfoBoxCopy2: {
    id: "page.SignUp.infoBoxRight.Copy2",
    defaultMessage:
      "Follow the link in the email we send you. To complete your registration, log in and configure your business details, your fleet and your preferences."
  },
  signUpInfoBoxCopy3: {
    id: "page.SignUp.infoBoxRight.Copy3",
    defaultMessage:
      "Create your fleet by adding your vehicles and other users. Users will then receive emails with their access details."
  },

  signUpInfoBoxCopy4: {
    id: "page.SignUp.infoBoxRight.Copy4",
    defaultMessage: "Visit Google Play and download the FleetPulse app."
  },
  signUpInfoBoxCopy5: {
    id: "page.SignUp.infoBoxRight.Copy5",
    defaultMessage:
      "When your manager sets up your account you’ll get an email with your log in details. Use these to log in to the app."
  },
  signUpInfoBoxCopy6: {
    id: "page.SignUp.infoBoxRight.Copy6",
    defaultMessage:
      "Select the vehicle you are driving and start your daily check. You can change the vehicle at any time. You can also select which language you’d like to use."
  },

  signUpInfoBoxCopy7: {
    id: "page.SignUp.infoBoxRight.Copy7",
    defaultMessage:
      "FleetPulse App subscription 1-20 vehicles<br/><strong>3.5€ per month/vehicle</strong>"
  },
  signUpInfoBoxCopy8: {
    id: "page.SignUp.infoBoxRight.Copy8",
    defaultMessage:
      "FleetPulse App subscription 21-100 vehicles<br/><strong>2.5€ per month/vehicle</strong>"
  },
  signUpInfoBoxCopy9: {
    id: "page.SignUp.infoBoxRight.Copy9",
    defaultMessage:
      'FleetPulse App subscription<br/><strong>&gt;100 vehicles</strong><br/><a href="https://help.fleetpulse.app/en/support/tickets/new">Contact us</a>'
  },
  signUpInfoBoxCopy10: {
    id: "page.SignUp.infoBoxRight.Copy10",
    defaultMessage:
      'FleetPulse App subscription<br/>For every vehicle equipped with our TPMS, regardless the fleet size<br/><strong>2€ per month/vehicle</strong><br/>Learn more about <a href="/en/faqs#15">TPMS pricing</a>'
  },
  signUpInfoBoxCopy3ExtraCopy: {
    id: "page.SignUp.infoBoxRight.Copy3.ExtraCopy",
    defaultMessage:
      'If you have any questions about the specific cost of your fleet, please let us know <a href="https://help.fleetpulse.app/en/support/tickets/new">here.</a>'
  },
  formConfirmationCopy: {
    id: "page.SignUp.FormConfirmationCopy",
    defaultMessage:
      "We have sent a confirmation email to <strong>{userEmail}</strong> which you will need to activate your account. Check your junk/spam folder if you haven’t received it."
  },

  phoneTooltipTitle: {
    id: "page.SignUp.phoneTooltipTitle",
    defaultMessage: "Why do we need this?"
  },
  phoneTooltipCopy: {
    id: "page.SignUp.phoneTooltipCopy",
    defaultMessage:
      "We need your phone number in case we need to call you back."
  },
  phoneNumber: {
    id: "page.SignUp.phoneNumber",
    defaultMessage:
      'Do you have any questions? Call us on <a href="tel:{phoneNumber}" class="call-us-tel">{phoneNumber}</a> or <a href="mailto:{emailAddress}" class="call-us-email">contact us</a>'
  },
  emailAddressCta: {
    id: "page.SignUp.emailAddressCta",
    defaultMessage:
      'Do you have any questions? <a href="{emailAddress}" target="_blank" class="call-us-email">Contact us</a>'
  }
});
