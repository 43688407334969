import ReactSelect from "react-select";
import styled from 'styled-components';
import 'react-flags-select/scss/react-flags-select.scss';


export const CustomeSelect = styled(ReactSelect)`
    &.Select {
        width: 100px;
        text-align: left;
        font-size: 16px !important;
        font-family: Roboto, sans-serif !important;
        color: #424242;
        position: absolute;
        top: 5px;
        right: 16px;

        &.is-open:after {
            transform: scaleX(1);
        }

        &.is-error:after {
            transform: scaleX(1);
            // background: #ff0000;
        }

        &.is-focused:not(.is-open) > .Select-control {
            border: none;
            box-shadow: none;
        }

        &:before, &:after {
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
        }

        &:before {
            // background: #999999;
        }

        &:after {
            transform: scaleX(0);
            // background: #00a8e5;
            transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        }

        &.select-error:after {
            // background: #ff0000;
            transform: scaleX(1);
        }

        .Select-control {
            height: 40px;
            border: none;
            // background: #f2f2f2;
            border-radius: 0;
            border-radius: 2px;
            box-shadow: none !important;
        }

        .Select-value-label {
            color: #424242 !important;
            line-height: 1.4;
            display: block;
        }

        .Select-value {
            padding: 10px;
        }

        .Select-input {
            height: 100%;
            width: 100%;
            line-height: 1.15;
            padding: 11px;
        }

        .Select-arrow {
            border: none;
            width: 16px;
            height: 8px;
            top: -1px;
            overflow: hidden;
            position: relative;

            &:before,
            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                background: black;
                left: 0;
                top: 7px;
            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
        &.is-open .Select-arrow {
            top: 0;
        }
        &.is-open .Select-arrow, .Select .Select-arrow-zone:hover > .Select-arrow {
            border-color: darken(#444, 5%) transparent transparent;
        }

        &.is-open > .Select-control .Select-arrow {
            &:before,
            &:after {
                top: 0;
            }
        }

        .Select-input > input {
            line-height: 1.15;
            padding: 0;
        }

        .Select-menu-outer {
            border-radius: 0;
            /* border-color: #ddd; */
            border: none;
            z-index: 200;
            background: rgba(255, 255, 255, 0.8);

            .Select-option {
                background-color: transparent;
            }
        }

        .Select-placeholder {
            line-height: 42px;
            padding: 0 20px;
        }
    }
`