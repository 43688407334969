// React libraries
import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import {SlideDown} from 'react-slidedown';

// Import Components
import { ExpandCollapseButton } from './../ExpandCollapseButton';

// Import Styled Components
 import { FaqWrapper, FaqQuestion, FaqAnswer } from './styles';

/* Component */
export const FaqComponent = props => (
    <FaqWrapper ref={props.scrollRef}>
        <FaqQuestion id={`question-${props.questionid ? props.questionid : 0}`} onClick={props.expand}>
            <FormattedMessage {...props.question}></FormattedMessage>
            <ExpandCollapseButton elementid={props.questionid} expanded={props.expanded}/>
        </FaqQuestion>
        <SlideDown>
        {    
            props.expanded &&
            <FaqAnswer>
                <FormattedHTMLMessage {...props.answer}></FormattedHTMLMessage>
                {props.openVideoModal}
            </FaqAnswer>
        }
        </SlideDown> 
    </FaqWrapper>
);
