/*
 * Component Reducers
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state. To add a new action, add it to the switch statement
 * in the reducer function.
 *
 * Example:
 * function appReducer(state = initialState, action) {
 *    switch (action.type) {
 *       case LOAD_REPOS:
 *          return state
 *          .set('loading', true)
 *          .set('error', false);
 *       default:
 *          return state;
 *    }
 * }
 * export default appReducer;
 */
import { fromJS } from "immutable";
import * as constant from "./constants";

// Create component initial state
const initialState = fromJS({
  firstName: "",
  firstNameError: "",
  lastName: "",
  lastNameError: "",
  emailAddress: "",
  emailAddressError: "",
  password: "",
  passwordError: "",
  confirmPassword: "",
  confirmPasswordError: "",
  internationalCode: "",
  internationalCodeError: "",
  countryPhoneCode: "",
  operatorPhone: "",
  operatorPhoneError: "",
  fleetName: "",
  fleetNameError: "",
  fleetSize: "",
  fleetSizeError: "",
  businessType: "",
  businessTypeError: "",
  businessTypeText: "",
  businessTypeTextError: "",
  businessTypeOptions: [],
  country: "",
  countryError: "",
  countryOptions: [],
  fleetSize: "",
  fleetSizeError: "",
  fleetSizeOptions: [],
  agreement: true,
  agreementError: "",
  modalTypes: null,
  modalContent: null,
  savefleetDetailsLoading: false,
  saveFleetDetailsSuccess: null,
  countryCodeByIp: null
});

// Component reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    // REDUCER - CHANGE_INPUT_VALUE
    case constant.CHANGE_INPUT_VALUE:
      return state
        .set(action.inputName, action.inputValue)
        .set(`${action.inputName}Error`, "");

    // REDUCER - FIELD_ERROR
    case constant.FIELD_ERROR:
      return state.set(`${action.fieldName}Error`, action.errorCopy);

    // REDUCER - CHANGE_SELECT_VALUE
    case constant.CHANGE_SELECT_VALUE:
      return state
        .set(action.selectName, action.selectValue)
        .set(`${action.selectName}Error`, "");

    // REDUCER - CHANGE_RADIO_INPUT
    case constant.CHANGE_RADIO_INPUT:
      return state
        .set(action.radioName, action.radioValue)
        .set(`${action.radioName}Error`, "");

    // REDUCER - CLEAR_ERRORS
    case constant.CLEAR_FORM:
      return initialState;

    // REDUCER - GET_FLEET_SIZES_LIST
    case constant.GET_FLEET_SIZE_LIST_DONE:
      const fletSizes = action.fleetSizeList;
      const fleetSizeList = fletSizes
        ? fletSizes.map(item => ({
          value: item.Code,
          label: item.Name
        }))
        : [];
      return state.set("fleetSizeOptions", fleetSizeList);

    // REDUCER - GET_COUNTRIES_LIST_DONE
    case constant.GET_COUNTRIES_LIST_DONE:
      const countries = action.countriesList;
      const countriesList = countries
        ? countries.map(item => ({
          value: item.Code,
          label: item.Name
        }))
        : [];
      return state.set("countryOptions", countriesList);

    // REDUCER - GET_BUSINESS_TYPES_LIST_DONE
    case constant.GET_BUSINESS_TYPES_LIST_DONE:
      const businessTypes = action.businessTypesList;
      const businessTypesList = businessTypes
        ? businessTypes.map(item => ({
          value: item.Code,
          label: item.Name
        }))
        : [];

      return state.set("businessTypeOptions", businessTypesList);

    // REDUCER - SET_SAVE_FLEET_DETAILS_LOADING
    case constant.SET_SAVE_FLEET_DETAILS_LOADING:
      return state.set("savefleetDetailsLoading", action.value);

    // REDUCER - SAVE_FLEET_DETAILS_SUCCESS
    case constant.SAVE_FLEET_DETAILS_SUCCESS:
      return state.set("saveFleetDetailsSuccess", action.value);

    // REDUCER - SET_NEW_COUNTRY_PHONE_CODE
    case constant.SET_NEW_COUNTRY_PHONE_CODE:
      return state.set("countryPhoneCode", action.phoneCode);

    case constant.GET_COUNTRY_BY_IP_DONE:
      if (!action || !action.data || !action.data.country_code2)
        return state;
      let lang = action.data.country_code2.toUpperCase();
      if (lang === 'GB')
        lang = 'EN';
      console.log("lang: ", lang);
      return state.set("countryCodeByIp", lang);

    // REDUCER - return initial state
    default:
      return state;
  }
}
