/*
 * This is tricky one ; )
 * Selectors are functions to select state from
 * our redux store. It's help us clean code and
 * end write less on our components.
 *
 * For example;
 *
 * const selectGlobal = state => state.get('global');
 * const makeSelectLoading = () => createSelector(
 *    selectGlobal,
 *    globalState => globalState.get('loading')
 * );
 * export {
 *    selectGlobal,
 *    makeSelectLoading
 * };
 * Gets from store object coled global
 * then search there loading object.
 */
import { createSelector } from "reselect";

// SELECTOR - Select global state
const pageDataState = state => state.get("signUp");
const languageState = state => state.get("language");

// SELECTOR - pageData
export const pageData = () =>
  createSelector(pageDataState, globalState => globalState.toJS());

// SELECTOR - pageLanguage
export const pageLanguage = () =>
  createSelector(languageState, globalState => globalState.get("name"));

// SELECTOR - savefleetDetailsLoading
export const savefleetDetailsLoading = () =>
  createSelector(pageDataState, globalState =>
    globalState.get("savefleetDetailsLoading")
  );
