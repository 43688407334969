/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 
 * 1) Import your constant like this:
 *    import {
 *        LOAD_REPOS
 *    } from './constants';
 * 
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */
import * as constant from './constants';


// ACTION - changePageLanguage
export function changePageLanguage(selectedLanguage) {
	return {
		type: constant.CHANGE_PAGE_LANGUAGE,
		selectedLanguage
	};
}