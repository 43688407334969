// Load Localtorage state
export const load = (nameSpace) => {
	try {
		const serializedState = window.localStorage.getItem(nameSpace);
		if (serializedState === null) {
			return undefined;
		}
		return JSON.parse(serializedState);
	} catch (err) {
		return undefined;
	}
};


// Save state to localStorage
export const save = (state, nameSpace) => {
	try {
		window.localStorage.setItem(nameSpace, JSON.stringify(state));
	} catch (err) {
		console.log(`error from localstorage: ${err}`);
	}
};


// Localstorage get/set
export const localStorage = {
    get(name, value) {
        window.localStorage.getItem(name, JSON.parse(value));
    },
    set(name, value) {
        window.localStorage.setItem(name, JSON.stringify(value));
    }
};


// Get item from locale storage
export const getStorage = storeID => {
	const storageItem = JSON.parse(window.localStorage.getItem(storeID));
	if (storageItem) {
		return storageItem;
	} else {
		return null;
	}
};


// Put item to localestorage
export const setStorage = (name, value) => {
	const keyName = name.toLowerCase();
	let storageObject = {};
	
	if (typeof value === 'object') {
		storageObject = value;
	} else {
		storageObject[keyName] = value;
	}

	const storageItem = JSON.stringify(storageObject);
	window.localStorage.setItem(name, storageItem);
};


// Function - Add authoryzation key to localstorage
export const addAuthorization = () => {
	setStorage('Authorization', 'ssmf 9e991e2b7de74c639aae9e8cae3a7851');
};