import styled from 'styled-components';

// Styles
export const ExpandCollapseButtonWrapper = styled.button`
    background-color: transparent;
    display: inline-block;
    min-width: 0;
    position: absolute;
    top: ${props => props.sectionButton ? '29px' : '25px'};
    padding: 0;
    right: 20px;
    width: 12px;
    transform: translateY(-50%);
    height: 12px;
    
    &:hover {
        background-color: transparent;
    }

    @media screen and (min-width: 941px) {
        top: 26px;
        height: 24px;
        width: 24px;
    }
`
export const HorizontalLine = styled.span`
    color: #FF0000;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 10px;
    height: 100%;
    width: 2px;
    transform: rotate(-90deg);
    background-color: #FF0000;
`
export const VerticalLine = styled.span`
    color: #FF0000;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 10px;
    height: 100%;
    width: 2px;
    background-color: #FF0000;
`
