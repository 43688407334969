/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 *
 * 1) Import your constant like this:
 *    import {
 *        LOAD_REPOS
 *    } from './constants';
 *
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */
import * as constant from "./constants";

// ACTION - changeInputValue
export function changeInputValue(inputName, inputValue) {
  return {
    type: constant.CHANGE_INPUT_VALUE,
    inputName,
    inputValue
  };
}

// ACTION - selectOnChange
export function selectOnChange(selectName, selectValue) {
  return {
    type: constant.CHANGE_SELECT_VALUE,
    selectName,
    selectValue
  };
}

// ACTION - handleRadioChange
export function handleRadioChange(radioName, radioValue) {
  return {
    type: constant.CHANGE_RADIO_INPUT,
    radioName,
    radioValue
  };
}

// ACTION - fieldError
export function fieldError(fieldName, errorCopy) {
  return {
    type: constant.FIELD_ERROR,
    fieldName,
    errorCopy
  };
}

// ACTION - clearForm
export function clearForm() {
  return {
    type: constant.CLEAR_FORM
  };
}

// ACTION - getCountriesList/getCountriesListDone
export function getCountriesList(selectedLanguage) {
  return {
    type: constant.GET_COUNTRIES_LIST,
    selectedLanguage
  };
}
export function getCountriesListDone(countriesList) {
  return {
    type: constant.GET_COUNTRIES_LIST_DONE,
    countriesList
  };
}

// ACTION - getBusinessTypesList/getBusinessTypesListDone
export function getBusinessTypesList(selectedLanguage) {
  return {
    type: constant.GET_BUSINESS_TYPES_LIST,
    selectedLanguage
  };
}
export function getBusinessTypesListDone(businessTypesList) {
  return {
    type: constant.GET_BUSINESS_TYPES_LIST_DONE,
    businessTypesList
  };
}

// ACTION - getBusinessTypesList/getBusinessTypesListDone
export function getGetFleetSizeList() {
  return {
    type: constant.GET_FLEET_SIZE_LIST
  };
}
export function getGetFleetSizeListDone(fleetSizeList) {
  return {
    type: constant.GET_FLEET_SIZE_LIST_DONE,
    fleetSizeList
  };
}

// ACTION - saveFleetDetails/saveFleetDetailsSuccess
export function saveFleetDetails(
  firstName,
  lastName,
  emailAddress,
  password,
  confirmPassword,
  fleetName,
  operatorPhone,
  internationalCode,
  countryPhoneCode,
  country,
  termsCondition,
  pageLanguage,
  marketingConsent,
  fleetSize,
  businessType,
  businessTypeText
) {
  return {
    type: constant.SAVE_FLEET_DETAILS,
    firstName,
    lastName,
    emailAddress,
    password,
    confirmPassword,
    fleetName,
    operatorPhone,
    internationalCode,
    countryPhoneCode,
    country,
    termsCondition,
    pageLanguage,
    marketingConsent,
    fleetSize,
    businessType,
    businessTypeText
  };
}

export function setSaveFleetDetailsLoading(value) {
  return {
    type: constant.SET_SAVE_FLEET_DETAILS_LOADING,
    value
  };
}

export function saveFleetDetailsSuccess(value) {
  return {
    type: constant.SAVE_FLEET_DETAILS_SUCCESS,
    value
  };
}

// ACTION - setNewPhoneCode
export function setNewPhoneCode(phoneCode) {
  return {
    type: constant.SET_NEW_COUNTRY_PHONE_CODE,
    phoneCode
  };
}

// ACTION - getCountryByIP
export function getCountryByIP() {
  return {
    type: constant.GET_COUNTRY_BY_IP
  };
}

export function getCountryByIPDone(data) {
  return {
    type: constant.GET_COUNTRY_BY_IP_DONE,
    data
  };
}