/*
 * Messages
 * This contains all the language sentences for component.
 *
 * id             = id for language file to show specyfic language message
 * defaultMessage = default message if language file dont have specyfic one
 *
 *  export default defineMessages({
 *      titleMessage: {
 *          id: 'components.Header.title',
 *          defaultMessage: 'Sample header title. Welcome {name}'
 *      }
 *  });
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
	phoneCountryCode: {
		id: 'page.SignUp.PhoneCountryCode',
		defaultMessage: 'Country code'
	},
	selectPlaceholder: {
		id: 'select.Placeholder',
		defaultMessage: 'Select...'
	},
	errorRequired: {
		id: 'errors.Required',
		defaultMessage: 'This is a required field'
	},
});
