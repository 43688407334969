// React imports
import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Slider from "react-slick";
import CircularProgressbar from 'react-circular-progressbar';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Import selectors
import * as selector from './selectors';

// Import Components
import { Header } from './../../components/Header';
import { Section } from './../../components/Section';
import { AvailableFor } from './../../components/AvailableFor';
import { BoxWithImage } from './../../components/BoxWithImage';
import { MobileTeaser } from './../../components/MobileTeaser';
import { VideoModal } from './../../components/VideoModal';

// Import Containers
import PageLayout from './../PageLayout';

// Import Translations
import messages from './messages';
import pathnames from './../../routing-messages';

// Import Helpers
import history from './../../history';
import GA from './../../ga';

// Styles & Assets
import './styles.css';
import headerBg from './../../images/tpms/header-bg.jpg';
import section3Bg from './../../images/tpms/section3-bg.jpg';
import section4Bg from './../../images/tpms/section4-bg.jpg';
import section3BoxImage1 from './../../images/tpms/section3-box-image1.jpg';
import section4BoxImage1 from './../../images/tpms/section4-box-image1.jpg';
import section5BoxSlide1 from './../../images/tpms/section5/section5-box-image1.jpg';
import section5BoxSlide2 from './../../images/tpms/section5/section5-box-image2.1.jpg';
import section6BoxSlide1 from './../../images/tpms/section6/section6-box-image1.jpg';
import section6BoxSlide2 from './../../images/tpms/section6/section6-box-image2.jpg';
import mobileTeaserImage from './../../images/tpms/mobile-teaser-image.jpg';
import boxListImage1 from './../../images/tpms/ic-laptop.svg';
import boxListImage2 from './../../images/tpms/ic-mobile.svg';
import boxListImage3 from './../../images/tpms/ic-dongle.svg';

//Import Localised images
//EN
import headerImageItemEN from './../../images/tpms/header-image-item-EN.png';
import mobileTeaserDevicesEN from './../../images/tpms/mobile-teaser-device-EN.png';
import section6BoxSlide3EN from './../../images/tpms/section6/section6-box-image3-EN.jpg';
import section6BoxSlide4EN from './../../images/tpms/section6/section6-box-image4-EN.jpg';
//DE
import headerImageItemDE from './../../images/tpms/header-image-item-EN.png';
import mobileTeaserDevicesDE from './../../images/tpms/mobile-teaser-device-DE.png';
import section6BoxSlide3DE from './../../images/tpms/section6/section6-box-image3-DE.jpg';
import section6BoxSlide4DE from './../../images/tpms/section6/section6-box-image4-DE.jpg';
//FR
import headerImageItemFR from './../../images/tpms/header-image-item-EN.png';
import mobileTeaserDevicesFR from './../../images/tpms/mobile-teaser-device-FR.png';
import section6BoxSlide3FR from './../../images/tpms/section6/section6-box-image3-FR.jpg';
import section6BoxSlide4FR from './../../images/tpms/section6/section6-box-image4-FR.jpg';
//ES
import headerImageItemES from './../../images/tpms/header-image-item-EN.png';
import mobileTeaserDevicesES from './../../images/tpms/mobile-teaser-device-ES.png';
import section6BoxSlide3ES from './../../images/tpms/section6/section6-box-image3-ES.jpg';
import section6BoxSlide4ES from './../../images/tpms/section6/section6-box-image4-ES.jpg';
//IT
import headerImageItemIT from './../../images/tpms/header-image-item-EN.png';
import mobileTeaserDevicesIT from './../../images/tpms/mobile-teaser-device-IT.png';
import section6BoxSlide3IT from './../../images/tpms/section6/section6-box-image3-IT.jpg';
import section6BoxSlide4IT from './../../images/tpms/section6/section6-box-image4-IT.jpg';
//PL
import headerImageItemPL from './../../images/tpms/header-image-item-EN.png';
import mobileTeaserDevicesPL from './../../images/tpms/mobile-teaser-device-PL.png';
import section6BoxSlide3PL from './../../images/tpms/section6/section6-box-image3-PL.jpg';
import section6BoxSlide4PL from './../../images/tpms/section6/section6-box-image4-PL.jpg';

const localisedImages = {
	'headerImageItemEN' : headerImageItemEN,
	'mobileTeaserDevicesEN': mobileTeaserDevicesEN,
	'section6BoxSlide3EN': section6BoxSlide3EN,
	'section6BoxSlide4EN': section6BoxSlide4EN,
	'headerImageItemDE' : headerImageItemDE,
	'mobileTeaserDevicesDE': mobileTeaserDevicesDE,
	'section6BoxSlide3DE': section6BoxSlide3DE,
	'section6BoxSlide4DE': section6BoxSlide4DE,
	'headerImageItemFR' : headerImageItemFR,
	'mobileTeaserDevicesFR': mobileTeaserDevicesFR,
	'section6BoxSlide3FR': section6BoxSlide3FR,
	'section6BoxSlide4FR': section6BoxSlide4FR,
	'headerImageItemES' : headerImageItemES,
	'mobileTeaserDevicesES': mobileTeaserDevicesES,
	'section6BoxSlide3ES': section6BoxSlide3ES,
	'section6BoxSlide4ES': section6BoxSlide4ES,
	'headerImageItemIT' : headerImageItemIT,
	'mobileTeaserDevicesIT': mobileTeaserDevicesIT,
	'section6BoxSlide3IT': section6BoxSlide3IT,
	'section6BoxSlide4IT': section6BoxSlide4IT,
	'headerImageItemPL' : headerImageItemPL,
	'mobileTeaserDevicesPL': mobileTeaserDevicesPL,
	'section6BoxSlide3PL': section6BoxSlide3PL,
	'section6BoxSlide4PL': section6BoxSlide4PL,
}

const sliderSettingsBase = {
	dots: false,
	infinite: true,
	speed: 500,
	fade: true,
	slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
	className: 'slick-slider-fade'
};

const sliderSettingsSection5 = Object.assign({
	sliderSettingsBase,
	afterChange: current => {
		GA.event('TPMS Page', `Clicks on arrows forward across carousel: slide ${current + 1}`);
		window.dataLayer.push({'event': 'carouselbtnclick', 'section': 5, 'slide': current });
	}
});

const sliderSettingsSection6 = Object.assign({
	sliderSettingsBase,
	afterChange: current => {
		GA.event('TPMS Page', `Clicks on arrows forward across carousel: slide ${current + 1}`);
		window.dataLayer.push({'event': 'carouselbtnclick', 'section': 6, 'slide': current });
	}
});

/* Container */
class Tpms extends Component {
	constructor(props) {
        super(props);
        this.state = {
			bar1: 30,
			bar2: 90,
			videoModalOpened: false
        }

		this.openVideoModal = this.openVideoModal.bind(this);
		this.closeVideoModal = this.closeVideoModal.bind(this);
		this.outerLinkContact = this.outerLinkContact.bind(this);
	}

	componentDidMount() {
		window.localStorage.setItem('page_id', 'page.UrlName.TPMS');
	}

	headerBTNClick(translatedLink) {
		history.push(`/${this.props.pageLanguage.toLowerCase()}/${translatedLink}`);
		GA.event('TPMS Page', 'Start using FleetPulse CTA - Hero');
	}

	availableForBtnClick(translatedLink) {
		history.push(`/${this.props.pageLanguage.toLowerCase()}/${translatedLink}`);
		GA.event('TPMS Page', 'Start using FleetPulse CTA - Bottom Page');
	}

	openVideoModal() {
		this.setState({
			videoModalOpened: true
		});
		GA.event('TPMS Page', 'Carousel 1 - Watch how easy it is.');
	}

	closeVideoModal() {
		this.setState({
			videoModalOpened: false
		});
	}

	outerLinkContact() {
		GA.event('TPMS Page', 'Contact us CTA');
		const language = this.props.pageLanguage ? this.props.pageLanguage.toLowerCase() : 'en';
		window.open(`http://help.fleetpulse.app/${language}/support/tickets/new`,'_blank');
	}

	render() {
        const { ...props } = this.props;
		const { ...state } = this.state;

		const language = props.pageLanguage ? props.pageLanguage : 'EN';
		const headerImageItem = localisedImages[`headerImageItem${language}`];
		const mobileTeaserDevices = localisedImages[`mobileTeaserDevices${language}`];
		const section6BoxSlide3 = localisedImages[`section6BoxSlide3${language}`];
		const section6BoxSlide4 = localisedImages[`section6BoxSlide4${language}`];

		return (
			<PageLayout
				pageName='tpms'
				metaTitle={messages.metaTitle}
				metaDescription={messages.metaDescription}
				metaKeywords={messages.metaKeywords}
			>
				<FormattedMessage {...pathnames.signUp}>
				{ msg =>
					<Header
						subTitle={<FormattedMessage {...messages.headerSubTitle}>{msg => msg}</FormattedMessage>}
						title={<FormattedMessage {...messages.headerTitle}>{msg => msg}</FormattedMessage>}
						btnClick={this.headerBTNClick.bind(this, msg)}
						btn2Click={this.headerBTNClick.bind(this, msg)}
						btnCopy={<FormattedMessage {...messages.headerBtnCopy}>{msg => msg}</FormattedMessage>}
						headerBg={headerBg}
						headerImageItem={headerImageItem}
						buttonId={'tpms-header-btn'}
						langClass={`lang-${language}`}
					/>
				}
				</FormattedMessage>

				<Section
				imageBg={false}
				sectionBg='#ffffff'
				sectionNumber='01'
				>
                    <div className="statistic">
                        <div className="inner">
                            <div className="item small">
                                <div className="inner">
                                    <div className="circle">
                                        <CircularProgressbar
                                            {...this.props}
                                            percentage={state.bar1}
                                            strokeWidth={8}
                                            textForPercentage={null}
											initialAnimation={true}
                                        />
                                        <span>
                                            {state.bar1}<small>%</small>
                                        </span>
                                    </div>
                                    <p><FormattedMessage {...messages.section01Statistic1}>{msg => msg}</FormattedMessage></p>
                                </div>
                            </div>
                            <div className="item small">
                                <div className="inner">
                                    <div className="circle">
                                        <CircularProgressbar
                                            {...this.props}
                                            percentage={state.bar2}
                                            strokeWidth={8}
                                            textForPercentage={null}
											initialAnimation={true}
                                        />
                                        <span>
                                            {state.bar2}<small>%</small>
                                        </span>
                                    </div>
                                    <p><FormattedMessage {...messages.section01Statistic2}>{msg => msg}</FormattedMessage></p>
                                </div>
                            </div>
                            <div className="item title">
                                <p>
									<FormattedHTMLMessage {...messages.section01Copy}></FormattedHTMLMessage>
                                </p>
                            </div>
                        </div>
                    </div>
				</Section>

				<Section
				imageBg={false}
				sectionBg='#f3f3f3'
				sectionNumber='02'
				>
					<MobileTeaser
						reverse={true}
						title={<FormattedMessage {...messages.section02MobileTeaserTitle}>{msg => msg}</FormattedMessage>}
						devicesImage={mobileTeaserDevices}
						teaserImage={mobileTeaserImage}
						box1Content={<FormattedHTMLMessage {...messages.section02MobileTeaserBox1Copy}></FormattedHTMLMessage>}
						box2List={[
							{ image: boxListImage1, copy: <FormattedHTMLMessage {...messages.section02MobileTeaserItem1}></FormattedHTMLMessage> },
							{ image: boxListImage2, copy: <FormattedHTMLMessage {...messages.section02MobileTeaserItem2}></FormattedHTMLMessage> },
							{ image: boxListImage3, copy: <FormattedHTMLMessage {...messages.section02MobileTeaserItem3}></FormattedHTMLMessage> },
						]}
					/>
				</Section>

				<Section
				copyBoxTopLeft
				imageBg={false}
				sectionBg={`url(${section3Bg})`}
				sectionNumber='03'
				>
					<BoxWithImage
					reverse={false}
					textColor='#20272A'
					image={section3BoxImage1}
					contentBoxColor='#f3f3f3'
					title={<FormattedMessage {...messages.section03Box1Title}>{msg => msg}</FormattedMessage>}
					copy={<FormattedMessage {...messages.section03Box1Copy}>{msg => msg}</FormattedMessage>}
					list={[
						<FormattedHTMLMessage {...messages.section03Box1Item1}></FormattedHTMLMessage>,
						<FormattedHTMLMessage {...messages.section03Box1Item2}></FormattedHTMLMessage>,
						<FormattedHTMLMessage {...messages.section03Box1Item3}></FormattedHTMLMessage>
					]}
					otherCopy={<FormattedHTMLMessage {...messages.section03Box1OtherCopy}></FormattedHTMLMessage>}
					/>
				</Section>

				<Section
				copyBoxTopRight
				imageBg={false}
				sectionBg={`url(${section4Bg})`}
				sectionNumber='04'
				>
					<BoxWithImage
					reverse={true}
					textColor='#20272A'
					image={section4BoxImage1}
					contentBoxColor='#ffffff'
					title={<FormattedHTMLMessage {...messages.section04Box1Title}>{msg => msg}</FormattedHTMLMessage>}
					list={[
						<FormattedHTMLMessage {...messages.section04Box1Item1}>{msg => msg}</FormattedHTMLMessage>,
						<FormattedHTMLMessage {...messages.section04Box1Item2}>{msg => msg}</FormattedHTMLMessage>,
						<FormattedHTMLMessage {...messages.section04Box1Item3}>{msg => msg}</FormattedHTMLMessage>,
						<FormattedHTMLMessage {...messages.section04Box1Item4}>{msg => msg}</FormattedHTMLMessage>
					]}
					/>
				</Section>

				<Section
				reverseBG
				imageBg={false}
				sectionBg='#fff'
				sectionNumber='05'
				>
                    <div className="slider-cpntainer">
                        <Slider {...sliderSettingsSection5}>
                            <div>
								<BoxWithImage
									reverse={false}
									textColor='#20272a'
									image={section5BoxSlide1}
									contentBoxColor='#f3f3f3'
									title={<FormattedHTMLMessage {...messages.section05Box1Title}>{msg => msg}</FormattedHTMLMessage>}
									copy={<FormattedHTMLMessage {...messages.section05Box1Copy}>{msg => msg}</FormattedHTMLMessage>}
									actualSlide={1}
									maxSlides={2}
									btnCopy={<FormattedHTMLMessage {...messages.section05Box1BtnCopy}>{msg => msg}</FormattedHTMLMessage>}
									buttonId={'box-image-section5-slide1-btn'}
									buttonOnClick={this.outerLinkContact}
								/>
                            </div>
                            <div>
								<BoxWithImage
									reverse={false}
									textColor='#20272a'
									image={section5BoxSlide2}
									contentBoxColor='#f3f3f3'
									title={<FormattedHTMLMessage {...messages.section05Box2Title}>{msg => msg}</FormattedHTMLMessage>}
									copy={<FormattedHTMLMessage {...messages.section05Box2Copy}>{msg => msg}</FormattedHTMLMessage>}
									actualSlide={2}
									maxSlides={2}
									btnCopy={<FormattedHTMLMessage {...messages.section05Box1BtnCopy}>{msg => msg}</FormattedHTMLMessage>}
									buttonId={'box-image-section5-slide2-btn'}
									buttonOnClick={this.outerLinkContact}
								/>
                            </div>
                        </Slider>
                    </div>
				</Section>

				<Section
				imageBg={false}
				sectionBg='#f3f3f3'
				sectionNumber='06'
				>
                    <div className="slider-cpntainer image-box-reverse">
                        <Slider {...sliderSettingsSection6}>
                            <div>
                                <BoxWithImage
									reverse={true}
									textColor='#20272a'
									image={section6BoxSlide1}
									contentBoxColor='#fff'
									title={<FormattedHTMLMessage {...messages.section06Box1Title}>{msg => msg}</FormattedHTMLMessage>}
									copy={<FormattedHTMLMessage {...messages.section06Box1Copy}>{msg => msg}</FormattedHTMLMessage>}
									actualSlide={1}
									maxSlides={4}
									btnVideoLink={true}
									btnCopy={<FormattedHTMLMessage {...messages.section06Box1BtnCopy}>{msg => msg}</FormattedHTMLMessage>}
									btnVideoClassName={'white'}
									buttonId={'box-image-section6-video-btn'}
									buttonOnClick={this.openVideoModal}
									counterPosition={'left-aligned'}
									displayButtonOnImage={true}
                                />
                            </div>
                            <div>
								<BoxWithImage
									reverse={true}
									textColor='#20272a'
									image={section6BoxSlide2}
									contentBoxColor='#fff'
									title={<FormattedHTMLMessage {...messages.section06Box2Title}>{msg => msg}</FormattedHTMLMessage>}
									copy={<FormattedHTMLMessage {...messages.section06Box2Copy}>{msg => msg}</FormattedHTMLMessage>}
									actualSlide={2}
									maxSlides={4}
									counterPosition={'left-aligned'}
                                />
                            </div>
                            <div>
								<BoxWithImage
									reverse={true}
									textColor='#20272a'
									image={section6BoxSlide3}
									contentBoxColor='#fff'
									title={<FormattedHTMLMessage {...messages.section06Box3Title}>{msg => msg}</FormattedHTMLMessage>}
									copy={<FormattedHTMLMessage {...messages.section06Box3Copy}>{msg => msg}</FormattedHTMLMessage>}
									actualSlide={3}
									maxSlides={4}
									counterPosition={'left-aligned'}
                                />
                            </div>
                            <div>
								<BoxWithImage
									reverse={true}
									textColor='#20272a'
									image={section6BoxSlide4}
									contentBoxColor='#fff'
									title={<FormattedHTMLMessage {...messages.section06Box4Title}>{msg => msg}</FormattedHTMLMessage>}
									copy={<FormattedHTMLMessage {...messages.section06Box4Copy}>{msg => msg}</FormattedHTMLMessage>}
									actualSlide={4}
									maxSlides={4}
									counterPosition={'left-aligned'}
                                />
                            </div>
                        </Slider>
                    </div>
				</Section>

				<FormattedMessage {...pathnames.signUp}>
				{ msg =>
					<AvailableFor
						topText={<FormattedMessage {...messages.availableForTopCopy}>{msg => msg}</FormattedMessage>}
						buttonText={<FormattedMessage {...messages.availableForBtnCopy}>{msg => msg}</FormattedMessage>}
						buttonOnClick={this.availableForBtnClick.bind(this, msg)}
						buttonId={'tpms-cta-btn'}
						bottomTextLinkId={'tpms-cta-link'}
					/>
				}
				</FormattedMessage>

				{
					state.videoModalOpened &&
					<VideoModal
						link="https://www.youtube.com/embed/L5q27sf9D6w"
						closeModal={this.closeVideoModal}
					/>
				}
			</PageLayout>
		);
	}
}

// Listen to store value
const mapStateToProps = createStructuredSelector({
	pageLanguage: selector.pageLanguage()
});

// Change state name base on actions
const mapDispatchToProps = dispatch => {
	return {
		// saveQueryString: value => dispatch(saveQueryString(value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Tpms);