/*
 * Component Constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We can prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const LOAD_REPOS = 'LOAD_REPOS';
 */
export const GET_HOME_DATA_REQUESTED = 'GET_HOME_DATA_REQUESTED';
export const GET_HOME_DATA_REQUESTED_DONE = 'GET_HOME_DATA_REQUESTED_DONE';
