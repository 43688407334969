const ReactGA = require('react-ga');

const GA = {

    /* on app init */
    init() {
        ReactGA.initialize('UA-120606759-1', {
            gaOptions: {
                cookieExpires: 31556926
            },
            debug: window.location.hostname === 'localhost',
            titleCase: false
        });
        ReactGA.plugin.require('displayfeatures');
    },


    /* on page load */
    pageview(path) {
        ReactGA.set({page: path});
        ReactGA.pageview(path);
    },


    /* page without change url - modals ect. */
    modalview(path) {
        ReactGA.modalview(path);
    },


    saveFleetID(fleetId) {
        localStorage.setItem('fleetId', fleetId);
    },


    /* events */
    event(category, action, label, value) {
        let data = {
            category,
            action
        };

        if (label == true) {
            const label = localStorage.getItem('fleetId');
            data = {...data, label};
        } else {
            data = {...data, label};
        }

        if (value !== undefined) {
            data = {...data, value};
        }

        ReactGA.event(data);
    },
};

window.GA = GA;

export default GA;