// React imports
import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import scrollToComponent from 'react-scroll-to-component';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Import selectors
import * as selector from './selectors';

// Import Components
import { Header } from './../../components/Header';
import { Section } from './../../components/Section';
import { AvailableFor } from './../../components/AvailableFor';
import { BoxWithImage } from './../../components/BoxWithImage';

// Import Containers
import PageLayout from './../PageLayout';

// Import Translations
import messages from './messages';
import pathnames from './../../routing-messages';

// Styles & Assets
import './styles.css';
import section1Bg from './../../images/features/section1-bg.jpg';
import section3Bg from './../../images/features/section3-bg.jpg';
import section4Bg from './../../images/features/section4-bg.jpg';
import headerBg from './../../images/features/header-bg.jpg';
import section1BoxImage1 from './../../images/features/section1-box-image1.jpg';
import section3BoxImage1 from './../../images/features/section3-box-image1.jpg';
import section4BoxImage1 from './../../images/features/section4-box-image1.jpg';
import icon1 from './../../images/features/ic-vehicle-checklist-red.svg';
import icon2 from './../../images/features/ic-vehicle-management-red.svg';
import icon3 from './../../images/features/ic-service-bookings-red.svg';
import icon4 from './../../images/features/ic-tpms-red.svg';

//Import Localised images
//EN
import headerImageItemEN from './../../images/features/header-image-item-EN.png';
import section2BoxImage1EN from './../../images/features/section2-box-image1-EN.jpg';
import section2BoxImage2EN from './../../images/features/section2-box-image2-EN.jpg';
//DE
import headerImageItemDE from './../../images/features/header-image-item-DE.png';
import section2BoxImage1DE from './../../images/features/section2-box-image1-DE.jpg';
import section2BoxImage2DE from './../../images/features/section2-box-image2-DE.jpg';
//FR
import headerImageItemFR from './../../images/features/header-image-item-FR.png';
import section2BoxImage1FR from './../../images/features/section2-box-image1-FR.jpg';
import section2BoxImage2FR from './../../images/features/section2-box-image2-FR.jpg';
//ES
import headerImageItemES from './../../images/features/header-image-item-ES.png';
import section2BoxImage1ES from './../../images/features/section2-box-image1-ES.jpg';
import section2BoxImage2ES from './../../images/features/section2-box-image2-ES.jpg';
//IT
import headerImageItemIT from './../../images/features/header-image-item-IT.png';
import section2BoxImage1IT from './../../images/features/section2-box-image1-IT.jpg';
import section2BoxImage2IT from './../../images/features/section2-box-image2-IT.jpg';
//PL
import headerImageItemPL from './../../images/features/header-image-item-PL.png';
import section2BoxImage1PL from './../../images/features/section2-box-image1-PL.jpg';
import section2BoxImage2PL from './../../images/features/section2-box-image2-PL.jpg';

// Import Helpers
import history from './../../history';
import GA from './../../ga';

const localisedImages = {
	'headerImageItemEN' : headerImageItemEN,
	'section2BoxImage1EN': section2BoxImage1EN,
	'section2BoxImage2EN': section2BoxImage2EN,
	'headerImageItemDE' : headerImageItemDE,
	'section2BoxImage1DE': section2BoxImage1DE,
	'section2BoxImage2DE': section2BoxImage2DE,
	'headerImageItemFR' : headerImageItemFR,
	'section2BoxImage1FR': section2BoxImage1FR,
	'section2BoxImage2FR': section2BoxImage2FR,
	'headerImageItemES' : headerImageItemES,
	'section2BoxImage1ES': section2BoxImage1ES,
	'section2BoxImage2ES': section2BoxImage2ES,
	'headerImageItemIT' : headerImageItemIT,
	'section2BoxImage1IT': section2BoxImage1IT,
	'section2BoxImage2IT': section2BoxImage2IT,
	'headerImageItemPL' : headerImageItemPL,
	'section2BoxImage1PL': section2BoxImage1PL,
	'section2BoxImage2PL': section2BoxImage2PL,
}

const scrollToSectionOptions = {
	offset: -80,
	align: 'top',
	duration: 500
}


/* Container */
class Features extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		window.localStorage.setItem('page_id', 'page.UrlName.Features');
	}

	headerBTNClick(translatedLink) {
		history.push(`/${this.props.pageLanguage.toLowerCase()}/${translatedLink}`);
		GA.event('Features Page', 'Start using FleetPulse CTA - Hero');
	}

	section4BoxBtnClick(translatedLink) {
		history.push(`/${this.props.pageLanguage.toLowerCase()}/${translatedLink}`);
		GA.event('Features Page', 'Show me TPMS CTA');
	}

	availableForBtnClick(translatedLink) {
		history.push(`/${this.props.pageLanguage.toLowerCase()}/${translatedLink}`);
		GA.event('Features Page', 'Start using FleetPulse CTA - Bottom Page');
	}

	render() {
        const { ...props } = this.props;
		const { ...state } = this.state;

		const language = props.pageLanguage ? props.pageLanguage : 'EN';
		const headerImageItem = localisedImages[`headerImageItem${language}`];
		const section2BoxImage1 = localisedImages[`section2BoxImage1${language}`];
		const section2BoxImage2 = localisedImages[`section2BoxImage2${language}`];

		return (
			<PageLayout
				pageName='features'
				metaTitle={messages.metaTitle}
				metaDescription={messages.metaDescription}
				metaKeywords={messages.metaKeywords}
			>
				<FormattedMessage {...pathnames.signUp}>
				{ msg =>
					<Header
						subTitle={<FormattedMessage {...messages.headerSubTitle}>{msg => msg}</FormattedMessage>}
						title={<FormattedMessage {...messages.headerTitle}>{msg => msg}</FormattedMessage>}
						btnClick={this.headerBTNClick.bind(this, msg)}
						btn2Click={this.headerBTNClick.bind(this, msg)}
						btnCopy={<FormattedMessage {...messages.headerBtnCopy}>{msg => msg}</FormattedMessage>}
						headerBg={headerBg}
						headerImageItem={headerImageItem}
						buttonId={'features-header-btn'}
						langClass={`lang-${language}`}
					/>
				}
				</FormattedMessage>

				<section className="features-icons">
					<div className="inner">
						<div className="item" id={'features-icons-01'} onClick={() => scrollToComponent(this.One, { })}>
							<div className="img">
								<span>01</span>
								<FormattedMessage {...messages.featuresIcon1}>
									{msg =>
									<img src={icon1} alt={msg} />
									}
								</FormattedMessage>
							</div>
							<span className="name">
								{<FormattedMessage {...messages.featuresIcon1}>{msg => msg}</FormattedMessage>}
							</span>
						</div>
						<div className="item" id={'features-icons-02'} onClick={() => scrollToComponent(this.Two, scrollToSectionOptions)}>
							<div className="img">
								<span>02</span>
								<FormattedMessage {...messages.featuresIcon2}>
									{msg =>
									<img src={icon2} alt={msg} />
									}
								</FormattedMessage>
							</div>
							<span className="name">
								{<FormattedMessage {...messages.featuresIcon2}>{msg => msg}</FormattedMessage>}
							</span>
						</div>
						<div className="item" id={'features-icons-03'} onClick={() => scrollToComponent(this.Three, scrollToSectionOptions)}>
							<div className="img">
								<span>03</span>
								<FormattedMessage {...messages.featuresIcon3}>
									{msg =>
									<img src={icon3} alt={msg} />
									}
								</FormattedMessage>
							</div>
							<span className="name">
								{<FormattedMessage {...messages.featuresIcon3}>{msg => msg}</FormattedMessage>}
							</span>
						</div>
						<div className="item" id={'features-icons-04'} onClick={() => scrollToComponent(this.Four, scrollToSectionOptions)}>
							<div className="img">
								<span>04</span>
								<FormattedMessage {...messages.featuresIcon4}>
									{msg =>
									<img src={icon4} alt={msg} />
									}
								</FormattedMessage>
							</div>
							<span className="name">
								{<FormattedMessage {...messages.featuresIcon4}>{msg => msg}</FormattedMessage>}
							</span>
						</div>
					</div>
				</section>

				<Section
				copyBoxTopRight
				imageBg={false}
				sectionBg={`url(${section1Bg})`}
				sectionNumber='01'
				sectionIcon={icon1}
				refs={(section) => { this.One = section; }}
				>
					<BoxWithImage
					reverse={false}
					textColor='#20272A'
					image={section1BoxImage1}
					contentBoxColor='#ffffff'
					title={<FormattedMessage {...messages.section01Box1Title}>{msg => msg}</FormattedMessage>}
					list={[
						<FormattedMessage {...messages.section01Box1Item1}>{msg => msg}</FormattedMessage>,
						<FormattedMessage {...messages.section01Box1Item2}>{msg => msg}</FormattedMessage>,
						<FormattedMessage {...messages.section01Box1Item3}>{msg => msg}</FormattedMessage>,
						<FormattedMessage {...messages.section01Box1Item4}>{msg => msg}</FormattedMessage>
					]}
					/>
				</Section>

				<Section
					reverseBG
					withCollumns
					imageBg={false}
					sectionBg='#ffffff'
					sectionNumber='02'
					sectionIcon={icon2}
					title={<FormattedMessage {...messages.section02Title}>{msg => msg}</FormattedMessage>}
					subTitle={<FormattedMessage {...messages.section02Subtitle}>{msg => msg}</FormattedMessage>}
					refs={(section) => { this.Two = section; }}
				>
					<BoxWithImage
					reverse={false}
					textColor='#20272A'
					image={section2BoxImage1}
					contentBoxColor='#f3f3f3'
					title={<FormattedMessage {...messages.section02Box1Title}>{msg => msg}</FormattedMessage>}
					list={[
						<FormattedMessage {...messages.section02Box1Item1}>{msg => msg}</FormattedMessage>,
						<FormattedMessage {...messages.section02Box1Item2}>{msg => msg}</FormattedMessage>,
						<FormattedMessage {...messages.section02Box1Item3}>{msg => msg}</FormattedMessage>,
						<FormattedMessage {...messages.section02Box1Item4}>{msg => msg}</FormattedMessage>
					]}
					/>
					<BoxWithImage
					reverse={true}
					textColor='#20272A'
					image={section2BoxImage2}
					contentBoxColor='#f3f3f3'
					title={<FormattedHTMLMessage {...messages.section02Box2Title}></FormattedHTMLMessage>}
					list={[
						<FormattedMessage {...messages.section02Box2Item1}>{msg => msg}</FormattedMessage>,
						<FormattedMessage {...messages.section02Box2Item2}>{msg => msg}</FormattedMessage>,
						<FormattedMessage {...messages.section02Box2Item3}>{msg => msg}</FormattedMessage>,
						<FormattedMessage {...messages.section02Box2Item4}>{msg => msg}</FormattedMessage>
					]}
					/>
				</Section>

				<Section
				copyBoxTopLeft
				imageBg={false}
				sectionBg={`url(${section3Bg})`}
				sectionNumber='03'
				sectionIcon={icon3}
				refs={(section) => { this.Three = section; }}
				>
					<BoxWithImage
					reverse={false}
					textColor='#20272A'
					image={section3BoxImage1}
					contentBoxColor='#ffffff'
					title={<FormattedMessage {...messages.section03Box1Title}>{msg => msg}</FormattedMessage>}
					list={[
						<FormattedMessage {...messages.section03Box1Item1}>{msg => msg}</FormattedMessage>,
						<FormattedMessage {...messages.section03Box1Item2}>{msg => msg}</FormattedMessage>,
						<FormattedMessage {...messages.section03Box1Item3}>{msg => msg}</FormattedMessage>
					]}
					/>
				</Section>

				<Section
				copyBoxTopRight
				imageBg={true}
				sectionBg={`url(${section4Bg})`}
				sectionNumber='04'
				sectionIcon={icon4}
				refs={(section) => { this.Four = section; }}
				>
					<FormattedMessage {...pathnames.tpms}>
					{ msg =>
						<BoxWithImage
						reverse={false}
						textColor='#ffffff'
						image={section4BoxImage1}
						contentBoxColor='rgba(0,0,0,0.3)'
						title={<FormattedMessage {...messages.section04Box1Title}>{msg => msg}</FormattedMessage>}
						copy={<FormattedMessage {...messages.section04Box1Copy}>{msg => msg}</FormattedMessage>}
						btnCopy={<FormattedMessage {...messages.section04Box1BtnCopy}>{msg => msg}</FormattedMessage>}
						buttonId={'box-image-section4-btn'}
						buttonOnClick={this.section4BoxBtnClick.bind(this, msg)}
						/>
					}
					</FormattedMessage>
				</Section>

				<FormattedMessage {...pathnames.signUp}>
				{msg =>
					<AvailableFor
						topText={<FormattedMessage {...messages.availableForTopCopy}>{msg => msg}</FormattedMessage>}
						buttonText={<FormattedMessage {...messages.availableForBtnCopy}>{msg => msg}</FormattedMessage>}
						buttonOnClick={this.availableForBtnClick.bind(this, msg)}
						buttonId={'features-cta-btn'}
						bottomTextLinkId={'features-cta-link'}
					/>
				}
				</FormattedMessage>
			</PageLayout>
		);
	}
}

// Listen to store value
const mapStateToProps = createStructuredSelector({
	pageLanguage: selector.pageLanguage()
});

// Change state name base on actions
const mapDispatchToProps = dispatch => {
	return {
		// saveQueryString: value => dispatch(saveQueryString(value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Features);
