/**
 * i18n.js
 * This will setup the i18n language files and locale data for your app.
 */
import { addLocaleData } from 'react-intl';

// Import global languages specification from intl
import enLocaleData from 'react-intl/locale-data/en';
import frLocaleData from 'react-intl/locale-data/fr';
import deLocaleData from 'react-intl/locale-data/de';
import esLocaleData from 'react-intl/locale-data/es';
import itLocaleData from 'react-intl/locale-data/it';
import plLocaleData from 'react-intl/locale-data/pl';

// Import translations files
import enTranslationMessages from './languages/en.json';
import frTranslationMessages from './languages/fr.json';
import deTranslationMessages from './languages/de.json';
import esTranslationMessages from './languages/es.json';
import itTranslationMessages from './languages/it.json';
import plTranslationMessages from './languages/pl.json';


// Declare default language
const DEFAULT_LANGUAGE = 'en';


// Run detect for default language translation base on browser language
// Defaults are: commas on numbers depends on nationalisation
addLocaleData(enLocaleData);
addLocaleData(frLocaleData);
addLocaleData(deLocaleData);
addLocaleData(esLocaleData);
addLocaleData(itLocaleData);
addLocaleData(plLocaleData);


// Add new locale shortcode for translation
export const appLocales = [
	'en',
	'fr',
	'de',
	'es',
	'it',
	'pl'
];


// Do all translation magic
export const formatTranslationMessages = (locale, messages) => {
	const defaultFormattedMessages = locale !== DEFAULT_LANGUAGE
	? formatTranslationMessages(DEFAULT_LANGUAGE, enTranslationMessages)
	: {};
	return Object.keys(messages).reduce((formattedMessages, key) => {
		const formattedMessage = !messages[key] && locale !== DEFAULT_LANGUAGE
		? defaultFormattedMessages[key]
		: messages[key];
		return Object.assign(formattedMessages, { [key]: formattedMessage });
	}, {});
};


// Export translated messages
export const translationMessages = {
	EN: formatTranslationMessages('en', enTranslationMessages),
	FR: formatTranslationMessages('fr', frTranslationMessages),
	DE: formatTranslationMessages('de', deTranslationMessages),
	ES: formatTranslationMessages('es', esTranslationMessages),
	IT: formatTranslationMessages('it', itTranslationMessages),
	PL: formatTranslationMessages('pl', plTranslationMessages)
};