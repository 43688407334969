import { defineMessages } from 'react-intl';

export default defineMessages({
	metaTitle: {
		id: 'page.Home.Meta.Title',
		defaultMessage: 'FleetPulse fleet management solution with vehicle checklist app andTPMS | Bridgestone'
	},
	metaDescription: {
		id: 'page.Home.Meta.Description',
		defaultMessage: 'Let your drivers record and manage defects with an easy vehicle checklist app, while you monitor your fleet and manage service bookings from an online dashboard – helping you stay one step ahead of your vehicle maintenance activities and avoid breakdowns.'
	},
	metaKeywords: {
		id: 'page.Home.Meta.Keywords',
		defaultMessage: 'fleet management solution, vehicle checklist, TPMS, Bridgestone, service booking, vehicle maintenance, breakdowns'
	},

	headerTitle: {
		id: 'page.Home.Header.Title',
		defaultMessage: 'FleetPulse prevents minor problems becoming major issues'
	},
	headerListItem1: {
		id: 'page.Home.Header.List.Item1',
		defaultMessage: 'Increased time on the road'
	},
	headerListItem2: {
		id: 'page.Home.Header.List.Item2',
		defaultMessage: 'Reduced maintenance costs'
	},
	headerListItem3: {
		id: 'page.Home.Header.List.Item3',
		defaultMessage: 'Simplified operations'
	},
	headerListItem4: {
		id: 'page.Home.Header.List.Item4',
		defaultMessage: 'Simplified operations'
	},
	headerBtnCopy: {
		id: 'page.Home.Header.BtnCopy',
		defaultMessage: 'Start Using FleetPulse'
	},

	mobileTeaserTitle: {
		id: 'page.Home.MobileTeaser.Title',
		defaultMessage: 'FleetPulse helps you stay one step ahead of your fleet’s maintenance activities – preventing repair costs, increasing time on the road and streamlining the vehicle inspection process for everyone.'
	},
	mobileTeaserBox1Content: {
		id: 'page.Home.MobileTeaser.Box1.Content',
		defaultMessage: 'It allows drivers to easily complete their road-compliance checklists and gives you a real-time view of your whole fleet, letting you analyse each vehicle at the click of a button. Plus, when used with our Tyre Pressure Monitoring System (TPMS), your drivers will be able to automatically check their vehicle’s tyre pressure and receive alerts if it’s too low – significantly reducing breakdowns to ensure customer deadlines aren’t missed.'
	},
	mobileTeaserBox2Content: {
		id: 'page.Home.MobileTeaser.Box2.Content',
		defaultMessage: 'Whether your fleet is made up of tractors, trailers, rigids, coaches, buses, or a combination of all, FleetPulse is the fleet management solution that keeps you moving.'
	},
	mobileTeaserBox3BtnCopy: {
		id: 'page.Home.MobileTeaser.Box3.BtnCopy',
		defaultMessage: 'Watch FleetPulse in action'
	},

	section02Title: {
		id: 'page.Home.Section.02.Title',
		defaultMessage: 'A three-in-one solution'
	},
	section02Subtitle: {
		id: 'page.Home.Section.02.Subtitle',
		defaultMessage: 'The innovative way FleetPulse helps protect the health of your fleet.'
	},
	section02Box1Title: {
		id: 'page.Home.Section.02.Box1.Title',
		defaultMessage: 'A centralised website for you'
	},
	section02Box1Copy: {
		id: 'page.Home.Section.02.Box1.Copy',
		defaultMessage: 'You’ll receive log-ins for an online dashboard, so you can enjoy a real-time view of your fleet, as well as details of individual vehicles. This can be shared with maintenance managers and your service team.'
	},
	section02Box1BtnCopy: {
		id: 'page.Home.Section.02.Box1.BtnCopy',
		defaultMessage: 'REGISTER YOUR FLEET'
	},
	section02Box2Title: {
		id: 'page.Home.Section.02.Box2.Title',
		defaultMessage: 'An app for your drivers'
	},
	section02Box2Copy: {
		id: 'page.Home.Section.02.Box2.Copy',
		defaultMessage: 'Once you’re set up, your drivers can simply download our app to access their daily checklists. To complete them, they can just swipe right on the screen to mark each item as ok or left if they need to record a defect. All information is sent through and stored on your centralised website.'
	},
	section02Box2BtnCopy: {
		id: 'page.Home.Section.02.Box2.BtnCopy',
		defaultMessage: 'Available on Google Play.'
	},
	section02Box3Title: {
		id: 'page.Home.Section.02.Box3.Title',
		defaultMessage: 'TPMS hardware for your vehicles'
	},
	section02Box3Copy: {
		id: 'page.Home.Section.02.Box3.Copy',
		defaultMessage: 'To wirelessly monitor your tyre pressure, you’ll need our Tirematics sensors and dongle to use with the FleetPulse app. We can help you work out how many you need.'
	},

	section03Box1Title: {
		id: 'page.Home.Section.03.Box1.Title',
		defaultMessage: 'Top features'
	},
	section03Box1Copy: {
		id: 'page.Home.Section.03.Box1.Copy',
		defaultMessage: 'Make things easier for you and your drivers with vehicle checklists, service bookings and vehicle management.'
	},
	section03Box1BtnCopy: {
		id: 'page.Home.Section.03.Box1.BtnCopy',
		defaultMessage: 'More about features'
	},

	section04Box1Title: {
		id: 'page.Home.Section.04.Box1.Title',
		defaultMessage: 'Tyre Pressure Monitoring System (TPMS)'
	},
	section04Box1Copy: {
		id: 'page.Home.Section.04.Box1.Copy',
		defaultMessage: 'Reduce maintenance costs and increase time on the road with our tyre pressure monitoring system.'
	},
	section04Box1BtnCopy: {
		id: 'page.Home.Section.04.Box1.BtnCopy',
		defaultMessage: 'More about TPMS'
	},

	availableForTopCopy: {
		id: 'page.AvailableFor.Copy',
		defaultMessage: 'Available for the following vehicle types'
	},
	availableForBtnCopy: {
		id: 'page.AvailableFor.BtnCopy',
		defaultMessage: 'Take control of your fleet'
	},
});