import { defineMessages } from 'react-intl';

export default defineMessages({
	features: {
		id: 'page.UrlName.Features',
		defaultMessage: 'features'
	},
	tpms: {
		id: 'page.UrlName.TPMS',
		defaultMessage: 'tpms'
	},
	faqs: {
		id: 'page.UrlName.FAQS',
		defaultMessage: 'faqs'
	},
	signUp: {
		id: 'page.UrlName.SignUp',
		defaultMessage: 'signup'
	},
});