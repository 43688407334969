// React librarys
import React from 'react';

// Import Assets
import './styles.css';

// Import translations
import messages from './messages';


/* Component */
export const Section = props => (
    <section 
        className={`section ${props.sectionNumber ? `section-${props.sectionNumber}` : ''} ${!props.title ? 'no-header' : ''} ${props.imageBg ? 'img-bg' : ''} ${props.withCollumns ? 'with-collumns' : ''} ${props.noCollumns ? 'no-collumns' : ''} ${props.reverseBG ? 'reverse-bg' : ''} ${props.copyBoxTopRight ? 'box-top-right' : ''} ${props.copyBoxTopLeft ? 'box-top-left' : ''}`} 
        style={{
            background: props.sectionBg,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }}
        ref={props.refs}
    >
        <div className="section-content">
            <div className={`section-decor ${props.sectionIcon ? 'with-icon' : ''}`}>
                <span>{props.sectionNumber}</span>
                {
                    props.sectionIcon &&
                    <img src={props.sectionIcon} alt=""/>
                }
            </div>
            {
                props.title && 
                <header className="section-header">
                    <h3 className="title">{props.title}</h3>
                    <p className="subtitle">{props.subTitle}</p>
                </header>
            }
            <div className="inner-content">
                {props.children}
            </div>
            {
                (props.copyBoxTopRight || props.copyBoxTopLeft) &&
                <div 
                    className="mobile-bg-box" 
                    style={{
                        background: props.sectionBg,
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }}
                ></div>
            }
        </div>
    </section>
);
