import { defineMessages } from 'react-intl';

export default defineMessages({
	cookiesCopy: {
		id: 'cookies.Copy',
		defaultMessage: 'Our website uses cookies to record the preferences of users and optimise the design of its websites, make navigation easier, serve you with more relevant ads and increase the user-friendliness of a website.'
	},
	cookiesCopy2: {
		id: 'cookies.Copy2',
		defaultMessage: 'Our website uses cookies to record the preferences of users and optimise the design of its websites, make navigation easier, serve you with more relevant ads and increase the user-friendliness of a website.'
	},

	cookiesPrivacyLinkCopy: {
		id: 'cookies.PrivacyLinkCopy',
		defaultMessage: 'Privacy statement'
	},

	cookiesCookieLinkCopy: {
		id: 'cookies.CookieLinkCopy',
		defaultMessage: 'Cookie policy'
	},

	acceptCookieBtnCopy: {
		id: 'cookies.AcceptBtnCopy',
		defaultMessage: 'I accept'
	},

	agreement1Title: {
		id: 'cookies.Agreement1.Title',
		defaultMessage: 'Essencial + analytics cookies'
	},
	agreement1Copy: {
		id: 'cookies.Agreement1.Copy',
		defaultMessage: 'Essential cookies are necessary so that you can move around the website and use its features. Analytics cookies help to improve the performance of our website. These cookies do not collect information that identifies you. They are enabled by default . Check our Cookies Policy to manage your settings.'
	},

	agreement2Title: {
		id: 'cookies.Agreement2.Title',
		defaultMessage: 'Functionality cookies'
	},
	agreement2Copy: {
		id: 'cookies.Agreement2.Copy',
		defaultMessage: 'These cookies allow the website to remember choices you make to give you better functionality and personal features.'
	},

	agreement3Title: {
		id: 'cookies.Agreement3.Title',
		defaultMessage: 'Advertising cookies'
	},
	agreement3Copy: {
		id: 'cookies.Agreement3.Copy',
		defaultMessage: 'These cookies enable us and our advertising partners to deliver personalised advertising when you visit other websites.'
	},

	agreement4Title: {
		id: 'cookies.Agreement4.Title',
		defaultMessage: 'Social media cookies'
	},
	agreement4Copy: {
		id: 'cookies.Agreement4.Copy',
		defaultMessage: 'These cookies enable you to share content from our Website on social media, and the social media to track your use of our website, for purposes of their own.'
	},
});