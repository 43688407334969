// React librarys
import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Import Assets
// import { Wrapper, Logo, Nav, NavTrigger } from './styles';
import './styles.css';
import bridgestoneLogo from './../../images/bs-logo-white.png';

// Import translations
import messages from './messages';
import pathnames from './../../routing-messages';

// Import Helpers
import GA from './../../ga';


/* Component */
export const Footer = props => (
    <footer className="footer">
        <div>
            <div className="footer-group">
                <h6 className="footer-group-title">
                    <FormattedMessage {...messages.col1Title}>{msg => msg}</FormattedMessage>
                </h6>
                <ul>
                    <li>
                        <FormattedMessage {...pathnames.signUp}>
                        {
                            msg =>
                            <Link id={'footer-signup-link'}  to={`/${props.language}/${msg}`} onClick={() => GA.event('Footer', 'Click on Start your Free Trial')}><FormattedMessage {...messages.col1Link1}>{msg => msg}</FormattedMessage></Link>
                        }
                        </FormattedMessage>
                    </li>
                    <li>
                        <FormattedMessage {...pathnames.faqs}>
                        {
                            msg =>
                            <Link id={'footer-faqs-link'}  to={`/${props.language}/${msg}`} onClick={() => GA.event('Footer', 'Click on FAQs')}><FormattedMessage {...messages.col1Link2}>{msg => msg}</FormattedMessage></Link>
                        }
                        </FormattedMessage>
                    </li>
                </ul>
            </div>
            <div className="footer-group">
                <h6 className="footer-group-title">
                    <FormattedMessage {...messages.col2Title}>{msg => msg}</FormattedMessage>
                </h6>
                <ul>
                    <li><a id={'footer-contactus-link'} href={`http://help.fleetpulse.app/${props.language}/support/tickets/new`} target="_blank" onClick={() => GA.event('Footer', 'Click on Ask us a question')}><FormattedMessage {...messages.col2Link1}>{msg => msg}</FormattedMessage></a></li>
                    <li><a id={'footer-manual-link'} href={`/files/FP_Manual_APP_${props.language.toUpperCase()}.pdf`} target="_blank" onClick={() => GA.event('Footer', 'Click on Fleetpulse Manual')}><FormattedMessage {...messages.manualLinkCopy}>{msg => msg}</FormattedMessage></a></li>
                </ul>
            </div>
            <div className="footer-group">
                <h6 className="footer-group-title">
                    <FormattedMessage {...messages.col3Title}>{msg => msg}</FormattedMessage>
                </h6>
                <ul>
                    <li>
                        <span id={'footer-cookie-link'}  href='#' target="_blank" onClick={props.openCookieModal}>
                            <FormattedMessage {...messages.cookiePreferences}>{msg => msg}</FormattedMessage>
                        </span>
                    </li>
                    <li>
                        <FormattedMessage {...messages.privacyLink}>
                            {msg =>
                            <a id={'footer-privacy-link'}  href={`/files/${msg}.pdf`} target="_blank" onClick={() => GA.event('Footer', 'Click on Privacy Policy')}>
                                <FormattedMessage {...messages.col3Link1}>{msg => msg}</FormattedMessage>
                            </a>
                            }
                        </FormattedMessage>
                    </li>
                    <li>
                        <FormattedMessage {...messages.termsLink}>
                            {msg =>
                            <a id={'footer-tc-link'}  href={`/files/${msg}.pdf`} target="_blank" onClick={() => GA.event('Footer', 'Click on Terms and conditions')}>
                                <FormattedMessage {...messages.col3Link2}>{msg => msg}</FormattedMessage>
                            </a>
                            }
                        </FormattedMessage>
                    </li>
                    <li>
                        <FormattedMessage {...messages.cookiesLink}>
                            {msg =>
                            <a id={'footer-cookies-link'} href={`https://edp-e-ne-p-bridgestone.azureedge.net/-/media/Files/Bridgestone/CHP/Downloads/Cookies-policy/${msg}_web.ashx`} target="_blank" onClick={() => GA.event('Footer', 'Click on Cookies')}>
                                <FormattedMessage {...messages.col3Link3}>{msg => msg}</FormattedMessage>
                            </a>
                            }
                        </FormattedMessage>
                    </li>
                </ul>
            </div>
            <Link id={'footer-logo'} to={`/${props.language}/`} className="footer-bs-logo" onClick={() => GA.event('Footer', 'Click on Bridgestone logo')}>
                <img src={bridgestoneLogo} alt="Bridgestone Logo"/>
            </Link>
            <span className="copyright"><FormattedMessage {...messages.copyright}>{msg => msg}</FormattedMessage></span>
        </div>
    </footer>
);
