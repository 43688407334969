/*
 * Import all app sagas
 * and connect it to one rote
 */
import { all, fork } from 'redux-saga/effects';

// Import sagas
import faqs from './pages/Faqs/saga';
import features from './pages/Features/saga';
import home from './pages/Home/saga';
import signUp from './pages/SignUp/saga';
import tpms from './pages/Tpms/saga';


export default function* rootSaga() {
	yield all([
		fork(faqs),
		fork(features),
		fork(home),
		fork(signUp),
		fork(tpms)
	]);
}