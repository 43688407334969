// React librarys
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Language selector
import LanguageSelector from './../../containers/LanguageSelector';

// Import Assets
// import { Wrapper, Logo, Nav, NavTrigger } from './styles';
import './styles.css';
import logo from './../../images/FleetPulse-logos.svg';

// Import translations
import messages from './messages';
import pathnames from './../../routing-messages';

// Helpers
import GA from './../../ga';


/* Component */
export const NavMenu = props => (
    <div className={`navbar ${props.scrolling ? 'scrolling' : ''}`}>
        <nav className={`nav ${props.menuOpened ? 'open' : ''}`}>
            <div className="nav-top">
                <Link id={'nav-logo'} to={`/${props.language.toLowerCase()}/`} onClick={() => GA.event('Header', 'Click on FleetPulse logo')}><img src={logo} alt="FleetPulse" /></Link>
            </div>
            <div className='nav-items'>
                <ul className="nav-links">
                    <li>
                        <NavLink id={'nav-home-link'} exact to={`/${props.language.toLowerCase()}/`} activeClassName="active" onClick={() => GA.event('Header', 'Click on Home')}>
                            <FormattedMessage {...messages.home}>{msg => msg}</FormattedMessage>
                        </NavLink>
                    </li>
                    <li>
                        <FormattedMessage {...pathnames.features}>
                        {
                            msg =>
                            <NavLink id={'nav-features-link'} exact to={`/${props.language.toLowerCase()}/${msg}`} activeClassName="active" onClick={() => GA.event('Header', 'Click on Features')}>
                                <FormattedMessage {...messages.features}>{msg => msg}</FormattedMessage>
                            </NavLink>
                        }
                        </FormattedMessage>
                    </li>
                    <li>
                        <FormattedMessage {...pathnames.tpms}>
                        {
                            msg =>
                            <NavLink id={'nav-tpms-link'} exact to={`/${props.language.toLowerCase()}/${msg}`} activeClassName="active" onClick={() => GA.event('Header', 'Click on TPMS')}>
                                <FormattedMessage {...messages.tmps}>{msg => msg}</FormattedMessage>
                            </NavLink>
                        }
                        </FormattedMessage>
                    </li>
                    <li>
                        <FormattedMessage {...pathnames.faqs}>
                        {
                            msg =>
                            <NavLink id={'nav-faqs-link'} exact to={`/${props.language.toLowerCase()}/${msg}`} activeClassName="active" onClick={() => GA.event('Header', 'Click on FAQs')}>
                                <FormattedMessage {...messages.faqs}>{msg => msg}</FormattedMessage>
                            </NavLink>
                        }
                        </FormattedMessage>
                    </li>
                </ul>
                <ul className="nav-links-outside">
                    <li>
                        <a id={'nav-login-link'} href={`https://my.fleetpulse.app/?lang=${props.language}`} onClick={() => GA.event('Header', 'Click on Log-in')}>
                            <FormattedMessage {...messages.logIn}>{msg => msg}</FormattedMessage>
                        </a>
                    </li>
                    <li className="red">
                        <FormattedMessage {...pathnames.signUp}>
                        {
                            msg =>
                            <NavLink id={'nav-signup-link'} exact to={`/${props.language.toLowerCase()}/${msg}`} activeClassName="active" onClick={() => GA.event('Header', 'Click on Sign-up')}>
                                <FormattedMessage {...messages.signUp}>{msg => msg}</FormattedMessage>
                            </NavLink>
                        }
                        </FormattedMessage>
                    </li>
                </ul>
                <LanguageSelector
                    flagsSelector
                    languages={["GB", "FR", "DE", "ES", "IT", "PL"]}
                    customLabels={{"GB": "EN", "FR": "FR", "DE": "DE", "ES": "ES", "IT": "IT", "PL": "PL"}}
                    className="language-selector flag-selector"
                />
            </div>
            <div className="nav-trigger" onClick={props.openMenu}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </nav>
    </div>
);
