// React librarys
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Import Assets
import './styles.css';
import playIconRed from './../../images/play-icon-red.svg';
import playIconWhite from './../../images/play-icon-white.svg';

// Import Helpers
import { generateRandomId } from './../../helpers'

// Import translations
import messages from './messages';


/* Component */
export const Button = props => (
    <button id={`${props.id ? props.id : generateRandomId('button')}`} className={`${props.className ? props.className : ''} ${props.btnVideoLink ? 'video-link' : ''}`} onClick={props.onClick}>
        {
            props.btnVideoLink &&
            <img src={props.className === 'white' ? playIconWhite : playIconRed} alt=""/>
        }
        {
            props.btnVideoLink &&
            <FormattedMessage {...messages.videoLink}>{msg => msg}</FormattedMessage>
        }
        {
            !props.btnVideoLink &&
            <span className={'btn-content'}>
                {props.children}
            </span>
        }
    </button>
);
