import { debounce } from "lodash";

/**
 * @typedef {string} MediaQueryName
 * @typedef {string} MediaQueryRule
 * @typedef {{[key: MediaQueryName]: MediaQueryRule}} MediaQueryRules
 */

 export default class MediaQueryListener {
  /**
   * MediaQueryListener is extension for matchMedia interface and allows to define 
   * more than one named media query to listen.
   * IMPORTANT: media queries must be exclusive because at single cycle only first
   * matching query will be returned in callback as matchedQueryRule. So use it
   * with awareness!!
   * 
   * Example usage in component:
   * 
   * // in constructor:
   * this.mediaQueryListener = new MediaQueryListener({
   *   small: 'screen and (max-width: 479.8px)',
   *   big: 'screen and (min-width: 480px)'
   * });
   * this.mediaQueryChangeHandler = this.mediaQueryChangeHandler.bind(this);
   * 
   * // in componentDidMount
   * this.mediaQueryListener.add(this.mediaQueryChangeHandler).on();
   * 
   * // in componentWillUnmount
   * this.mediaQueryListener.remove(this.mediaQueryChangeHandler).off();
   * 
   * @param {MediaQueryRules} queries 
   */
  constructor(queries = {}) { 
    this.queries = queries;
    this.mediaQueries = {};
    this.callbacks = [];

    for (let q in this.queries) {
      this.mediaQueries[q] = window.matchMedia(this.queries[q]);
    }
    this.callbackProxy = this.callbackProxy.bind(this);
  }

  /**
   * Enable whole listener
   * 
   * @returns {MediaQueryListener} self
   */
  on() {
    for (let mq in this.mediaQueries) {
      this.mediaQueries[mq].addListener(this.callbackProxy);
    }
    return this;
  }

  /**
   * Disable whole listener
   * 
   * @returns {MediaQueryListener} self
   */
  off() {
    for (let mq in this.mediaQueries) {
      this.mediaQueries[mq].removeListener(this.callbackProxy);
    }
    return this;
  }

  /**
   * Add single callback
   * 
   * @param {function} callback 
   * 
   * @returns {MediaQueryListener} self
   */
  add(callback) {
    this.callbacks.push(callback);
    return this;
  }

  /**
   * Remove single callback
   * 
   * @param {function} callback 
   * 
   * @returns {MediaQueryListener} self
   */
  remove(callback) {
    this.callbacks = this.callbacks.filter((currentCallback) => {
      return currentCallback !== callback;
    });
    return this;
  }

  /**
   * @returns {string|undefined} matched media query name
   */
  getMatchedQueryRule() {
    for (let mqName in this.mediaQueries) {
      if (this.mediaQueries[mqName].matches) {
        return mqName;
      }
    }
  }
};

MediaQueryListener.prototype.callbackProxy = debounce(function() {
  const matchedQuery = this.getMatchedQueryRule();
  for (let callback of this.callbacks) {
    callback(matchedQuery);
  }
}, 20);