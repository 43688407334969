/*
 * Component Reducers
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state. To add a new action, add it to the switch statement 
 * in the reducer function.
 *
 * Example:
 * 
 * // Import constants
 * import {
 *    GET_DATA_REQUESTED,
 * } from './constants';
 * 
 * // Create component initial state
 * const initialState = fromJS({
 *    loading: false
 * });
 * 
 * // Component reducers
 * function reducerName(state = initialState, action) {
 *    switch (action.type) {
 * 
 *       // Reducer - on get login data requested
 *          case GET_DATA_REQUESTED :
 *          return state
 *          .set('loading', true);
 * 
 *       // Reducer - return initial state
 *       default:
 *          return state;
 *    }
 * }
 * 
 * export default reducerName;
 */
import { fromJS } from 'immutable';
import * as constant from './constants';


// Create component initial state
const initialState = fromJS({
	apiData: null,
	apiDataReady: false
});


// Component reducers
export default function reducer(state = initialState, action) {
	switch (action.type) {
		
		// Reducer - on get data requested done
		case constant.GET_HOME_DATA_REQUESTED_DONE:
			return state
				.set('apiData', action.data)
				.set('apiDataReady', true);

		// Reducer - return initial state *
		default:
			return state;
	}
}