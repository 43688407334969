// React imports
import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Import selectors
import * as selector from './selectors';

// Import Components
import { Header } from './../../components/Header';
import { Section } from './../../components/Section';
import { AvailableFor } from './../../components/AvailableFor';
import { BoxWithImage } from './../../components/BoxWithImage';
import { VideoModal } from './../../components/VideoModal';

// Import Containers
import PageLayout from './../PageLayout';
import HowItWorks from './../../containers/HowItWorks';

// Import Translations
import messages from './messages';
import pathnames from './../../routing-messages';

// Styles & Assets
import './styles.css';
import headerBg from './../../images/home/header-bg.jpg';
import section3BG from './../../images/home/section3-bg.jpg';
import section4BG from './../../images/home/section4-bg.jpg';

//Import Localised images
//EN
import headerImageItemEN from './../../images/home/header-image-item-EN.png';
import section2BoxImage1EN from './../../images/home/section2-box-image1-EN.jpg';
import section2BoxImage2EN from './../../images/home/section2-box-image2-EN.jpg';
import section2BoxImage3EN from './../../images/home/section2-box-image3-EN.jpg';
//DE
import headerImageItemDE from './../../images/home/header-image-item-DE.png';
import section2BoxImage1DE from './../../images/home/section2-box-image1-DE.jpg';
import section2BoxImage2DE from './../../images/home/section2-box-image2-DE.jpg';
import section2BoxImage3DE from './../../images/home/section2-box-image3-DE.jpg';
//FR
import headerImageItemFR from './../../images/home/header-image-item-FR.png';
import section2BoxImage1FR from './../../images/home/section2-box-image1-FR.jpg';
import section2BoxImage2FR from './../../images/home/section2-box-image2-FR.jpg';
import section2BoxImage3FR from './../../images/home/section2-box-image3-FR.jpg';
//FR
import headerImageItemES from './../../images/home/header-image-item-ES.png';
import section2BoxImage1ES from './../../images/home/section2-box-image1-ES.jpg';
import section2BoxImage2ES from './../../images/home/section2-box-image2-ES.jpg';
import section2BoxImage3ES from './../../images/home/section2-box-image3-ES.jpg';
//IT
import headerImageItemIT from './../../images/home/header-image-item-IT.png';
import section2BoxImage1IT from './../../images/home/section2-box-image1-IT.jpg';
import section2BoxImage2IT from './../../images/home/section2-box-image2-IT.jpg';
import section2BoxImage3IT from './../../images/home/section2-box-image3-IT.jpg';
//PL
import headerImageItemPL from './../../images/home/header-image-item-PL.png';
import section2BoxImage1PL from './../../images/home/section2-box-image1-PL.jpg';
import section2BoxImage2PL from './../../images/home/section2-box-image2-PL.jpg';
import section2BoxImage3PL from './../../images/home/section2-box-image3-PL.jpg';

// Import Helpers
import history from './../../history';
import GA from './../../ga';

const localisedImages = {
	'headerImageItemEN': headerImageItemEN,
	'section2BoxImage1EN': section2BoxImage1EN,
	'section2BoxImage2EN': section2BoxImage2EN,
	'section2BoxImage3EN': section2BoxImage3EN,
	'headerImageItemDE': headerImageItemDE,
	'section2BoxImage1DE': section2BoxImage1DE,
	'section2BoxImage2DE': section2BoxImage2DE,
	'section2BoxImage3DE': section2BoxImage3DE,
	'headerImageItemFR': headerImageItemFR,
	'section2BoxImage1FR': section2BoxImage1FR,
	'section2BoxImage2FR': section2BoxImage2FR,
	'section2BoxImage3FR': section2BoxImage3FR,
	'headerImageItemES': headerImageItemES,
	'section2BoxImage1ES': section2BoxImage1ES,
	'section2BoxImage2ES': section2BoxImage2ES,
	'section2BoxImage3ES': section2BoxImage3ES,
	'headerImageItemIT': headerImageItemIT,
	'section2BoxImage1IT': section2BoxImage1IT,
	'section2BoxImage2IT': section2BoxImage2IT,
	'section2BoxImage3IT': section2BoxImage3IT,
	'headerImageItemPL': headerImageItemPL,
	'section2BoxImage1PL': section2BoxImage1PL,
	'section2BoxImage2PL': section2BoxImage2PL,
	'section2BoxImage3PL': section2BoxImage3PL,
}

const ytVideos = {
	'DE': 'ObaCM7WN8Ds',
	'EN': '2NJZZNxO6rI',
	'ES': 'ARTFF6n0P6E',
	'FR': 'VFic1x7XaT8',
	'IT': 'zC7c4B_AIXs',
	'PL': 'izGTAAQhJa4'
}


/* Container */
class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			videoModalOpened: false,
			isDesktop: false,
		}

		this.closeVideoModal = this.closeVideoModal.bind(this);
		this.section2Box2BtnClick = this.section2Box2BtnClick.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.setStateBasedOnScreenSize.bind(this));
		this.setStateBasedOnScreenSize();
		window.localStorage.setItem('page_id', '');
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.setStateBasedOnScreenSize.bind(this));
	}

	setStateBasedOnScreenSize(e) {
		if (window.innerWidth >= 940) {
			this.setState({
				isDesktop: true
			});
		}
		else {
			this.setState({
				isDesktop: false
			});
		}
	}

	headerBTNClick() {
		this.setState({
			videoModalOpened: true
		});
		GA.event('Homepage', 'WATCH FLEETPULSE IN ACTION');
	}
	headerBTN2Click(translatedLink) {
		GA.event('Homepage', 'Register your fleet.');
		history.push(`/${this.props.pageLanguage.toLowerCase()}/${translatedLink}`);
	}

	availableForBtnClick(translatedLink) {
		history.push(`/${this.props.pageLanguage.toLowerCase()}/${translatedLink}`);
		GA.event('Homepage', 'Take control of your fleet CTA');
	}

	section3BoxBtnClick(translatedLink) {
		history.push(`/${this.props.pageLanguage.toLowerCase()}/${translatedLink}`);
		GA.event('Homepage', 'More about features CTA');
	}

	section4BoxBtnClick(translatedLink) {
		history.push(`/${this.props.pageLanguage.toLowerCase()}/${translatedLink}`);
		GA.event('Homepage', 'More about TPMS CTA');
	}

	section2Box2BtnClick() {
		const language = this.props.pageLanguage ? this.props.pageLanguage : 'EN';
		GA.event('Homepage', 'Available on Google Play.');
		window.open(`https://play.google.com/store/apps/details?id=my.fleetpulse.app&hl=${language}`, '_blank');
	}

	section2Box1BtnClick(translatedLink) {
		GA.event('Homepage', 'Register your fleet.');
		history.push(`/${this.props.pageLanguage.toLowerCase()}/${translatedLink}`);
	}

	closeVideoModal() {
		this.setState({
			videoModalOpened: false
		});
	}

	render() {
		const { ...props } = this.props;
		const { ...state } = this.state;

		const language = props.pageLanguage ? props.pageLanguage : 'EN';
		const headerImageItem = localisedImages[`headerImageItem${language}`];
		const section2BoxImage1 = localisedImages[`section2BoxImage1${language}`];
		const section2BoxImage2 = localisedImages[`section2BoxImage2${language}`];
		const section2BoxImage3 = localisedImages[`section2BoxImage3${language}`];

		return (
			<PageLayout
				pageName='homepage'
				metaTitle={messages.metaTitle}
				metaDescription={messages.metaDescription}
				metaKeywords={messages.metaKeywords}
			>

				<FormattedMessage {...pathnames.signUp}>
					{msg =>
						<Header
							title={<FormattedMessage {...messages.headerTitle}>{msg => msg}</FormattedMessage>}
							itemList={[
								<FormattedMessage {...messages.headerListItem1}>{msg => msg}</FormattedMessage>,
								<FormattedMessage {...messages.headerListItem2}>{msg => msg}</FormattedMessage>,
								<FormattedMessage {...messages.headerListItem3}>{msg => msg}</FormattedMessage>,
								<FormattedMessage {...messages.headerListItem4}>{msg => msg}</FormattedMessage>
							]}
							btnClick={this.headerBTN2Click.bind(this, msg)}
							btn2Click={this.headerBTNClick.bind(this, msg)}
							btnCopy={<FormattedMessage {...messages.headerBtnCopy}>{msg => msg}</FormattedMessage>}
							headerBg={headerBg}
							headerImageItem={headerImageItem}
							buttonId={'home-header-btn'}
							btnVideoLink
							videoBg
							isDesktop={state.isDesktop}
						/>
					}
				</FormattedMessage>

				<HowItWorks />

				<Section
					withCollumns
					imageBg={false}
					sectionBg='#f3f3f3'
					sectionNumber='02'
					title={<FormattedMessage {...messages.section02Title}>{msg => msg}</FormattedMessage>}
					subTitle={<FormattedMessage {...messages.section02Subtitle}>{msg => msg}</FormattedMessage>}
				>
					<FormattedMessage {...pathnames.signUp}>
						{msg =>
							<BoxWithImage
								reverse={false}
								textColor='#20272A'
								image={section2BoxImage1}
								contentBoxColor='#ffffff'
								title={<FormattedMessage {...messages.section02Box1Title}>{msg => msg}</FormattedMessage>}
								copy={<FormattedMessage {...messages.section02Box1Copy}>{msg => msg}</FormattedMessage>}
							/>
						}
					</FormattedMessage>
					<BoxWithImage
						reverse={true}
						textColor='#20272A'
						image={section2BoxImage2}
						contentBoxColor='#ffffff'
						title={<FormattedMessage {...messages.section02Box2Title}>{msg => msg}</FormattedMessage>}
						copy={<FormattedMessage {...messages.section02Box2Copy}>{msg => msg}</FormattedMessage>}
					/>
					<BoxWithImage
						reverse={false}
						textColor='#20272A'
						image={section2BoxImage3}
						contentBoxColor='#ffffff'
						title={<FormattedMessage {...messages.section02Box3Title}>{msg => msg}</FormattedMessage>}
						copy={<FormattedMessage {...messages.section02Box3Copy}>{msg => msg}</FormattedMessage>}
					/>
				</Section>

				<Section
					copyBoxTopRight
					imageBg={true}
					sectionBg={`url('${section3BG}')`}
					sectionNumber='03'
				>
					<FormattedMessage {...pathnames.features}>
						{msg =>
							<BoxWithImage
								reverse={true}
								textColor='#ffffff'
								contentBoxColor='#f3f3f3'
								title={<FormattedMessage {...messages.section03Box1Title}>{msg => msg}</FormattedMessage>}
								copy={<FormattedMessage {...messages.section03Box1Copy}>{msg => msg}</FormattedMessage>}
								btnCopy={<FormattedMessage {...messages.section03Box1BtnCopy}>{msg => msg}</FormattedMessage>}
								buttonId={'box-image-section3-btn'}
								buttonOnClick={this.section3BoxBtnClick.bind(this, msg)}
							/>
						}
					</FormattedMessage>
				</Section>

				<Section
					copyBoxTopLeft
					imageBg={false}
					sectionBg={`url('${section4BG}')`}
					sectionNumber='04'
				>
					<FormattedMessage {...pathnames.tpms}>
						{msg =>
							<BoxWithImage
								reverse={false}
								textColor='#20272A'
								contentBoxColor='#f3f3f3'
								title={<FormattedHTMLMessage {...messages.section04Box1Title}></FormattedHTMLMessage>}
								copy={<FormattedMessage {...messages.section04Box1Copy}>{msg => msg}</FormattedMessage>}
								btnCopy={<FormattedMessage {...messages.section04Box1BtnCopy}>{msg => msg}</FormattedMessage>}
								buttonId={'box-image-section4-btn'}
								buttonOnClick={this.section4BoxBtnClick.bind(this, msg)}
							/>
						}
					</FormattedMessage>
				</Section>

				<FormattedMessage {...pathnames.signUp}>
					{msg =>
						<AvailableFor
							topText={<FormattedMessage {...messages.availableForTopCopy}>{msg => msg}</FormattedMessage>}
							buttonText={<FormattedMessage {...messages.availableForBtnCopy}>{msg => msg}</FormattedMessage>}
							buttonOnClick={this.availableForBtnClick.bind(this, msg)}
							buttonId={'home-cta-btn'}
							bottomTextLinkId={'home-cta-link'}
						/>
					}
				</FormattedMessage>

				{
					state.videoModalOpened &&
					<VideoModal
						link={`${ytVideos[language]}`}
						closeModal={this.closeVideoModal}
					/>
				}

			</PageLayout>
		);
	}
}

// Listen to store value
const mapStateToProps = createStructuredSelector({
	pageLanguage: selector.pageLanguage()
});

// Change state name base on actions
const mapDispatchToProps = dispatch => {
	return {
		// saveQueryString: value => dispatch(saveQueryString(value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
