// React imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import Select from "react-select";
import getCountryISO2 from "country-iso-3-to-2";
import axios from "axios";
import * as constant from "./constants";
import { getStorage } from "./../../utils/localStorage";

// Import Material-Ui
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import TextField from "material-ui/TextField";

// Import Components
import { Header } from "./../../components/Header";
import CountryCodeSelector from "./../../components/CountryCodeSelector";

// Import Containers
import PageLayout from "./../PageLayout";

// Actions
import * as action from "./actions";

// Selector
import * as selector from "./selectors";

// Import Translations
import messages from "./messages";

// Import helpers
import * as helper from "./../../helpers";
import MediaQueryListener from "./../../utils/MediaQueryListener";

// Styles & Assets
import "./styles.css";
import successSendIcon from "./../../images/ic-confirmation2.svg";
import errorSendIcon from "./../../images/ic-error.svg";
import tooltipIconBlack from "./../../images/tooltip-icon-black.svg";
import leftArrowRed from "./../../images/left-arrow-red.svg";
import signupIllustration from "./../../images/sign-up/signup-illustration.png";
import GA from "../../ga";

// Change basic materialUI style
const styles = {
  errorStyle: {
    color: "#ed1c24",
    position: "absolute",
    bottom: "-8px",
    borderColor: "transparent"
  },
  underlineStyle: {
    borderColor: "transparent"
  },
  underlineFocusStyle: {
    borderColor: "transparent"
  }
};

/* Container */
class SignUp extends Component {
  constructor(props) {
    super(props);

    this.fleetNameInput = React.createRef();
    
    this.mediaQueryListener = new MediaQueryListener({
      flatScreen: "screen and (max-height: 899.8px) and (min-width: 1001px)",
      overFlatScreen: "screen and (min-height: 900px)",
    }).on();

    this.handleMediaQueryRuleChange = this.handleMediaQueryRuleChange.bind(this);

    this.state = {
      step: 1,
      phoneNumbers: {
        EN: "08000266241",
        FR: "",
        DE: "",
        ES: "0800 000846",
        IT: "",
        PL: ""
      },
      emailAddress: "https://help.fleetpulse.app/en/support/tickets/new",
      phoneNumberPerCountry: "",
      isEmailAddressValid: false,
      marketingConsent: false,
      showFleetNamePlaceholder: true,
      matchedQueryRule: this.mediaQueryListener.getMatchedQueryRule()
    }; 
  }

  handleMediaQueryRuleChange(matchedQueryRule) {
    this.setState({
      matchedQueryRule
    });
  }

  componentDidMount() {
    const {
      history,
      pageLanguage,
      clearForm,
      getCountriesList,
      getBusinessTypesList,
      getGetFleetSizeList,
      getCountryByIP
    } = this.props;

    clearForm();
    getGetFleetSizeList();
    getCountriesList(pageLanguage);
    getBusinessTypesList(pageLanguage);
    console.log("request get country by ip");
    getCountryByIP();

    this.setState({
      phoneNumberPerCountry: this.state.phoneNumbers[pageLanguage],
      emailAddress: `https://help.fleetpulse.app/${pageLanguage.toLowerCase()}/support/tickets/new`
    });

    history.block((location, action) => {
      if (action === "POP" && this.state.step === 2) {
        this.previousStep();
        return false;
      }
    });

    window.localStorage.setItem("page_id", "page.UrlName.SignUp");
    this.mediaQueryListener.add(this.handleMediaQueryRuleChange);
  }

  componentWillUnmount() {
    this.mediaQueryListener.remove(this.handleMediaQueryRuleChange);
  }

  changeInput(e) {
    if (e.target.name === "marketingConsent") {
      this.setState({
        marketingConsent: !this.state.marketingConsent
      });
    } else if (e.target.name === "operatorPhone") {
      console.log("phoneChanged: ", e);
      // if value is not blank, then test the regex
      if (e.target.value === "" || helper.phoneReqexp.test(e.target.value)) {
        this.props.changeInputValue(e.target.name, e.target.value);
      }
    } else {
      this.props.changeInputValue(e.target.name, e.target.value);
    }
    this.props.saveFleetDetailsSuccess(null);
  }

  selectOnChange(selectName, selectedValue, flagCode) {
    if (selectedValue.value === undefined) {
      this.props.selectOnChange(selectName, flagCode.value);
    } else {
      this.props.selectOnChange(selectName, selectedValue.value);
    }

    this.props.saveFleetDetailsSuccess(null);

    if (selectName === "country") {
      const internationalCode = selectedValue.value
        ? selectedValue.value.length > 2
          ? getCountryISO2(selectedValue.value)
          : selectedValue.value
        : null;
      const phoneCode = helper.getCountryPhoneCodeForFlagCode(
        internationalCode
      );
      this.props.setNewPhoneCode(phoneCode);
      this.props.selectOnChange("internationalCode", internationalCode);
      // if (!this.props.pageData.internationalCode) {
      //   this.props.selectOnChange("internationalCode", internationalCode);
      // }
    }

    if (selectName === "internationalCode") {
      const phoneCode = helper.getCountryPhoneCodeForFlagCode(
        flagCode.value ? flagCode.value : flagCode
      );
      this.props.setNewPhoneCode(phoneCode);
    }
  }

  handleRadioChange(e) {
    this.props.handleRadioChange(e.target.name, e.target.value == "true");
  }

  validateFormFirstStep() {
    let valid = true;
    const { ...props } = this.props;
    const formData = this.props.pageData;

    const fields = [
      { name: "firstName", value: formData.firstName },
      { name: "lastName", value: formData.lastName },
      { name: "emailAddress", value: formData.emailAddress },
      { name: "password", value: formData.password },
      { name: "confirmPassword", value: formData.confirmPassword }
    ];

    // Default errors
    fields.forEach(item => {
      if (item.value === "") {
        props.fieldError(item.name, helper.errorCodes.defaultError);
        valid = false;
      }
    });

    // Email Validation
    if (
      formData.emailAddress !== "" &&
      !formData.emailAddress.match(helper.emailReqexp)
    ) {
      props.fieldError("emailAddress", helper.errorCodes.invalidEmail);
      valid = false;
    }
    if (formData.emailAddress !== "" && !this.state.isEmailAddressValid) {
      props.fieldError("emailAddress", helper.errorCodes.emailAlreadyExists);
      valid = false;
    }

    if (
      formData.password !== "" &&
      !formData.password.match(helper.passwordReqexp) &&
      formData.password !== "fleetpulse"
    ) {
      props.fieldError("password", helper.errorCodes.invalidPassword);
      valid = false;
    }
    if (
      formData.confirmPassword !== "" &&
      formData.confirmPassword !== formData.password
    ) {
      props.fieldError(
        "confirmPassword",
        helper.errorCodes.invalidConfirmPassword
      );
      valid = false;
    }

    return valid;
  }

  validateFormSecondStep() {
    let valid = true;
    const formData = this.props.pageData;

    const fields = [
      { name: "fleetName", value: formData.fleetName },
      { name: "country", value: formData.country },
      {
        name: "internationalCode",
        value: formData.internationalCode
      },
      {
        name: "operatorPhone",
        value: formData.operatorPhone
      },
      { name: "fleetSize", value: formData.fleetSize },
      { name: "businessType", value: formData.businessType }
    ];

    // Default errors
    fields.forEach(item => {
      if (item.value === "") {
        this.props.fieldError(item.name, helper.errorCodes.defaultError);
        valid = false;
      }
    });

    if (formData.businessType === "BTOTH" && formData.businessTypeText === "") {
      console.log("Error - Busines type other & empty");
      this.props.fieldError("businessTypeText", helper.errorCodes.defaultError);
      valid = false;
    }
    if (
      formData.businessType === "BTOTH" &&
      !helper.alphanumeric.test(formData.businessTypeText)
    ) {
      console.log("Error - Busines type other & input format error");
      this.props.fieldError(
        "businessTypeText",
        helper.errorCodes.invalidCharacterError
      );
      valid = false;
    }

    if (
      formData.operatorPhone != "" &&
      formData.operatorPhone != null &&
      !formData.operatorPhone.match(helper.phoneReqexp)
    ) {
      console.log("ERROR - Phone format error");
      this.props.fieldError(
        "operatorPhone",
        helper.errorCodes.invalidPhoneError
      );
      valid = false;
    }
    // User international code added & phone empty
    if (
      (formData.internationalCode != null &&
        formData.internationalCode != "" &&
        formData.operatorPhone === null) ||
      (formData.internationalCode != null &&
        formData.internationalCode != "" &&
        formData.operatorPhone === "")
    ) {
      console.log("ERROR - User international code added & phone empty");
      this.props.fieldError("operatorPhone", helper.errorCodes.defaultError);
      valid = false;
    }

    // Phone added & international code empty
    if (
      (formData.operatorPhone != null &&
        formData.operatorPhone != "" &&
        formData.operatorPhone.match(helper.phoneReqexp) &&
        formData.internationalCode === null) ||
      (formData.operatorPhone != null &&
        formData.operatorPhone != "" &&
        formData.operatorPhone.match(helper.phoneReqexp) &&
        formData.internationalCode === "")
    ) {
      console.log("ERROR - Phone added & international code empty");
      this.props.fieldError(
        "internationalCode",
        helper.errorCodes.defaultError
      );
      valid = false;
    }

    return valid;
  }

  checkEmailAddress(
    firstName,
    lastName,
    emailAddress,
    password,
    confirmPassword,
    fleetName,
    operatorPhone,
    internationalCode,
    countryPhoneCode,
    country,
    termsCondition,
    pageLanguage,
    marketingConsent,
    fleetSize,
    businessType,
    businessTypeText
  ) {
    const requestLink = `${helper.apiLink}/api/promo/ValidateFirstStep`;
    return axios({
      method: "post",
      url: requestLink,
      responseType: "json",
      headers: {
        Authorization: getStorage("Authorization").authorization
      },
      data: {
        FirstName: firstName,
        LastName: lastName,
        EmailAddress: emailAddress,
        Password: password,
        ConfirmPassword: confirmPassword,
        FleetName: fleetName,
        PhoneNumber: operatorPhone,
        InternationalCode: internationalCode,
        PhoneCountryCode: countryPhoneCode,
        Country: country,
        TermsConditions: termsCondition,
        Language: pageLanguage,
        AcceptMarketing: marketingConsent,
        FleetSize: fleetSize,
        BusinessType: businessType,
        BusinessTypeText: businessTypeText
      }
    }).then(response => {
      this.setState({ isEmailAddressValid: response.data.Result });
    });
  }

  handleScrollToStats() {
    window.scrollTo({ top: 0 });
  }

  nextStep(e) {
    e.preventDefault();
    GA.event("Sign Up", "CTA Next", "Registration");

    const { saveFleetDetailsSuccess, pageLanguage, pageData } = this.props;

    this.checkEmailAddress(
      pageData.firstName,
      pageData.lastName,
      pageData.emailAddress,
      pageData.password,
      pageData.confirmPassword,
      pageData.fleetName,
      pageData.operatorPhone,
      pageData.internationalCode,
      pageData.countryPhoneCode,
      pageData.country,
      pageData.termsCondition,
      pageLanguage,
      pageData.marketingConsent,
      pageData.fleetSize,
      pageData.businessType
    ).then(() => {
      if (this.validateFormFirstStep()) {
        this.setState({ step: 2 });
        this.handleScrollToStats();
      } else {
        saveFleetDetailsSuccess(false);
      }
    });
  }

  previousStep() {
    this.setState({ step: 1 });
    GA.event("Sign Up", "CTA Previous step", "Registration");
  }

  saveForm(e) {
    e.preventDefault();
    const pageData = this.props.pageData;
    GA.event("Sign Up", "CTA Sign up now", "Registration");

    if (this.validateFormFirstStep() && this.validateFormSecondStep()) {
      this.props.saveFleetDetails(
        pageData.firstName,
        pageData.lastName,
        pageData.emailAddress,
        pageData.password,
        pageData.confirmPassword,
        pageData.fleetName,
        pageData.operatorPhone,
        pageData.internationalCode,
        pageData.countryPhoneCode,
        pageData.country,
        pageData.termsCondition,
        this.props.pageLanguage,
        pageData.marketingConsent,
        pageData.fleetSize,
        pageData.businessType,
        pageData.businessTypeText
      );
    } else {
      this.props.saveFleetDetailsSuccess(false);
    }
  }

  sendGaContactEvent = e => {
    if (e.target.tagName.toLowerCase() === "a") {
      GA.event("Sign Up", "CTA Contact us", "Registration");
    }
  };

  render() {
    const { ...props } = this.props;
    const { ...state } = this.state;

    const language = props.pageLanguage ? props.pageLanguage : "EN";
    const country = props.pageData.country;

    const TC_PDF = () => {
      return `/files/${language}_-_Terms_and_Conditions.pdf`;
    };

    const privacyLink = (
      <FormattedMessage {...messages.privacyLink}>
        {msg => (
          <a
            id={"signup-privacy-link"}
            className="link"
            href={`/files/${msg}.pdf`}
            target="_blank"
          >
            <FormattedMessage {...messages.privacy}>{i => i}</FormattedMessage>
          </a>
        )}
      </FormattedMessage>
    );

    const termsLink = (
      <a id={"signup-tc-link"} className="link" href={TC_PDF()} target="_blank">
        <FormattedMessage {...messages.terms}>{i => i}</FormattedMessage>
      </a>
    );

    const handleMultipleErrorsFirstName = helper.handleMultipleErrors(
      props.pageData.firstNameError,
      <FormattedMessage {...messages.errorInvalidCharacters}>
        {i => i}
      </FormattedMessage>,
      <FormattedMessage {...messages.errorRequired}>{i => i}</FormattedMessage>
    );

    const handleMultipleErrorsLastName = helper.handleMultipleErrors(
      props.pageData.lastNameError,
      <FormattedMessage {...messages.errorInvalidCharacters}>
        {i => i}
      </FormattedMessage>,
      <FormattedMessage {...messages.errorRequired}>{i => i}</FormattedMessage>
    );

    const handleMultipleErrorsEmailAddress = helper.handleMultipleErrors(
      props.pageData.emailAddressError,
      <FormattedMessage {...messages.errorInvalidEmail}>
        {i => i}
      </FormattedMessage>,
      <FormattedMessage {...messages.errorRequired}>{i => i}</FormattedMessage>,
      <FormattedMessage {...messages.errorEmailAlreadyExists}>
        {i => i}
      </FormattedMessage>
    );

    const handleMultipleErrorsPassword = helper.handleMultipleErrors(
      props.pageData.passwordError,
      <FormattedMessage {...messages.errorInvalidPassword}>
        {i => i}
      </FormattedMessage>,
      <FormattedMessage {...messages.errorRequired}>{i => i}</FormattedMessage>
    );

    const handleMultipleErrorsConfirmPassword = helper.handleMultipleErrors(
      props.pageData.confirmPasswordError,
      <FormattedMessage {...messages.errorInvalidConfirmPassword}>
        {i => i}
      </FormattedMessage>,
      <FormattedMessage {...messages.errorRequired}>{i => i}</FormattedMessage>
    );

    const formFirstStep = (
      <div>
        <div className="form-group">
          <label>
            <FormattedMessage {...messages.firstName}>
              {i => i}
            </FormattedMessage>
          </label>
          <div className="right">
            <TextField
              className={`form__textfield ${
                !!handleMultipleErrorsFirstName ? " error" : null
                }`}
              value={props.pageData.firstName}
              name="firstName"
              onChange={this.changeInput.bind(this)}
              errorText={handleMultipleErrorsFirstName}
              underlineStyle={styles.underlineStyle}
              errorStyle={styles.errorStyle}
              underlineFocusStyle={styles.underlineFocusStyle}
              maxLength="50"
            />
          </div>
        </div>
        <div className="form-group">
          <label>
            <FormattedMessage {...messages.lastName}>{i => i}</FormattedMessage>
          </label>
          <div className="right">
            <TextField
              className={`form__textfield ${
                !!handleMultipleErrorsLastName ? " error" : null
                }`}
              value={props.pageData.lastName}
              name="lastName"
              onChange={this.changeInput.bind(this)}
              errorText={handleMultipleErrorsLastName}
              underlineStyle={styles.underlineStyle}
              errorStyle={styles.errorStyle}
              underlineFocusStyle={styles.underlineFocusStyle}
              maxLength="50"
            />
          </div>
        </div>
        <div className="form-group">
          <label>
            <FormattedMessage {...messages.emailAddress}>
              {i => i}
            </FormattedMessage>
          </label>
          <div className="right">
            <TextField
              className={`form__textfield ${
                !!handleMultipleErrorsEmailAddress ? " error" : null
                }`}
              value={props.pageData.emailAddress}
              name="emailAddress"
              onChange={this.changeInput.bind(this)}
              errorText={handleMultipleErrorsEmailAddress}
              underlineStyle={styles.underlineStyle}
              errorStyle={styles.errorStyle}
              underlineFocusStyle={styles.underlineFocusStyle}
              maxLength="50"
            />
          </div>
        </div>
        <div className="form-group">
          <label>
            <FormattedMessage {...messages.password}>{i => i}</FormattedMessage>
          </label>
          <div className="right">
            <TextField
              type="password"
              className={`form__textfield ${
                !!handleMultipleErrorsPassword ? " error" : null
                }`}
              value={props.pageData.password}
              name="password"
              onChange={this.changeInput.bind(this)}
              errorText={handleMultipleErrorsPassword}
              underlineStyle={styles.underlineStyle}
              errorStyle={styles.errorStyle}
              underlineFocusStyle={styles.underlineFocusStyle}
              maxLength="50"
            />
          </div>
        </div>
        <div className="form-group">
          <label>
            <FormattedMessage {...messages.confirmPassword}>
              {i => i}
            </FormattedMessage>
          </label>
          <div className="right">
            <TextField
              type="password"
              className={`form__textfield ${
                !!handleMultipleErrorsConfirmPassword ? " error" : null
                }`}
              value={props.pageData.confirmPassword}
              name="confirmPassword"
              onChange={this.changeInput.bind(this)}
              errorText={handleMultipleErrorsConfirmPassword}
              underlineStyle={styles.underlineStyle}
              errorStyle={styles.errorStyle}
              underlineFocusStyle={styles.underlineFocusStyle}
              maxLength="50"
            />
          </div>
        </div>
        {props.pageData.saveFleetDetailsSuccess && (
          <div className="form-group">
            <span className="label-copy">
              <img src={successSendIcon} alt="" />{" "}
              <FormattedMessage {...messages.changesSaved}>
                {msg => msg}
              </FormattedMessage>
            </span>
          </div>
        )}
        {props.pageData.saveFleetDetailsSuccess === false && (
          <div className="form-group">
            <span className="label-copy">
              <img src={tooltipIconBlack} alt="" />{" "}
              <FormattedMessage {...messages.changesNotSaved}>
                {msg => msg}
              </FormattedMessage>
            </span>
          </div>
        )}
        <div className="form-group no-label-mobile">
          <div className="right form-info align-left">
            <button
              id="next-step"
              className="btn red"
              onClick={this.nextStep.bind(this)}
            >
              <FormattedMessage {...messages.nextForm}>
                {i => i}
              </FormattedMessage>
            </button>
          </div>
        </div>
      </div>
    );

    const handleMultipleFleetName = helper.handleMultipleErrors(
      props.pageData.fleetNameError,
      <FormattedMessage {...messages.errorInvalidCharacters}>
        {i => i}
      </FormattedMessage>,
      <FormattedMessage {...messages.errorRequired}>{i => i}</FormattedMessage>
    );

    const handleMultipleBusinessTypeText = helper.handleMultipleErrors(
      props.pageData.businessTypeTextError,
      <FormattedMessage {...messages.errorInvalidCharacters}>
        {i => i}
      </FormattedMessage>,
      <FormattedMessage {...messages.errorRequired}>{i => i}</FormattedMessage>
    );

    const handleMultipleOperatorPhone = helper.handleMultipleErrors(
      props.pageData.operatorPhoneError,
      <FormattedMessage {...messages.errorInvalidPhone}>
        {i => i}
      </FormattedMessage>,
      <FormattedMessage {...messages.errorRequired}>{i => i}</FormattedMessage>
    );

    const formSecondStep = (
      <div>
        <div className="form-group">
          <label>
            <FormattedMessage {...messages.country}>{i => i}</FormattedMessage>
          </label>
          <div className="right">
            <Select
              className={`select-country ${
                props.pageData.countryError !== "" ? "select-error" : ""
                }`}
              clearable={false}
              value={props.pageData.country}
              onChange={this.selectOnChange.bind(this, "country")}
              options={props.pageData.countryOptions}
              placeholder={
                <FormattedMessage {...messages.selectPlaceholder}>
                  {i => i}
                </FormattedMessage>
              }
              noResultsText={
                <FormattedMessage {...messages.selectNoResultFound}>
                  {i => i}
                </FormattedMessage>
              }
            />
            {props.pageData.countryError !== "" && (
              <span className="select-error-msg">
                <FormattedMessage {...messages.errorRequired}>
                  {i => i}
                </FormattedMessage>
              </span>
            )}
          </div>
        </div>
        <div className="form-group">
          <label>
            <FormattedMessage {...messages.fleetName}>
              {i => i}
            </FormattedMessage>
          </label>
          <div className="right">
            <TextField
              className={`form__textfield ${
                !!handleMultipleFleetName ? " error" : null
                }`}
              value={props.pageData.fleetName}
              name="fleetName"
              onFocus={() => this.setState({ showFleetNamePlaceholder: false })}
              onBlur={() => {
                if (!props.pageData.fleetName) {
                  this.setState({ showFleetNamePlaceholder: true });
                }
              }}
              ref={this.fleetNameInput}
              onChange={this.changeInput.bind(this)}
              errorText={handleMultipleFleetName}
              underlineStyle={styles.underlineStyle}
              errorStyle={styles.errorStyle}
              underlineFocusStyle={styles.underlineFocusStyle}
              maxLength="100"
            />
            {state.showFleetNamePlaceholder && (
              <span
                className="Select-placeholder"
                style={{
                  right: "auto",
                  left: "10px",
                  top: "8px"
                }}
                onClick={() => {
                  this.fleetNameInput.current.focus();
                  this.setState({ showFleetNamePlaceholder: false });
                }}
              >
                <FormattedMessage {...messages.enterFleetName}>
                  {i => i}
                </FormattedMessage>
              </span>
            )}
          </div>
        </div>
        <div className="form-group">
          <label>
            <FormattedMessage {...messages.fleetSize}>
              {i => i}
            </FormattedMessage>
          </label>
          <div className="right">
            <Select
              className={`select-fleet-size ${
                props.pageData.fleetSizeError !== "" ? "select-error" : ""
                }`}
              clearable={false}
              value={props.pageData.fleetSize}
              onChange={this.selectOnChange.bind(this, "fleetSize")}
              options={props.pageData.fleetSizeOptions}
              placeholder={
                <FormattedMessage {...messages.selectPlaceholder}>
                  {i => i}
                </FormattedMessage>
              }
              noResultsText={
                <FormattedMessage {...messages.selectNoResultFound}>
                  {i => i}
                </FormattedMessage>
              }
            />
            {props.pageData.fleetSizeError !== "" && (
              <span className="select-error-msg">
                <FormattedMessage {...messages.errorRequired}>
                  {i => i}
                </FormattedMessage>
              </span>
            )}
          </div>
        </div>
        <div className="form-group">
          <label>
            <FormattedMessage {...messages.businessType}>
              {i => i}
            </FormattedMessage>
          </label>
          <div className="right">
            <Select
              className={`select-business-type ${
                props.pageData.businessTypeError !== "" ? "select-error" : ""
                }`}
              clearable={false}
              value={props.pageData.businessType}
              onChange={this.selectOnChange.bind(this, "businessType")}
              options={props.pageData.businessTypeOptions}
              placeholder={
                <FormattedMessage {...messages.selectPlaceholder}>
                  {i => i}
                </FormattedMessage>
              }
              noResultsText={
                <FormattedMessage {...messages.selectNoResultFound}>
                  {i => i}
                </FormattedMessage>
              }
            />
            {props.pageData.businessTypeError !== "" && (
              <span className="select-error-msg">
                <FormattedMessage {...messages.errorRequired}>
                  {i => i}
                </FormattedMessage>
              </span>
            )}
          </div>
        </div>
        {props.pageData.businessType === "BTOTH" && (
          <div className="form-group">
            <label></label>
            <div className="right">
              <FormattedMessage {...messages.businessType}>
                {placeholder => (
                  <TextField
                    className={`form__textfield ${
                      !!handleMultipleBusinessTypeText ? " error" : null
                      }`}
                    value={props.pageData.businessTypeText}
                    name="businessTypeText"
                    placeholder={placeholder}
                    onChange={this.changeInput.bind(this)}
                    errorText={handleMultipleBusinessTypeText}
                    underlineStyle={styles.underlineStyle}
                    errorStyle={styles.errorStyle}
                    underlineFocusStyle={styles.underlineFocusStyle}
                    maxLength="100"
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
        )}
        <div className="form-group">
          <label>
            <FormattedMessage {...messages.phone}>{i => i}</FormattedMessage>
          </label>
          <div className="right" style={{ display: "flex" }}>
            <CountryCodeSelector
              required={false}
              initialCountryFlagCode={
                country
                  ? country.length > 2
                    ? getCountryISO2(country)
                    : country
                  : null
              }
              countryFlagCode={props.pageData.internationalCode}
              countryPhoneCode={props.pageData.countryPhoneCode}
              countryPhoneCodeError={props.pageData.internationalCodeError}
              selectOnChange={this.selectOnChange.bind(
                this,
                "internationalCode"
              )}
            />
            <TextField
              className={`form__textfield ${
                !!handleMultipleOperatorPhone ? " error" : null
                }`}
              value={props.pageData.operatorPhone}
              name="operatorPhone"
              onChange={this.changeInput.bind(this)}
              underlineStyle={styles.underlineStyle}
              errorText={handleMultipleOperatorPhone}
              underlineStyle={styles.underlineStyle}
              errorStyle={styles.errorStyle}
              underlineFocusStyle={styles.underlineFocusStyle}
              maxLength="50"
            />
            <div className="tooltip">
              <img className="tooltip-icon" src={tooltipIconBlack} alt="" />
              <div className="tooltip-content">
                <span className="tooltip-title">
                  <FormattedMessage {...messages.phoneTooltipTitle}>
                    {i => i}
                  </FormattedMessage>
                </span>
                <FormattedMessage {...messages.phoneTooltipCopy}>
                  {i => i}
                </FormattedMessage>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group no-label-mobile">
          <div
            className={`label-copy ${
              this.props.pageData.agreementError !== "" ? "error" : ""
              }`}
          >
            <FormattedMessage
              {...messages.agreements}
              values={{ privacyLink, termsLink }}
            ></FormattedMessage>
          </div>
        </div>
        {props.pageData.saveFleetDetailsSuccess && (
          <div className="form-group">
            <div className="label-copy ">
              <span>
                <img src={successSendIcon} alt="" />{" "}
                <FormattedMessage {...messages.changesSaved}>
                  {msg => msg}
                </FormattedMessage>
              </span>
            </div>
          </div>
        )}
        {props.pageData.saveFleetDetailsSuccess === false && (
          <div className="form-group">
            <div className="label-copy ">
              <span>
                <img src={tooltipIconBlack} alt="" />{" "}
                <FormattedMessage {...messages.changesNotSaved}>
                  {msg => msg}
                </FormattedMessage>
              </span>
            </div>
          </div>
        )}
        <div className="form-group no-label-mobile">
          <div className="right form-info align-left">
            <button
              className="btn btn-red-link"
              disabled={props.savefleetDetailsLoading}
              onClick={this.previousStep.bind(this)}
            >
              <img src={leftArrowRed} alt="" />
              <FormattedMessage {...messages.previousForm}>
                {msg => msg}
              </FormattedMessage>
            </button>
            <FormattedMessage {...messages.saveForm}>
              {saveForm => (
                <button
                  id="signup-btn"
                  className="btn red"
                  type="submit"
                  disabled={props.savefleetDetailsLoading}
                  onClick={this.saveForm.bind(this)}
                >
                  {props.savefleetDetailsLoading ? (
                    <div className="loader"></div>
                  ) : (
                      saveForm
                    )}
                </button>
              )}
            </FormattedMessage>
          </div>
        </div>
      </div>
    );

    const lang = props.pageData.countryCodeByIp != null ? props.pageData.countryCodeByIp : props.pageLanguage;
    //console.log("render lang: ", lang);
    
    // flat screen
    const isFlatScreen = this.state.matchedQueryRule === "flatScreen";

    const headerProps = {
      copy: (
        state.step === 1 ? (
          <FormattedMessage {...messages.headerTitleFirstStepSubtitle}>
            {i => i}
          </FormattedMessage>
        ) : (
          <FormattedMessage {...messages.headerTitleSecondStepSubtitle}>
            {i => i}
          </FormattedMessage>
        )
      ),
      title: (
        props.pageData.saveFleetDetailsSuccess ? (
          <FormattedMessage {...messages.headerTitleComplite}>
            {msg => msg}
          </FormattedMessage>
        ) : // <FormattedHTMLMessage {...messages.headerTitle}></FormattedHTMLMessage>
          state.step === 1 ? (
            <FormattedMessage {...messages.headerTitleFirstStep}>
              {i => i}
            </FormattedMessage>
          ) : (
              <FormattedMessage {...messages.headerTitleSecondStep}>
                {i => i}
              </FormattedMessage>
            )
      ),
      langClass: `lang-${language}`
    };

    let alternativeHeader = false;
    if (isFlatScreen) {
      alternativeHeader = <header>
        <div className="header-wrapper">
          <h1 
            className={`header-title ${
              headerProps.copy ? "with-copy" : ""
            } ${
              headerProps.langClass ? headerProps.langClass : ""
            }`}
          >
            {headerProps.title}
          </h1>
          {headerProps.copy && <p className="header-copy">{headerProps.copy}</p>}
        </div>
      </header>;
    }

    return (
      <PageLayout
        pageName="sign-up"
        metaTitle={messages.metaTitle}
        metaDescription={messages.metaDescription}
        metaKeywords={messages.metaKeywords}
      >
        <Header
          noBackground
          title={headerProps.title}
          copy={headerProps.copy}
          langClass={headerProps.langClass}
          noContent={isFlatScreen}
          noSemanticHeader={isFlatScreen}
        />
        
        <div className="section-content">
          <div className={`left-list-box ${state.step === 2 ? "hide" : ""}`}>
            {alternativeHeader}
            <img src={signupIllustration} alt="" />
            <div className="left-list">
              <h3 className="left-list-title">
                <FormattedMessage {...messages.listItemTitle}>
                  {i => i}
                </FormattedMessage>
              </h3>
              <ul>
                <li>
                  <FormattedMessage {...messages.listItem1}>
                    {i => i}
                  </FormattedMessage>
                </li>
                <li>
                  <FormattedMessage {...messages.listItem2}>
                    {i => i}
                  </FormattedMessage>
                </li>
                <li>
                  <FormattedMessage {...messages.listItem3}>
                    {i => i}
                  </FormattedMessage>
                </li>
              </ul>
            </div>
          </div>
          <div className="right-form-box">
            <form>
              <MuiThemeProvider>
                <div>
                  <header>
                    <span>
                      <FormattedHTMLMessage
                        {...messages.stepsTitle}
                        values={{ count: state.step }}
                      ></FormattedHTMLMessage>
                    </span>
                    <div>
                      <span></span>
                      <span className="required-copy">
                        <FormattedMessage {...messages.required}>
                          {i => i}
                        </FormattedMessage>
                      </span>
                    </div>
                  </header>
                  {state.step === 1 ? formFirstStep : formSecondStep}
                </div>
              </MuiThemeProvider>
              <p className="call-us">
                {lang === "EN" || lang === "ES" ? (
                  <FormattedHTMLMessage
                    {...messages.phoneNumber}
                    values={{
                      phoneNumber: state.phoneNumbers[lang],
                      emailAddress: state.emailAddress
                    }}
                  ></FormattedHTMLMessage>
                ) : (
                    <span onClick={this.sendGaContactEvent.bind(this)}>
                      <FormattedHTMLMessage
                        {...messages.emailAddressCta}
                        values={{
                          emailAddress: state.emailAddress
                        }}
                      ></FormattedHTMLMessage>
                    </span>
                  )}
              </p>
            </form>
          </div>
        </div>
      </PageLayout>
    );
  }
}

// Listen to store value
const mapStateToProps = createStructuredSelector({
  pageData: selector.pageData(),
  pageLanguage: selector.pageLanguage(),
  savefleetDetailsLoading: selector.savefleetDetailsLoading()
});

// Change state name base on actions
const mapDispatchToProps = dispatch => {
  return {
    changeInputValue: (inputName, inputValue) =>
      dispatch(action.changeInputValue(inputName, inputValue)),
    selectOnChange: (selectName, selectValue) =>
      dispatch(action.selectOnChange(selectName, selectValue)),
    handleRadioChange: (radioName, radioValue) =>
      dispatch(action.handleRadioChange(radioName, radioValue)),
    fieldError: (fieldName, errorCopy) =>
      dispatch(action.fieldError(fieldName, errorCopy)),
    clearForm: () => dispatch(action.clearForm()),
    getCountriesList: selectedLanguage =>
      dispatch(action.getCountriesList(selectedLanguage)),
    getBusinessTypesList: selectedLanguage =>
      dispatch(action.getBusinessTypesList(selectedLanguage)),
    getGetFleetSizeList: () => dispatch(action.getGetFleetSizeList()),
    getCountryByIP: () => dispatch(action.getCountryByIP()),
    saveFleetDetails: (
      firstName,
      lastName,
      emailAddress,
      password,
      confirmPassword,
      fleetName,
      phoneNumber,
      internationalCode,
      countryPhoneCode,
      country,
      termsCondition,
      pageLanguage,
      marketingConsent,
      fleetSize,
      businessType,
      businessTypeText
    ) =>
      dispatch(
        action.saveFleetDetails(
          firstName,
          lastName,
          emailAddress,
          password,
          confirmPassword,
          fleetName,
          phoneNumber,
          internationalCode,
          countryPhoneCode,
          country,
          termsCondition,
          pageLanguage,
          marketingConsent,
          fleetSize,
          businessType,
          businessTypeText
        )
      ),
    saveFleetDetailsSuccess: value =>
      dispatch(action.saveFleetDetailsSuccess(value)),
    setNewPhoneCode: phoneCode => dispatch(action.setNewPhoneCode(phoneCode))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
