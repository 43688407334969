// React librarys
import React from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { FormattedMessage } from "react-intl";

// Language selector
import LanguageSelector from "./../../containers/LanguageSelector";

// Import Components
import { Button } from "./../Buttons";

// Import Assets
import "./styles.css";
import headerBsLogo from "./../../images/bs-logo-white.png";
import checkIcon from "./../../images/ic-resolved-red.svg";
import video from "./../../images/Fleetpulse.mp4";
import videoPoster from "./../../images/video-poster.jpg";

// Import translations
import messages from "./messages";

const HeaderWrapper = props => {
  return props.type === "div" ? (
    <div className={props.className}>{props.children}</div>
  ) : (
    <header className={props.className}>{props.children}</header>
  )
};

/* Component */
export const Header = props => {
  return (
    <HeaderWrapper 
      type={props.noContent ? "div" : "header"} 
      className={`header ${props.noBackground ? "no-bg" : ""}`}
    >
      <div>
        <LanguageSelector
          languages={[
            { value: "EN", label: "EN" },
            { value: "FR", label: "FR" },
            { value: "DE", label: "DE" },
            { value: "ES", label: "ES" },
            { value: "IT", label: "IT" },
            { value: "PL", label: "PL" }
          ]}
          className="hide-mobile language-selector"
        />
        
        <div className="header-content">
          {!props.noContent && (
            <div>
              <div>
                {props.subTitle && (
                  <h2 className="header-subtitle">{props.subTitle}</h2>
                )}
                <h1
                  className={`header-title ${props.copy ? "with-copy" : ""} ${
                    props.langClass ? props.langClass : ""
                  }`}
                >
                  {props.title}
                </h1>
                {props.copy && <p>{props.copy}</p>}
                {props.itemList && (
                  <ul className="items-list">
                    {props.itemList.map((item, index) => (
                      <li key={index}>
                        <img src={checkIcon} className="ic-resolved-red" />
                        {item}
                      </li>
                    ))}
                  </ul>
                )}
                {props.btnCopy && (
                  <Button
                    id={props.buttonId ? props.buttonId : "header-btn"}
                    onClick={props.btnClick}
                    className="red"
                  >
                    {props.btnCopy}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
        {!props.noBackground && (
          <div
            className="header-bg"
            style={{
              background: `url(${
                props.videoBg && props.isDesktop ? videoPoster : props.headerBg
              })`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center bottom"
            }}
          >
            {props.videoBg && props.isDesktop && (
              <video id="header-video" poster={videoPoster} autoPlay muted loop>
                <source src={video} type="video/mp4" />
              </video>
            )}
          </div>
        )}
        {!props.noBackground && (
          <div className="header-image">
            <img
              src={props.headerImageItem}
              alt=""
              className="header-image-item"
            />
            <img
              src={headerBsLogo}
              alt="Bridgestone logo"
              className="header-bs-logo"
            />
          </div>
        )}
      </div>
      {props.btnCopy && !props.noButtomLink && (
        <Button
          id={props.buttonId ? props.buttonId : "header-btn"}
          className="red"
          onClick={props.btn2Click}
          btnVideoLink={props.btnVideoLink}
        >
          {props.btnCopy}
        </Button>
      )}
    </HeaderWrapper>
  );
};
