/*
 * Component Reducers
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state. To add a new action, add it to the switch statement 
 * in the reducer function.
 *
 * Example:
 * function appReducer(state = initialState, action) {
 *    switch (action.type) {
 *       case LOAD_REPOS:
 *          return state
 *          .set('loading', true)
 *          .set('error', false);
 *       default:
 *          return state;
 *    }
 * }
 * export default appReducer;
 */
import { fromJS } from 'immutable';

// Import constants
import * as constant from './constants';
import { DEFAULT_LANGUAGE } from './../../helpers';


// Create component initial state
const initialState = fromJS({
	name: DEFAULT_LANGUAGE
});


// Component reducers
export default function reducer(state = initialState, action) {
	switch (action.type) {

		// REDUCER - CHANGE_PAGE_LANGUAGE
		case constant.CHANGE_PAGE_LANGUAGE:
			return state
			.set('name', action.selectedLanguage);


		// REDUCER - return initial state
		default:
			return state;
	}
};