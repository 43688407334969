// React librarys
import React from 'react';

// Import Components
import { Button } from './../Buttons';

// Import Assets
import './styles.css';
import bridgestoneLogo from './../../images/bs-logo-white.png';

// Import translations
import messages from './messages';


/* Component */
export const MobileTeaser = props => (
    <div className={`mobile-teaser ${props.reverse ? 'reverse' : ''}`}>
        <h3 className="mobile-teaser-title">
            {props.title}
        </h3>
        <div className="mobile-teaser-content">
            <img src={props.devicesImage} alt="" className="mobile-teaser-phone"/>
            <div className="box box-image">
                <div style={{
                background: `url(${props.teaserImage})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
                }}></div>
            </div>
            <div className="box box1">
                <div>
                    <p>
                        {props.box1Content}
                    </p>
                    {
                        props.renderThirdBoxAsImage &&
                        <p>
                            {props.box2Content}
                        </p>
                    }
                    {
                        props.box1BtnCopy &&
                        <Button id={props.box1buttonId ? props.box1buttonId : 'mobile-teaser-box1-video-btn'} onClick={props.box1BtnClick} btnVideoLink={props.box1BtnVideoLink}>
                            {props.box1BtnCopy}
                        </Button>
                    }
                    {
                        props.box1List &&
                        <ul>
                            {
                                props.box1List.map((item, index) =>
                                    <li key={index}>
                                        <div className="image">
                                            <img src={item.image} alt=""/>
                                        </div>
                                        <span>{item.copy}</span>
                                    </li>
                                )
                            }
                        </ul>
                    }
                </div>
            </div>
            {
                props.renderThirdBoxAsImage ?
                <div className="box box-image">
                    <div style={{
                        background: `url(${props.box2VideoImage})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        position: 'relative'
                        }}>
                            {
                                props.box2BtnCopy &&
                                <Button id={props.box2buttonId ? props.box2buttonId : 'mobile-teaser-box2-video-btn'}  onClick={props.box2BtnClick} btnVideoLink={props.box2BtnVideoLink} className={'white'}>
                                    {props.box2BtnCopy}
                                </Button>
                            }
                        </div>
                </div> :
                <div className="box box2">
                    <div>
                        <p>
                            {props.box2Content}
                        </p>
                        {
                            props.box2BtnCopy &&
                            <Button id={props.box2buttonId ? props.box2buttonId : 'mobile-teaser-box2-video-btn'}  onClick={props.box2BtnClick} btnVideoLink={props.box2BtnVideoLink} className={'red'}>
                                {props.box2BtnCopy}
                            </Button>
                        }
                        {
                            props.box2List &&
                            <ul>
                                {
                                    props.box2List.map((item, index) =>
                                        <li key={index}>
                                            <div className="image">
                                                <img src={item.image} alt=""/>
                                            </div>
                                            <span>{item.copy}</span>
                                        </li>
                                    )
                                }
                            </ul>
                        }
                    </div>
                </div>
            }
        </div>
    </div>
);
