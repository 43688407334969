/**
 * Create app store with dynamic reducers
 */
import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import createReducer from './reducers';

// Assets
import * as helper from './helpers';
import { save } from './utils/localStorage';
import rootSaga from './sagas';


const sagaMiddleware = createSagaMiddleware();

export default function initializeStore(initialState = {}, history) {
	const middlewares = [
		sagaMiddleware,
        routerMiddleware(history)
	];

	const enhancers = [
		applyMiddleware(...middlewares)
	];

	/* eslint-disable no-underscore-dangle */
	const composeEnhancers =
		process.env.NODE_ENV !== 'develop' &&
		typeof window === 'object' &&
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
			? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ 
			: compose;
    /* eslint-enable */
    
	const store = createStore(
		createReducer(),
		fromJS(initialState),
		composeEnhancers(...enhancers)
    );

    // Extensions
    sagaMiddleware.run(rootSaga);

    store.subscribe(() => {
		save(store.getState(), helper.appStoreName);
    });

	return store;
}
