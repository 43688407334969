import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';

// Import pages
import Home from './pages/Home';
import Features from './pages/Features';
import Tpms from './pages/Tpms';
import Faqs from './pages/Faqs';
import SignUp from './pages/SignUp';
import ErrorPage from './pages/ErrorPage';


// Google Analitics
import GA from './ga';


export default class AppRouting extends Component {
	constructor(props) {
		super(props);
		GA.init();
    }

    render() {
		return (
            <ScrollToTop>
                <Switch>
                    <Route exact path="/:lng(en|es|de|fr|it|pl)?" component={Home}/>

                    <Route exact path="/:lng(en|es|de|fr|it|pl)?/features" component={Features}/>
                    <Route exact path="/:lng(es)/funciones" component={Features}/>
                    <Route exact path="/:lng(de)/funktionen" component={Features}/>
                    <Route exact path="/:lng(fr)/caracteristiques" component={Features}/>
                    <Route exact path="/:lng(it)/caratteristiche" component={Features}/>
                    <Route exact path="/:lng(pl)/funkcje" component={Features}/>

                    <Route exact path="/:lng(en|es|de|fr|it|pl)?/tpms" component={Tpms}/>

                    <Route exact path="/:lng(en|es|de|fr|it|pl)?/faqs" component={Faqs}/>
                    <Route exact path="/:lng(de|fr|it|pl)/faq" component={Faqs}/>
                    <Route exact path="/:lng(es)/preguntas_frecuentes" component={Faqs}/>

                    <Route exact path="/:lng(en|es|de|fr|it|pl)?/signup" component={SignUp}/>
                    <Route exact path="/:lng(es)/registrarse" component={SignUp}/>
                    <Route exact path="/:lng(de)/registrieren" component={SignUp}/>
                    <Route exact path="/:lng(fr)/inscription" component={SignUp}/>
                    <Route exact path="/:lng(it)/iscriviti" component={SignUp}/>
                    <Route exact path="/:lng(pl)/rejestracja" component={SignUp}/>

                    <Route path="/sitemap.xml"/>
                    <Route path="/robots.txt"/>
                    <Route path="/files/*"/>

                    <Route component={ErrorPage}/>
                </Switch>
            </ScrollToTop>
		);
	}
}
