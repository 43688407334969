import styled from 'styled-components';


export const FaqPageHeaderWrapper = styled.div`
    padding-top: 90px;
    padding-bottom: 20px;

    @media screen and (min-width: 941px) {
        padding-top: 160px;
        padding-bottom: 45px;
    }
`

export const FaqPageWrapper = styled.div`
    margin: 0 auto;
    max-width: 1050px;
    width: 100%;
    padding: 0 20px;
`

export const FaqPageHeader = styled.h1`
    color: #FF0000;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    margin: 0 auto;
    padding-top: 16px;
    margin-bottom: 20px;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        height: 2px;
        top: 0;
        width: 50px;
        background: #ff0000;
    }

    @media screen and (min-width: 941px) {
        font-size: 48px;
    }
`