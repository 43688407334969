// React librarys
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Import Components
import { Button } from './../Buttons';

// Import Assets
import './styles.css';

// Import translations
import messages from './messages';

// Assets
import rigid from './../../images/available-vehicles/rigid.png';
import tractor from './../../images/available-vehicles/tractor.png';
import trailer from './../../images/available-vehicles/trailer.png';
import tractorTrailer from './../../images/available-vehicles/tractor-trailer.png';
import coachBus from './../../images/available-vehicles/coach-bus.png';
import van from './../../images/available-vehicles/van.png';
import crane from './../../images/available-vehicles/crane.png';



/* Component */
export const AvailableFor = props => (
    <section className='available-for'>
        <div className="section-content">
            {
                props.topText &&
                <h3 className="top-copy">
                    {props.topText}
                </h3>
            }

            <ul className="available-vehicles">
                <li className="vehicle v-rigid">
                    <img src={rigid} alt=""/>
                    <span><FormattedMessage {...messages.rigid}>{msg => msg}</FormattedMessage></span>
                </li>
                <li className="vehicle v-tractor">
                    <img src={tractor} alt=""/>
                    <span><FormattedMessage {...messages.tractor}>{msg => msg}</FormattedMessage></span>
                </li>
                <li className="vehicle v-trailer">
                    <img src={trailer} alt=""/>
                    <span><FormattedMessage {...messages.trailer}>{msg => msg}</FormattedMessage></span>
                </li>
                <li className="vehicle v-tractor-trailer">
                    <img src={tractorTrailer} alt=""/>
                    <span><FormattedMessage {...messages.tractorTrailer}>{msg => msg}</FormattedMessage></span>
                </li>
                <li className="vehicle v-coach-bus">
                    <img src={coachBus} alt=""/>
                    <span><FormattedMessage {...messages.coachBus}>{msg => msg}</FormattedMessage></span>
                </li>
                <li className="vehicle v-van">
                    <img src={van} alt=""/>
                    <span><FormattedMessage {...messages.van}>{msg => msg}</FormattedMessage></span>
                </li>
                <li className="vehicle v-crane">
                    <div className="icon">
                        <span className="new-vehicle"><FormattedMessage {...messages.newVehicle}>{msg => msg}</FormattedMessage></span>
                        <img src={crane} alt=""/>
                    </div>
                    <span><FormattedMessage {...messages.crane}>{msg => msg}</FormattedMessage></span>
                </li>
            </ul>

            <Button id={props.buttonId ? props.buttonId : 'call-to-action-btn'} onClick={props.buttonOnClick} className={'call-to-action-btn red'} >
                {props.buttonText}
            </Button>
        </div>
    </section>
);
