import { defineMessages } from 'react-intl';

export default defineMessages({
	privacyLink: {
		id: 'pdf.privacyLink',
		defaultMessage: 'EN_-_Privacy_Policy'
	},
	termsLink: {
		id: 'pdf.termsLink',
		defaultMessage: 'EN_-_Terms_and_Conditions'
	},
	cookiesLink: {
		id: 'pdf.cookiesLink',
		defaultMessage: 'EN_-_Cookies_Policy'
	},

	cookieTitle1: {
		id: 'cookies.Title1',
		defaultMessage: 'THIS WEBSITE USES COOKIES'
	},
	cookieTitle2: {
		id: 'cookies.Title2',
		defaultMessage: 'COOKIES ON FLEETPULSE'
	},
	changePreferences: {
		id: 'cookies.ChangePreferencesBtnCopy',
		defaultMessage: 'Change your Preferences'
	},
	savePreferences: {
		id: 'cookies.SavePreferencesBtnCopy',
		defaultMessage: 'Save'
	},
	readMore: {
		id: 'cookies.readMore',
		defaultMessage: 'Read more'
	},
	readLess: {
		id: 'cookies.readLess',
		defaultMessage: 'Read less'
	},
});