/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/languages`)
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { IntlProvider } from 'react-intl';

// Import selectors
import * as selector from './selectors';
import * as action from './actions'

import { getLanguageBasedOnUrl } from './../../helpers'

/** Container */
export class LanguageProvider extends Component {

	componentDidUpdate(){
		const { ...props } = this.props;
		const language = getLanguageBasedOnUrl(props.locationData.location.pathname);
		if (props.pageLanguage !== language) {
			props.changePageLanguage(language);
		}
	}

	render() {
		const { ...props } = this.props;
		const language = props.pageLanguage;
	
		return (
			<IntlProvider locale={language} key={language} messages={props.messages[language]}>
				{React.Children.only(props.children)}
			</IntlProvider>
		);
	}
}


// React propTypes
LanguageProvider.propTypes = {
	pageLanguage: PropTypes.string,
	messages: PropTypes.object,
	children: PropTypes.element.isRequired
};


// Listen to store value
const mapStateToProps = createStructuredSelector({
	pageLanguage: selector.pageLanguage(),
	locationData: selector.locationData(),
});



// Change state name base on actions
const mapDispatchToProps = dispatch => {
	return {
		changePageLanguage: selectedLanguage => dispatch(action.changePageLanguage(selectedLanguage)),
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(LanguageProvider);