// React component import
import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

// Import Actions
import * as action from './actions';
import { apiLink } from './../../helpers';


// Worker saga - do requests
export function* getPageData() {
	// const requestLink = `${apiLink}/users`;

	// try {
	// 	const response = yield call(axios, { 
	// 		method: 'get', 
	// 		url: requestLink, 
	// 		// responseType: 'json', 
	// 		// headers: helper.apiCallHeader(), 
	// 		// data: {
	// 		// 	CountPages: action.pageCount,
	// 		// 	PageSize: action.rowsPerPage
	// 		// }
	// 	});

	// 	const data = yield response.data;
	// 	yield put(action.getDataRequestedDone(data));
	// 	console.log('SAGA - getPageData:', data);
	// } catch (e) {
	// 	console.log(`Error: ${e.response.status} - ${e.response.statusText}`);
	// }
}


// Watcher saga - listen for request updates 
// on specyfic actions and run our worker sagas
export default function* saga() {
	// yield takeLatest(action.getDataRequested().type, getPageData)
}
