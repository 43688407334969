import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import 'react-select/dist/react-select.css';

// Flags select
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';


// Selectors
import * as selector from './selectors';

// Styles
import { CustomeSelect } from './styles';

// Helpers
import GA from './../../ga';
import history from './../../history'

// Messages & Helpers
import { translationMessages } from './../../i18n';


/** Container */
export class LanguageSelector extends Component {
    selectLanguage(target) {
        if (typeof target === 'object') {
            target = target;
        } else {
            target = {
                value: target === 'GB' ? 'EN' : target,
                label: target === 'GB' ? 'EN' : target
            }
        }

        const
            pageID      = window.localStorage.getItem('page_id'),
            newPageURL  = translationMessages[target.value][pageID] || '';

        GA.event('Selection of language picker', `language: ${target.value}`);
        window.dataLayer.push({'event': 'languagechange', 'lang': target.value});

        history.push(`/${target.value.toLowerCase()}/${newPageURL}`);
    }

	render() {
        const { ...props } = this.props;

        if(props.flagsSelector) {
            return (
                <ReactFlagsSelect
                    defaultCountry={props.pageLanguage === 'EN' ? 'GB' : props.pageLanguage}
                    onSelect={this.selectLanguage.bind(this)}
                    searchable={false}
                    countries={props.languages}
                    customLabels={props.customLabels}
                    className={props.className}
                    selectedSize={16}
                />
            );
        } else {
            return (
                <CustomeSelect
                    name="select-languages"
                    value={props.pageLanguage}
                    clearable={false}
                    searchable={false}
                    onChange={this.selectLanguage.bind(this)}
                    options={props.languages}
                    className={props.className}
                />
            );
        }
	}
}


// Listen to store value
const mapStateToProps = createStructuredSelector({
	pageLanguage: selector.pageLanguage()
});

export default connect(mapStateToProps, null)(LanguageSelector);
