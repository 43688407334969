/*
 * Component Constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We can prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 * or
 * export const LOAD_REPOS = 'LOAD_REPOS';
 */
export const SAVE_REGISTER_DATA = "SAVE_REGISTER_DATA";
export const SAVE_REGISTER_DATA_DONE = "SAVE_REGISTER_DATA_DONE";
export const CHANGE_INPUT_VALUE = "CHANGE_INPUT_VALUE";
export const CHANGE_SELECT_VALUE = "CHANGE_SELECT_VALUE";
export const CHANGE_RADIO_INPUT = "CHANGE_RADIO_INPUT";
export const FIELD_ERROR = "FIELD_ERROR";
export const CLEAR_FORM = "CLEAR_FORM";
export const GET_COUNTRIES_LIST = "GET_COUNTRIES_LIST";
export const GET_COUNTRIES_LIST_DONE = "GET_COUNTRIES_LIST_DONE";
export const GET_BUSINESS_TYPES_LIST = "GET_BUSINESS_TYPES_LIST";
export const GET_BUSINESS_TYPES_LIST_DONE = "GET_BUSINESS_TYPES_LIST_DONE";
export const SAVE_FLEET_DETAILS = "SAVE_FLEET_DETAILS";
export const SAVE_FLEET_DETAILS_SUCCESS = "SAVE_FLEET_DETAILS_SUCCESS";
export const SET_NEW_COUNTRY_PHONE_CODE = "SET_NEW_COUNTRY_PHONE_CODE";
export const GET_FLEET_SIZE_LIST = "GET_FLEET_SIZE_LIST";
export const GET_FLEET_SIZE_LIST_DONE = "GET_FLEET_SIZE_LIST_DONE";
export const SET_SAVE_FLEET_DETAILS_LOADING = "SET_SAVE_FLEET_DETAILS_LOADING";
export const GET_COUNTRY_BY_IP = "GET_COUNTRY_BY_IP";
export const GET_COUNTRY_BY_IP_DONE = "GET_COUNTRY_BY_IP_DONE";
