import { defineMessages } from 'react-intl';

export default defineMessages({
	sectionTitle: {
		id: 'page.Home.Section.01.Title',
		defaultMessage: 'FleetPulse helps you stay one step ahead of your fleet’s maintenance activities – preventing repair costs, increasing time on the road and streamlining the vehicle inspection process for everyone.'
	},
	sectionBtnCtaCopy: {
		id: 'page.Home.Section.01.BtnCtaCopy',
		defaultMessage: 'Explore FleetPulse features'
	},

	howItWorks1ImageAlt: {
		id: 'page.Home.HowItWorks1.ImageAlt',
		defaultMessage: 'Drivers inspect the vehicle and tyre pressure using TPMS'
	},
	howItWorks1Excerpt: {
		id: 'page.Home.HowItWorks1.Excerpt',
		defaultMessage: 'Drivers inspect the vehicle and tyre pressure using TPMS'
	},
	howItWorks1Copy: {
		id: 'page.Home.HowItWorks1.Copy',
		defaultMessage: 'Different checklists are available for different vehicle types. Our TPMS sensors give you an automatic pressure reading during inspections. Any defects can be reported with comments and photos.'
	},

	howItWorks2ImageAlt: {
		id: 'page.Home.HowItWorks2.ImageAlt',
		defaultMessage: 'Managers receive defect notifications and pressure alerts'
	},
	howItWorks2Excerpt: {
		id: 'page.Home.HowItWorks2.Excerpt',
		defaultMessage: 'Managers receive defect notifications and pressure alerts'
	},
	howItWorks2Copy: {
		id: 'page.Home.HowItWorks2.Copy',
		defaultMessage: 'You’ll get notified through the web app and by email for any vehicle defects or pressure alerts.'
	},

	howItWorks3ImageAlt: {
		id: 'page.Home.HowItWorks3.ImageAlt',
		defaultMessage: 'Managers have a centralised view of the fleet’s health'
	},
	howItWorks3Excerpt: {
		id: 'page.Home.HowItWorks3.Excerpt',
		defaultMessage: 'Managers have a centralised view of the fleet’s health'
	},
	howItWorks3Copy: {
		id: 'page.Home.HowItWorks3.Copy',
		defaultMessage: 'You can monitor daily KPIs based on vehicle checklists and defects. You’ll also receive comments and photos from your drivers to help you judge the severity of these defects. You can quickly view the details of every vehicles, including service and maintenance history.'
	},

	howItWorks4ImageAlt: {
		id: 'page.Home.HowItWorks4.ImageAlt',
		defaultMessage: 'Managers can manage their vehicles and maintenance activities'
	},
	howItWorks4Excerpt: {
		id: 'page.Home.HowItWorks4.Excerpt',
		defaultMessage: 'Managers can manage their vehicles and maintenance activities'
	},
	howItWorks4Copy: {
		id: 'page.Home.HowItWorks4.Copy',
		defaultMessage: 'You can arrange bookings in the workshop or with service providers to resolve vehicle issues. You can classify vehicles as off the road and update vehicle status once an issue is resolved. You also have an overview of all maintenance plans and can edit them.'
	},

	howItWorks5ImageAlt: {
		id: 'page.Home.HowItWorks5.ImageAlt',
		defaultMessage: 'Drivers are notified about vehicle servicing'
	},
	howItWorks5Excerpt: {
		id: 'page.Home.HowItWorks5.Excerpt',
		defaultMessage: 'Drivers are notified about vehicle servicing'
	},
	howItWorks5Copy: {
		id: 'page.Home.HowItWorks5.Copy',
		defaultMessage: 'Drivers receive notifications about any services planned for vehicles they drive, along with details about the service and the vehicle’s issue.'
	},

	howItWorks6ImageAlt: {
		id: 'page.Home.HowItWorks6.ImageAlt',
		defaultMessage: 'FleetPulse is a simple tool with huge benefits'
	},
	howItWorks6Excerpt: {
		id: 'page.Home.HowItWorks6.Excerpt',
		defaultMessage: 'FleetPulse is a simple tool with huge benefits'
	},
	howItWorks6Copy: {
		id: 'page.Home.HowItWorks6.Copy',
		defaultMessage: 'FleetPulse simplifies vehicle management and helps keep your fleet on the road. It reduces maintenance costs and helps you stay compliant with safety regulations.'
	}
});