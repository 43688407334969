import { defineMessages } from 'react-intl';

export default defineMessages({
	rigid: {
		id: 'vehiclesTypes.Rigid',
		defaultMessage: 'Rigid'
	},
	tractor: {
		id: 'vehiclesTypes.Tractor',
		defaultMessage: 'Tractor'
	},
	trailer: {
		id: 'vehiclesTypes.Trailer',
		defaultMessage: 'Trailer'
	},
	tractorTrailer: {
		id: 'vehiclesTypes.TractorTrailer',
		defaultMessage: 'Tractor & trailer'
	},
	coachBus: {
		id: 'vehiclesTypes.CoachBus',
		defaultMessage: 'Coach & bus'
	},
	van: {
		id: 'vehiclesTypes.Van',
		defaultMessage: 'Van'
	},
	crane: {
		id: 'vehiclesTypes.Crane',
		defaultMessage: 'Crane'
	},
	newVehicle: {
		id: 'vehiclesTypes.NewVehicle',
		defaultMessage: 'New'
	},
});