import styled from 'styled-components';

// Styles
export const FaqWrapper =  styled.div`
    margin-bottom: 30px !important;
`

export const FaqQuestion = styled.div`
    background-color: #E6E6E6;
    cursor: pointer;
    font-size: 16px;
    padding: 15px 30px 15px 10px;
    position: relative;
    line-height: 22px;

    @media screen and (min-width: 941px) {
        font-size: 24px;
        padding-left: 30px;
        padding-right: 50px;
        line-height: 1;
    }
`
export const FaqAnswer = styled.div`
    background-color: #E6E6E6;
    font-size: 14px;
    padding: 0 30px 15px 10px;
    line-height: 20px;

    @media screen and (min-width: 941px) {
        font-size: 20px;
        padding-left: 30px;
    }

    p {
        margin: 0;
        padding: 10px 0;
    }
`
