// React imports
import React, { Component } from 'react';
import ScrollToTop from 'react-scroll-up';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { createSelector } from 'reselect';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// Import Components
import { Footer } from './../components/Footer';
import { CookiesBox } from './../components/CookiesBox';

// Import Containers
import PageMeta from './../containers/PageMeta';
import Navbar from './../containers/Navbar';

// Import Helpers
import history from './../history';
import { addAuthorization } from './../utils/localStorage';
import { getBrowserLanguage } from './../helpers';
import GA from './../ga';

// Translations
import messages from './messages';

// Assets
import './languages-styles.css';
import scrollToTopIcon from './../images/back-to-top.svg';


const cookies = new Cookies();


/* Container */
class PageLayout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cookiesBox: false,
			cookiesPreferencesOn: false,
			cookieCopyExpanded: false,
			coookie1: {
				name: 'bs-ess',
				disabled: true,
				checked: true,
			},
			coookie2: {
				name: 'bs-func',
				disabled: false,
				checked: false,
			},
			coookie3: {
				name: 'bs-ads',
				disabled: false,
				checked: false,
			},
			coookie4: {
				name: 'bs-some',
				disabled: false,
				checked: false,
			}
		}

		this.acceptCookieBtnClick = this.acceptCookieBtnClick.bind(this);
	}

	showCookiesBox(val) {
		this.setState({
			cookiesBox: val
		});
	}

	componentDidMount() {
		addAuthorization();

		if (cookies.get('flpms')) {
			const
				cookiesVal 	= cookies.get('flpms').replace(/\=/g, ':').replace(/\&/g, ';').split(';'),
				val 				= cookiesVal.map(item => item.split(':')[1]);

				this.setState({
					coookie1: {
						name: 'bs-ess',
						disabled: true,
						checked: val[0] === 'true',
					},
					coookie2: {
						name: 'bs-func',
						disabled: false,
						checked: val[1] === 'true',
					},
					coookie3: {
						name: 'bs-ads',
						disabled: false,
						checked: val[2] === 'true',
					},
					coookie4: {
						name: 'bs-some',
						disabled: false,
						checked: val[3] === 'true',
					}
				});

				this.showCookiesBox(false);
		} else {
			this.showCookiesBox(true);
		}
	}

	// If someone come to page without language selector
	// redirect to page coresponding to user language
	componentWillMount() {
		if (window.location.pathname === '/') {
			history.push(`/${getBrowserLanguage().toLocaleLowerCase()}/`);
		} else {
			if (window.location.href.indexOf(`/${this.props.pageLanguage.toLowerCase()}/`) < 0) {
				const pageName = window.location.pathname.split('/')[1];
				history.push(`/${getBrowserLanguage().toLocaleLowerCase()}/${pageName}/`);
			}
		};
	}

	acceptCookieBtnClick() {
		const tooday = new Date();
		const year = tooday.getFullYear();
		const month = tooday.getMonth();
		const day = tooday.getDate();
		const cookiesValues = `${[this.state.coookie1.name]}=true&${[this.state.coookie2.name]}=true&${[this.state.coookie3.name]}=true&${[this.state.coookie4.name]}=true`;
		document.cookie = `flpms=${cookiesValues}; expires=${new Date(year + 1, month, day, 0, 0, 1)}; path=/;`;
		this.showCookiesBox(false);
		GA.event('Cookies', 'Accept all cookies');
		setTimeout(() => {
			window.location.reload()
		}, 500);
	}

	changeCookiesPreferences(e) {
		e.preventDefault();
		this.setState({
			cookiesPreferencesOn: !this.state.cookiesPreferencesOn
		});
		if (!this.state.cookiesPreferencesOn) {
			GA.event('Cookies', 'Change preferences');
		}
	}

	saveCookiesPreferences(e) {
		e.preventDefault();
		const tooday = new Date();
		const year = tooday.getFullYear();
		const month = tooday.getMonth();
		const day = tooday.getDate();
		const cookiesValues = `${[this.state.coookie1.name]}=${[this.state.coookie1.checked]}&${[this.state.coookie2.name]}=${[this.state.coookie2.checked]}&${[this.state.coookie3.name]}=${[this.state.coookie3.checked]}&${[this.state.coookie4.name]}=${[this.state.coookie4.checked]}`;
		document.cookie = `flpms=${cookiesValues}; expires=${new Date(year + 1, month, day, 0, 0, 1)}; path=/;`;
		this.showCookiesBox(false);
		GA.event('Cookies', 'Save modified preferences');
		setTimeout(() => {
			window.location.reload()
		}, 500);
	}

	changeCookieAgreement(cookie) {
		this.setState({
			[cookie]: {
				name: this.state[cookie].name,
				disabled: this.state[cookie].disabled,
				checked: !this.state[cookie].checked
			}
		});

		if(this.state[cookie].name === 'bs-ess') {
			GA.event('Cookies', 'Essential, analytics cookies');
		}
		if(this.state[cookie].name === 'bs-func') {
			GA.event('Cookies', 'Functionality cookies');
		}
		if(this.state[cookie].name === 'bs-some') {
			GA.event('Cookies', 'Advertising cookies');
		}
		if(this.state[cookie].name === 'bs-ads') {
			GA.event('Cookies', 'Social media cookies');
		}
	}

	expandCookieContent() {
		this.setState({
			cookieCopyExpanded: !this.state.cookieCopyExpanded
		});
	}

	render() {
        const { ...props } = this.props;
        const { ...state } = this.state;

		const language = props.pageLanguage.toLowerCase();

		return (
			<div className={`${props.pageName} lang-${language}`} ref={(section) => { this.Top = section; }}>
				<PageMeta
					metaTitle={props.metaTitle}
					metaDescription={props.metaDescription}
					metaKeywords={props.metaKeywords}
				/>
                <Navbar language={props.pageLanguage} />
				{props.children}
				<div id={'scroll-to-top'} className="scroll-to-top">
					<ScrollToTop showUnder={160} style={{
						position: 'absolute',
						top: 0,
						left: 0,
						transitionDuration: '0.2s',
						transitionTimingFunction: 'linear',
						transitionDelay: '0s',
						width: '100%',
						height: '100%',
						background: `url(${scrollToTopIcon})`
					}}></ScrollToTop>
				</div>
				<Footer language={language} openCookieModal={this.showCookiesBox.bind(this, true)} />
				{
					state.cookiesBox &&
					<CookiesBox
						expandCookieContent={this.expandCookieContent.bind(this)}
						expanded={state.cookieCopyExpanded}
						copy={<FormattedHTMLMessage {...messages.cookiesCopy}></FormattedHTMLMessage>}
						copy2={<FormattedHTMLMessage {...messages.cookiesCopy2}></FormattedHTMLMessage>}
						privacyLinkCopy={<FormattedMessage {...messages.cookiesPrivacyLinkCopy}>{msg => msg}</FormattedMessage>}
						cookieLinkCopy={<FormattedMessage {...messages.cookiesCookieLinkCopy}>{msg => msg}</FormattedMessage>}
						acceptCookieBtnCopy={<FormattedMessage {...messages.acceptCookieBtnCopy}>{msg => msg}</FormattedMessage>}
						acceptCookieBtnClick={this.acceptCookieBtnClick}
						cookiesPreferencesOn={state.cookiesPreferencesOn}
						changeCookiesPreferences={this.changeCookiesPreferences.bind(this)}
						saveCookiesPreferences={this.saveCookiesPreferences.bind(this)}
						changeAgreement={this.changeCookieAgreement.bind(this)}
						agreements={[
							{
								name: state.coookie1.name,
								title: <FormattedMessage {...messages.agreement1Title}>{msg => msg}</FormattedMessage>,
								copy: <FormattedMessage {...messages.agreement1Copy}>{msg => msg}</FormattedMessage>,
								disabled: state.coookie1.disabled,
								defaultChecked: state.coookie1.checked
							},
							{
								name: state.coookie2.name,
								title: <FormattedMessage {...messages.agreement2Title}>{msg => msg}</FormattedMessage>,
								copy: <FormattedMessage {...messages.agreement2Copy}>{msg => msg}</FormattedMessage>,
								disabled: state.coookie2.disabled,
								defaultChecked: state.coookie2.checked
							},
							{
								name: state.coookie3.name,
								title: <FormattedMessage {...messages.agreement3Title}>{msg => msg}</FormattedMessage>,
								copy: <FormattedMessage {...messages.agreement3Copy}>{msg => msg}</FormattedMessage>,
								disabled: state.coookie3.disabled,
								defaultChecked: state.coookie3.checked
							},
							{
								name: state.coookie4.name,
								title: <FormattedMessage {...messages.agreement4Title}>{msg => msg}</FormattedMessage>,
								copy: <FormattedMessage {...messages.agreement4Copy}>{msg => msg}</FormattedMessage>,
								disabled: state.coookie4.disabled,
								defaultChecked: state.coookie4.checked
							}
						]}
					/>
				}
			</div>
		);
	}
}

// SELECTOR - Select global state
const selectPageLanguage = state => state.get('language');

// SELECTOR - pageLanguage
export const pageLanguage = () => createSelector(
    selectPageLanguage,
    languageState => languageState.get('name')
);

// Listen to store value
const mapStateToProps = createStructuredSelector({
	pageLanguage: pageLanguage()
});

export default connect(mapStateToProps, null)(PageLayout);