// React imports
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { createSelector } from 'reselect';

// Import Helpers
import history from './../../history';
import { getBrowserLanguage } from './../../helpers';


/* Container */
class ErrorPage extends Component {

	// Redirect to default browser language home page
	componentWillMount() {
		history.push(`/${getBrowserLanguage().toLocaleLowerCase()}/`);
	}

	render() {
		return (
			<h1>Error 404</h1>
		);
	}
}

// SELECTOR - Select global state
const selectPageLanguage = state => state.get('language');

// SELECTOR - pageLanguage
export const pageLanguage = () => createSelector(
    selectPageLanguage,
    languageState => languageState.get('name')
);

// Listen to store value
const mapStateToProps = createStructuredSelector({
	pageLanguage: pageLanguage()
});

export default connect(mapStateToProps, null)(ErrorPage);
