// React librarys
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Import Assets
import './styles.css';
import messages from './messages';


/* Component */
export const CookiesBox = props => (
    <div className={`cookie-box ${props.cookiesPreferencesOn ? 'step2' : ''}`}>
        {
            !props.cookiesPreferencesOn ?
            <div className="inner">
                <div>
                    <span className="cookie-title">
                        <FormattedMessage {...messages.cookieTitle1}>{msg => msg}</FormattedMessage>
                    </span>
                    <div className="content">
                        {
                            props.expanded ?
                            <p>
                                {props.copy2}
                                &nbsp;<span className="more" onClick={props.expandCookieContent}>
                                    <FormattedMessage {...messages.readLess}>{msg => msg}</FormattedMessage>
                                </span>
                            </p>
                            :
                            <p>
                                {props.copy}
                                &nbsp;<span className="more" onClick={props.expandCookieContent}>
                                    <FormattedMessage {...messages.readMore}>{msg => msg}</FormattedMessage>
                                </span>
                            </p>
                        }
                    </div>
                    <div className="btn-group">
                        <button id={'cookiebox-btn'} onClick={props.acceptCookieBtnClick}>
                            {props.acceptCookieBtnCopy}
                        </button>
                        <a id='change-preferences' href='/' onClick={props.changeCookiesPreferences}>
                            <FormattedMessage {...messages.changePreferences}>{msg => msg}</FormattedMessage>
                        </a>
                    </div>
                    <div className="links">
                        <ul>
                            <li>
                                <FormattedMessage {...messages.privacyLink}>
                                    {msg =>
                                    <a id={'cookiebox-privacy-link'} href={`/files/${msg}.pdf`} target="_blank">{props.privacyLinkCopy}</a>
                                    }
                                </FormattedMessage>
                            </li>
                            <li>
                                <FormattedMessage {...messages.cookiesLink}>
                                    {msg =>
                                    <a id={'cookiebox-cookies-link'} href={`https://edp-e-ne-p-bridgestone.azureedge.net/-/media/Files/Bridgestone/CHP/Downloads/Cookies-policy/${msg}_web.ashx`} target="_blank">{props.cookieLinkCopy}</a>
                                    }
                                </FormattedMessage>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            :
            <div className="inner">
                <div className="close-preferences" onClick={props.changeCookiesPreferences}></div>
                <span className="cookie-title">
                    <FormattedMessage {...messages.cookieTitle2}>{msg => msg}</FormattedMessage>
                </span>
                <div className="content">
                    {
                        props.agreements.map((agr, index) =>
                        <div className="agreement-group" key={agr.name}>
                            <input
                                type="checkbox"
                                name={agr.name}
                                id={agr.name}
                                onChange={props.changeAgreement.bind(this, `coookie${index + 1}`)}
                                disabled={agr.disabled}
                                defaultChecked={agr.defaultChecked}
                            />
                            <label htmlFor={agr.name}>
                                <span className="title">
                                    {agr.title}
                                </span>
                                <p>
                                    {agr.copy}
                                </p>
                            </label>
                        </div>
                        )
                    }
                </div>
                <div className="btn-group">
                    <button id='save-cookie-preferences' onClick={props.saveCookiesPreferences}>
                        <FormattedMessage {...messages.savePreferences}>{msg => msg}</FormattedMessage>
                    </button>
                </div>
                <div className="links">
                    <ul>
                        <li>
                            <FormattedMessage {...messages.privacyLink}>
                                {msg =>
                                <a id={'cookiebox-privacy-link'} href={`${msg}.pdf`} target="_blank">{props.privacyLinkCopy}</a>
                                }
                            </FormattedMessage>
                        </li>
                        <li>
                            <FormattedMessage {...messages.cookiesLink}>
                                {msg =>
                                <a id={'cookiebox-cookies-link'} href={`https://edp-e-ne-p-bridgestone.azureedge.net/-/media/Files/Bridgestone/CHP/Downloads/Cookies-policy/${msg}_web.ashx`} target="_blank">{props.cookieLinkCopy}</a>
                                }
                            </FormattedMessage>
                        </li>
                    </ul>
                </div>
            </div>
        }

        <div className="bg"></div>
    </div>
);