/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { LOCATION_CHANGE } from 'react-router-redux';

// Import reducers
import language from './containers/LanguageProvider/reducers';
import home from './pages/Home/reducers';
import faqs from './pages/Faqs/reducers';
import features from './pages/Features/reducers';
import tpms from './pages/Tpms/reducers';
import signUp from './pages/SignUp/reducers';


// Initial routing state
const routeInitialState = fromJS({
	location: '/'
});


// Merge route into the global application state
function route(state = routeInitialState, action) {
	switch (action.type) {
		case LOCATION_CHANGE:
			return state.merge({
				location: action.payload
			});
		default:
			return state;
	}
}



/**
 * Creates the main reducer with the dynamically injected ones
 * @param {Object} - key/value of reducer functions
 */
export default function createReducer() {
	return combineReducers({
        route,
        language,
        home,
        faqs,
        features,
        tpms,
        signUp
	});
};