/*
 * This is tricky one ; )
 * Selectors are functions to select state from
 * our redux store. It's help us clean code and
 * end write less on our components. 
 * 
 * For example;
 * 
 * const selectGlobal = state => state.get('global');
 * const makeSelectLoading = () => createSelector(
 *    selectGlobal,
 *    globalState => globalState.get('loading')
 * );
 * export {
 *    selectGlobal,
 *    makeSelectLoading
 * };
 * Gets from store object coled global
 * then search there loading object.
 */
import { createSelector } from 'reselect';


// SELECTOR - Select global state
const pageStore = state => state.get('features');
const selectPageLanguage = state => state.get('language');

// SELECTOR - pageLanguage
export const pageLanguage = () => createSelector(
    selectPageLanguage,
    languageState => languageState.get('name')
);


// SELECTOR - apiData
// export const apiData = () => createSelector(
//     selectStateFromStore,
//     apiData => apiData.get('apiData')
// );


// SELECTOR - apiDataReady
// export const apiDataReady = () => createSelector(
//     selectStateFromStore,
//     apiDataReady => apiDataReady.get('apiDataReady')
// );

