// React librarys
import React from 'react';

// Import Component
import { Button } from './../Buttons';

// Import Assets
import './styles.css';

// Import translations
import messages from './messages';


/* Component */
export const BoxWithImage = props => (
    <article className="image-box">

        <div className={`image-box-inner ${props.reverse ? 'reverse': ''}`}
        style={{
            color: props.textColor
        }}>
            <div className="image-box-image"
            style={{
                backgroundImage: `url(${props.image})`,
                backgroundSize: 'cover',
                backgroudRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }}>
            {
                props.displayButtonOnImage && props.btnCopy && props.buttonOnClick &&
                <Button id={props.buttonId} onClick={props.buttonOnClick} btnVideoLink={props.btnVideoLink} className={props.btnVideoClassName ? props.btnVideoClassName : ''}>
                    {props.btnCopy}
                </Button>
            }</div>
            <div className='image-box-content'
            style={{
                background: props.contentBoxColor
            }}
            >
                {
                    props.subTitle &&
                    <h3 className="image-box-subtitle">
                        {props.subTitle}
                    </h3>
                }
                <h6 className="image-box-title">
                    {props.title}
                </h6>
                <p className="image-box-copy">
                    {props.copy}
                </p>
                {
                    props.list &&
                    <div className="items-list">
                        <ul>
                        {
                            props.list.map((item, index) => 
                            <li key={index}>{item}</li>
                            )
                        }
                        </ul>
                    </div>
                }
                {
                    props.otherCopy &&
                    <p className="image-box-copy">
                        {props.otherCopy}
                    </p>
                }
                {
                    !props.displayButtonOnImage && props.btnCopy && props.buttonOnClick &&
                    <Button id={props.buttonId} onClick={props.buttonOnClick} btnVideoLink={props.btnVideoLink} className={`red ${props.btnVideoClassName ? props.btnVideoClassName : ''}`}>
                        {props.btnCopy}
                    </Button>
                }
                {
                    props.btnCopy && props.buttonHref &&
                    <a className="btn red" href={props.buttonHref} target="_blank">
                        {props.btnCopy}
                    </a>
                }
                
                {
                    props.actualSlide &&
                    <span className={`slide-count ${props.counterPosition ? props.counterPosition : ''}`}>{props.actualSlide}/{props.maxSlides}</span>
                }
            </div>
        </div>

    </article>
);
