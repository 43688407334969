import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import 'react-select/dist/react-select.css';

// Component
import { NavMenu } from './../../components/NavMenu';


/** Container */
export class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpened: false,
            scrolling: false
        };

        this.openMenu = this.openMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        let offsetTop = window.pageYOffset;
        this.setState({
            scrolling: offsetTop > 0 ? true : false
        });
    }

    openMenu() {
        // console.log('nav click');
        this.setState({
            menuOpened: !this.state.menuOpened
        });
    }

    closeMenu() {
        this.setState({
            menuOpened: false
        });
    }

	render() {
		const { ...state } = this.state;

		return (
            <NavMenu
                menuOpened={state.menuOpened}
                closeMenu={this.closeMenu}
                openMenu={this.openMenu}
                language={this.props.language}
                scrolling={this.state.scrolling}
            />
		);
	}
}

export default Navbar;
