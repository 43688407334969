import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Helmet } from "react-helmet";


const PageMeta = ({ intl, metaTitle, metaDescription, metaKeywords }) => {
    const 
        title       = intl.formatMessage({...metaTitle}),
        description = intl.formatMessage({...metaDescription}),
        keywords    = intl.formatMessage({...metaKeywords});

    return (
        <Helmet>
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta name="keywords" content={keywords} />
        </Helmet>
    );
}

PageMeta.propTypes = {
    intl: intlShape.isRequired
}

export default injectIntl(PageMeta);