import { defineMessages } from 'react-intl';

export default defineMessages({
	col1Title: {
		id: 'page.Footer.Col1.Title',
		defaultMessage: 'Get started'
	},
	col1Link1: {
		id: 'page.Footer.Col1.Link1',
		defaultMessage: 'Start your free trial'
	},
	col1Link2: {
		id: 'page.Footer.Col1.Link2',
		defaultMessage: 'FAQs'
	},

	col2Title: {
		id: 'page.Footer.Col2.Title',
		defaultMessage: 'Product support'
	},
	col2Link1: {
		id: 'page.Footer.Col2.Link1',
		defaultMessage: 'Ask us a question'
	},

	col3Title: {
		id: 'page.Footer.Col3.Title',
		defaultMessage: 'Company'
	},
	col3Link1: {
		id: 'page.Footer.Col3.Link1',
		defaultMessage: 'Privacy policy'
	},
	col3Link2: {
		id: 'page.Footer.Col3.Link2',
		defaultMessage: 'Terms & Conditions'
	},
	col3Link3: {
		id: 'page.Footer.Col3.Link3',
		defaultMessage: 'Cookies'
	},

	copyright: {
		id: 'page.Footer.Copyright',
		defaultMessage: 'Copyright © 2018 Bridgestone'
	},
	manualLinkCopy: {
		id: 'page.Footer.ManualLinkCopy',
		defaultMessage: 'Fleetpulse Manual'
	},

	privacyLink: {
		id: 'pdf.privacyLink',
		defaultMessage: 'EN_Privacy_Policy'
	},
	termsLink: {
		id: 'pdf.termsLink',
		defaultMessage: 'EN_-_Terms_and_Conditions'
	},
	cookiesLink: {
		id: 'pdf.cookiesLink',
		defaultMessage: 'EN_Cookies_Policy'
	},
	cookiePreferences: {
		id: 'page.Footer.cookiePreferences',
		defaultMessage: 'Cookie Preferences'
	}
});