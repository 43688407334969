// React imports
import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import scrollToComponent from 'react-scroll-to-component';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Import selectors
import * as selector from './selectors';

// Import Components
import { AvailableFor } from './../../components/AvailableFor';
import { VideoModal } from './../../components/VideoModal';

// Import Containers
import PageLayout from './../PageLayout';
import { FaqSectionContainer } from './../../containers/FaqSection'
import { FaqContainer } from './../../containers/Faq'
import LanguageSelector from './../../containers/LanguageSelector'

// Import Translations
import messages from './messages';
import pathnames from './../../routing-messages';

// Helpers
import * as helper from './../../helpers';
import history from './../../history';
import GA from './../../ga';

// Styles
import { FaqPageHeaderWrapper, FaqPageWrapper, FaqPageHeader } from './styles';
import './styles.css';

/* Container */
class Faqs extends Component {
	constructor(props){
		super(props);

		this.state = {
			videoModalOpened: false
		}

		this.openVideoModal = this.openVideoModal.bind(this);
		this.closeVideoModal = this.closeVideoModal.bind(this);
	}

	componentDidMount() {
		const hash = window.location.hash;
		setTimeout(() => {
			if (hash) {
				const questionId = parseInt(hash.substring(1));
				this.scrollToQuestion(questionId);
			}
		}, 1000);
		window.localStorage.setItem('page_id', 'page.UrlName.FAQS');
	}

	availableForBtnClick(translatedLink) {
		history.push(`/${this.props.pageLanguage.toLowerCase()}/${translatedLink}`);
		GA.event('Faq Page', 'Take control of your fleet CTA');
	}

	openVideoModal() {
		this.setState({
			videoModalOpened: true
		});
		GA.event('Faq Page', 'Carousel 1 - Watch Fleetpulse in action.');
	}

	closeVideoModal() {
		this.setState({
			videoModalOpened: false
		});
	}

	scrollToQuestion(qid) {
		let offset = -100;

		const scrollOptions = { offset: offset, align: 'top', duration: 500 };

		switch(qid){
			case(1):
				scrollToComponent(this.One, scrollOptions);
				break;
			case(2):
				scrollToComponent(this.Two, scrollOptions);
				break;
			case(3):
				scrollToComponent(this.Three, scrollOptions);
				break;
			case(4):
				scrollToComponent(this.Four, scrollOptions);
				break;
			case(5):
				scrollToComponent(this.Five, scrollOptions);
				break;
			case(6):
				scrollToComponent(this.Six, scrollOptions);
				break;
			case(7):
				scrollToComponent(this.Seven, scrollOptions);
				break;
			case(8):
				scrollToComponent(this.Eight, scrollOptions);
				break;
			case(9):
				scrollToComponent(this.Nine, scrollOptions);
				break;
			case(10):
				scrollToComponent(this.Ten, scrollOptions);
				break;
			case(11):
				scrollToComponent(this.Eleven, scrollOptions);
				break;
			case(12):
				scrollToComponent(this.Twelve, scrollOptions);
				break;
			case(13):
				scrollToComponent(this.Thirteen, scrollOptions);
				break;
			case(14):
				scrollToComponent(this.Fourteen, scrollOptions);
				break;
			case(15):
				scrollToComponent(this.Fifteen, scrollOptions);
				break;
			case(16):
				scrollToComponent(this.Sixteen, scrollOptions);
				break;
			case(17):
				scrollToComponent(this.Seventeen, scrollOptions);
				break;
			case(18):
				scrollToComponent(this.Eighteen, scrollOptions);
				break;
			case(19):
				scrollToComponent(this.Nineteen, scrollOptions);
				break;
			case(20):
				scrollToComponent(this.Twenty, scrollOptions);
				break;
			case(21):
				scrollToComponent(this.TwentyOne, scrollOptions);
				break;
			case(22):
				scrollToComponent(this.TwentyTwo, scrollOptions);
				break;
			case(23):
				scrollToComponent(this.TwentyThree, scrollOptions);
				break;
			case(24):
				scrollToComponent(this.TwentyFour, scrollOptions);
				break;
			case(25):
				scrollToComponent(this.TwentyFive, scrollOptions);
				break;
			case(26):
				scrollToComponent(this.TwentySix, scrollOptions);
				break;
			case(27):
				scrollToComponent(this.TwentySeven, scrollOptions);
				break;
			case(28):
				scrollToComponent(this.TwentyEight, scrollOptions);
				break;
			case(29):
				scrollToComponent(this.TwentyNine, scrollOptions);
				break;
			case(30):
				scrollToComponent(this.Thirty, scrollOptions);
				break;
			case(31):
				scrollToComponent(this.ThirtyOne, scrollOptions);
				break;
			case(32):
				scrollToComponent(this.ThirtyTwo, scrollOptions);
				break;
			case(33):
				scrollToComponent(this.ThirtyThree, scrollOptions);
				break;
			case(34):
				scrollToComponent(this.ThirtyFour, scrollOptions);
				break;
			case(35):
				scrollToComponent(this.ThirtyFive, scrollOptions);
				break;
			default:
				console.log(`cannot map qid ${qid} to section anchor`);
		}
	}

	getSelectedSection() {
		const hash = window.location.hash;
		if (hash) {
			const questionId = parseInt(hash.substring(1));
			// console.log('Item id:', questionId);
			if (questionId > 0 && questionId < 2) {
				return 1;
			}
			if (questionId > 1 && questionId < 4) {
				return 2;
			}
			if (questionId > 3 && questionId < 12) {
				return 3;
			}
			if (questionId > 11 && questionId < 16) {
				return 4;
			}
			if (questionId > 15 && questionId < 27) {
				return 5;
			}
			if (questionId > 26 && questionId < 29) {
				return 6;
			}
			if (questionId > 28 && questionId <= 35) {
				return 7;
			}
		}
		return 0;
	}

	render() {
        const { ...props } = this.props;
		const { ...state } = this.state;

		const selectedSectionId = this.getSelectedSection();
		// console.log(`sectionId ${selectedSectionId}`);

		const videoLink = <FormattedMessage {...messages.watchVideo}>{msg => <span id={'video-open-link'} className="link" onClick={this.openVideoModal}>{msg}</span>}</FormattedMessage>;

		return (
			<PageLayout
				pageName='faqs'
				metaTitle={messages.metaTitle}
				metaDescription={messages.metaDescription}
				metaKeywords={messages.metaKeywords}
			>
				<LanguageSelector
					languages={[
						{ value: 'EN', label: 'EN' },
						{ value: 'FR', label: 'FR' },
						{ value: 'DE', label: 'DE' },
						{ value: 'ES', label: 'ES' },
						{ value: 'IT', label: 'IT' },
                		{ value: 'PL', label: 'PL' }
					]}
					className="hide-mobile language-selector"
				/>
				<FaqPageWrapper>
					<FaqPageHeaderWrapper>
						<FaqPageHeader><FormattedMessage {...messages.faqPageHeader}>{msg => msg}</FormattedMessage></FaqPageHeader>
					</FaqPageHeaderWrapper>
					<FaqSectionContainer sectionid={'s1'} title={messages.faq1section} selected={ selectedSectionId === 1 }>
						<FaqContainer
							questionGA='Q1 Behind fleetpulse'
							question={messages.faq1question}
							answer={messages.faq1answer}
							scrollRef={(section) => { this.One = section; }}
							questionid={'q1'}
						/>
					</FaqSectionContainer>
					<FaqSectionContainer sectionid={'s2'} title={messages.faq2section} selected={ selectedSectionId === 2 }>
						<FaqContainer
							questionGA='Q2 Benefits fleetpulse'
							question={messages.faq2question}
							answer={messages.faq2answer}
							scrollRef={(section) => { this.Two = section; }}
							questionid={'q2'}
						/>
						<FaqContainer
							questionGA='Q3 Main features fleetpulse'
							question={messages.faq3question}
							answer={messages.faq3answer}
							scrollRef={(section) => { this.Three = section; }}
							questionid={'q3'}
						/>
					</FaqSectionContainer>
					<FaqSectionContainer sectionid={'s3'} title={messages.faq3section} selected={ selectedSectionId === 3 }>
						<FaqContainer
							questionGA='Q4 Start using fleetpulse'
							question={messages.faq4question}
							answer={messages.faq4answer}
							scrollRef={(section) => { this.Four = section; }}
							questionid={'q4'}
						/>
						<FaqContainer
							questionGA='Q5 How do I use TPMS'
							question={messages.faq5question}
							answer={messages.faq5answer}
							scrollRef={(section) => { this.Five = section; }}
							questionid={'q5'}
						/>
						<FaqContainer
							questionGA='Q6 Vehicle types in fleetpulse'
							question={messages.faq6question}
							answer={messages.faq6answer}
							scrollRef={(section) => { this.Six = section; }}
							questionid={'q6'}
						/>
						<FaqContainer
							questionGA='Q7 Languages available in fleetPulse'
							question={messages.faq7question}
							answer={messages.faq7answer}
							scrollRef={(section) => { this.Seven = section; }}
							questionid={'q7'}
						/>
						<FaqContainer
							questionGA='Q8 Operating system'
							question={messages.faq8question}
							answer={messages.faq8answer}
							scrollRef={(section) => { this.Eight = section; }}
							questionid={'q8'}
						/>
						<FaqContainer
							questionGA='Q9 View the online dashboard in phone'
							question={messages.faq9question}
							answer={messages.faq9answer}
							scrollRef={(section) => { this.Nine = section; }}
							questionid={'q9'}
						/>
						<FaqContainer
							questionGA='Q10 Phone type to use TPMS'
							question={messages.faq10question}
							answer={messages.faq10answer}
							scrollRef={(section) => { this.Ten = section; }}
							questionid={'q10'}
						/>
						<FaqContainer
							questionGA='Q11 Provide phone to drivers'
							question={messages.faq11question}
							answer={messages.faq11answer}
							scrollRef={(section) => { this.Eleven = section; }}
							questionid={'q11'}
						/>
					</FaqSectionContainer>
					<FaqSectionContainer sectionid={'s4'} title={messages.faq4section} selected={ selectedSectionId === 4 }>
						{/* <FaqContainer
							questionGA='Q12 Free trial length'
							question={messages.faq12question}
							answer={messages.faq12answer}
							scrollRef={(section) => { this.Twelve = section; }}
							questionid={'q12'}
						/>
						<FaqContainer
							questionGA='Q13 Limit number of vehicles during free trial'
							question={messages.faq13question}
							answer={messages.faq13answer}
							scrollRef={(section) => { this.Thirteen = section; }}
							questionid={'q13'}
						/> */}
						<FaqContainer
							questionGA='Q14 How much is fleetpulse'
							question={messages.faq14question}
							answer={messages.faq14answer}
							scrollRef={(section) => { this.Fourteen = section; }}
							questionid={'q14'}
						/>
						<FaqContainer
							questionGA='Q15 TPMS sensors and dongles cost'
							question={messages.faq15question}
							answer={messages.faq15answer}
							scrollRef={(section) => { this.Fifteen = section; }}
							questionid={'q15'}
						/>
					</FaqSectionContainer>
					<FaqSectionContainer sectionid={'s5'} title={messages.faq5section} selected={ selectedSectionId === 5 }>
						<FaqContainer
							questionGA='Q16 Customise vehicle checklists'
							question={messages.faq16question}
							answer={messages.faq16answer}
							scrollRef={(section) => { this.Sixteen = section; }}
							questionid={'q16'}
							/>
						<FaqContainer
							questionGA='Q17 Do drivers need to complete checklist to use app?'
							question={messages.faq17question}
							answer={messages.faq17answer}
							scrollRef={(section) => { this.Seventeen = section; }}
							questionid={'q17'}
							/>
						<FaqContainer
							questionGA='Q18 Do fleets need to buy hardware to user fleetpulse'
							question={messages.faq18question}
							answer={messages.faq18answer}
							scrollRef={(section) => { this.Eighteen = section; }}
							questionid={'q18'}
							/>
						<FaqContainer
							questionGA='Q19 Use app for checklists or tyre pressure only'
							question={messages.faq19question}
							answer={messages.faq19answer}
							scrollRef={(section) => { this.Nineteen = section; }}
							questionid={'q19'}
							/>
						<FaqContainer
							questionGA='Q20 Buy fleetpulse and TPMS hardware'
							question={messages.faq20question}
							answer={messages.faq20answer}
							scrollRef={(section) => { this.Twenty = section; }}
							questionid={'q20'}
							/>
						<FaqContainer
							questionGA='Q21 Wheel and rim type'
							question={messages.faq21question}
							answer={messages.faq21answer}
							scrollRef={(section) => { this.TwentyOne = section; }}
							questionid={'q21'}
							/>
						<FaqContainer
							questionGA='Q22 How to fit sensors'
							question={messages.faq22question}
							answer={messages.faq22answer}
							scrollRef={(section) => { this.TwentyTwo = section; }}
							openVideoModal={videoLink}
							questionid={'q22'}
							/>
						<FaqContainer
							questionGA='Q23 Sensors battery life'
							question={messages.faq23question}
							answer={messages.faq23answer}
							scrollRef={(section) => { this.TwentyThree = section; }}
							questionid={'q23'}
							/>
						<FaqContainer
							questionGA='Q24 Fleetpulse new functionalities'
							question={messages.faq24question}
							answer={messages.faq24answer}
							scrollRef={(section) => { this.TwentyFour = section; }}
							questionid={'q24'}
							/>
						<FaqContainer
							questionGA='Q25 Integration with other systems'
							question={messages.faq25question}
							answer={messages.faq25answer}
							scrollRef={(section) => { this.TwentyFive = section; }}
							questionid={'q25'}
							/>
						<FaqContainer
							questionGA='Q26 Export and save data from fleetpulse'
							question={messages.faq26question}
							answer={messages.faq26answer}
							scrollRef={(section) => { this.TwentySix = section; }}
							questionid={'q26'}
							/>
					</FaqSectionContainer>
					<FaqSectionContainer sectionid={'s6'} title={messages.faq6section} selected={ selectedSectionId === 6 }>
						<FaqContainer
							questionGA='Q27 Fleetpulse storage space'
							question={messages.faq27question}
							answer={messages.faq27answer}
							scrollRef={(section) => { this.TwentySeven = section; }}
							questionid={'q27'}
						/>
						<FaqContainer
							questionGA='Q28 Usage of mobile data'
							question={messages.faq28question}
							answer={messages.faq28answer}
							scrollRef={(section) => { this.TwentyEight = section; }}
							questionid={'q28'}
						/>
					</FaqSectionContainer>
					<FaqSectionContainer sectionid={'s7'} title={messages.faq7section} selected={ selectedSectionId === 7 }>
						{/* <FaqContainer
							questionGA='Q29 Compliance with Legal requirements'
							question={messages.faq29question}
							answer={messages.faq29answer}
							scrollRef={(section) => { this.TwentyNine = section; }}
							questionid={'q29'}
						/>
						<FaqContainer
							questionGA='Q30 Security of data in fleetpulse'
							question={messages.faq30question}
							answer={messages.faq30answer}
							scrollRef={(section) => { this.Thirty = section; }}
							questionid={'q30'}
						/> */}
						<FaqContainer
							questionGA='Q31 Compliant with European agencies'
							question={messages.faq31question}
							answer={messages.faq31answer}
							scrollRef={(section) => { this.ThirtyOne = section; }}
							questionid={'q31'}
						/>
						<FaqContainer
							questionGA='Q32 How secure is the data'
							question={messages.faq32question}
							answer={messages.faq32answer}
							scrollRef={(section) => { this.ThirtyTwo = section; }}
							questionid={'q32'}
						/>
						<FaqContainer
							questionGA='Q33 How is the data transmitted'
							question={messages.faq33question}
							answer={messages.faq33answer}
							scrollRef={(section) => { this.ThirtyThree = section; }}
							questionid={'q33'}
						/>
						<FaqContainer
							questionGA='Q34 How long do you retain the gathered data'
							question={messages.faq34question}
							answer={messages.faq34answer}
							scrollRef={(section) => { this.ThirtyFour = section; }}
							questionid={'q34'}
						/>
						<FaqContainer
							questionGA='Q35 Who, beside the customer itself, has access to the data'
							question={messages.faq35question}
							answer={messages.faq35answer}
							scrollRef={(section) => { this.ThirtyFive = section; }}
							questionid={'q35'}
						/>
					</FaqSectionContainer>
				</FaqPageWrapper>
				<FormattedMessage {...pathnames.signUp}>
				{ msg =>
					<AvailableFor
						topText={<FormattedMessage {...messages.availableForTopCopy}>{msg => msg}</FormattedMessage>}
						buttonText={<FormattedMessage {...messages.availableForBtnCopy}>{msg => msg}</FormattedMessage>}
						buttonOnClick={this.availableForBtnClick.bind(this, msg)}
						buttonId={'faq-cta-btn'}
						bottomTextLinkId={'faq-cta-link'}
					/>
				}
				</FormattedMessage>

				{
					state.videoModalOpened &&
					<VideoModal
						link="https://www.youtube.com/embed/EDvfn_OWYVc"
						closeModal={this.closeVideoModal}
					/>
				}
			</PageLayout>
		);
	}
}

// Listen to store value
const mapStateToProps = createStructuredSelector({
	pageLanguage: selector.pageLanguage()
});

export default connect(mapStateToProps, null)(Faqs);
