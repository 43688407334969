// React imports
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import Slider from "react-slick";
import TrackVisibility from 'react-on-screen';

// Components
import { Button } from './../../components/Buttons';

// Import Translations
import messages from './messages';
import pathnames from './../../routing-messages';

// Import Helpers
import history from './../../history';
import GA from './../../ga';

// Import selectors
import * as selector from './selectors';

// Assets
import './styles.css';
import howItWorks1 from './../../images/how-it-works/1.gif';
import howItWorksExtanded1 from './../../images/how-it-works/1-extanded.gif';
import howItWorks2 from './../../images/how-it-works/2.gif';
import howItWorksExtanded2 from './../../images/how-it-works/2-extanded.gif';
import howItWorks3 from './../../images/how-it-works/3.gif';
import howItWorksExtanded3 from './../../images/how-it-works/3-extanded.gif';
import howItWorks4 from './../../images/how-it-works/4.gif';
import howItWorksExtanded4 from './../../images/how-it-works/4-extanded.gif';
import howItWorks5 from './../../images/how-it-works/5.gif';
import howItWorksExtanded5 from './../../images/how-it-works/5-extanded.gif';
import howItWorks6 from './../../images/how-it-works/6.gif';
import howItWorksExtanded6 from './../../images/how-it-works/6-extanded.gif';


/* Container */
class HowItWorks extends Component {
	constructor(props) {
		super(props);
		this.state = {
			stepOpened: false,
			stepId: 0,
			isVisible: false
		}

		this.myRef = React.createRef();
	}

	isBottom(el) {
		return el.getBoundingClientRect().top <= window.innerHeight;
	}

	componentDidMount() {
		document.addEventListener('scroll', this.trackScrolling);
	}

	componentWillUnmount() {
		document.removeEventListener('scroll', this.trackScrolling);
	}

	trackScrolling = () => {
		const wrappedElement = document.getElementById('how-it-works');
		if (this.isBottom(wrappedElement)) {
			document.removeEventListener('scroll', this.trackScrolling);
			this.setState({
				isVisible: true
			});
		} else {
			this.setState({
				isVisible: false
			});
		}
	};

	buttonOnClick(translatedLink) {
		history.push(`/${this.props.pageLanguage.toLowerCase()}/${translatedLink}`);
		GA.event('Homepage', 'Explore FleetPulse features CTA');
	}

	showMore(itemId) {
		this.setState({
			stepOpened: !this.state.stepOpened,
			stepId: this.state.stepId != 0 ? 0 : itemId
		});
		window.scrollTo(0, this.myRef.current.offsetTop);

		if(!this.state.stepOpened && itemId === 1) {
			GA.event('Homepage', 'Expand - TPMS');
		}
		if(!this.state.stepOpened && itemId === 2) {
			GA.event('Homepage', 'Expand - Notifications - managers');
		}
		if(!this.state.stepOpened && itemId === 3) {
			GA.event('Homepage', 'Expand - Centralised view');
		}
		if(!this.state.stepOpened && itemId === 4) {
			GA.event('Homepage', 'Expand - Managing vehicles');
		}
		if(!this.state.stepOpened && itemId === 5) {
			GA.event('Homepage', 'Expand - Notifications - driver');
		}
		if(!this.state.stepOpened && itemId === 6) {
			GA.event('Homepage', 'Expand - Simplicity');
		}
	}

	render() {
		const { ...props } = this.props;
		const { ...state } = this.state;

		const settings = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: state.stepOpened ? false : true,
			mobileFirst: true,
			draggable: state.stepOpened ? false : true,
			swipe: state.stepOpened ? false : true,
			responsive: [
				{
				  breakpoint: 940,
				},
				{
				  breakpoint: 100000,
				  settings: "unslick"
				}
		  	]
		};

		const item = [
			{
				id: 1,
				image: howItWorks1,
				imageExtanded: howItWorksExtanded1,
				imageAlt: <FormattedMessage {...messages.howItWorks1ImageAlt}>{msg => msg}</FormattedMessage>,
				excerpt: <FormattedMessage {...messages.howItWorks1Excerpt}>{msg => msg}</FormattedMessage>,
				copy: <FormattedMessage {...messages.howItWorks1Copy}>{msg => msg}</FormattedMessage>
			},
			{
				id: 2,
				image: howItWorks2,
				imageExtanded: howItWorksExtanded2,
				imageAlt: <FormattedMessage {...messages.howItWorks2ImageAlt}>{msg => msg}</FormattedMessage>,
				excerpt: <FormattedMessage {...messages.howItWorks2Excerpt}>{msg => msg}</FormattedMessage>,
				copy: <FormattedMessage {...messages.howItWorks2Copy}>{msg => msg}</FormattedMessage>
			},
			{
				id: 3,
				image: howItWorks3,
				imageExtanded: howItWorksExtanded3,
				imageAlt: <FormattedMessage {...messages.howItWorks3ImageAlt}>{msg => msg}</FormattedMessage>,
				excerpt: <FormattedMessage {...messages.howItWorks3Excerpt}>{msg => msg}</FormattedMessage>,
				copy: <FormattedMessage {...messages.howItWorks3Copy}>{msg => msg}</FormattedMessage>
			},
			{
				id: 4,
				image: howItWorks4,
				imageExtanded: howItWorksExtanded4,
				imageAlt: <FormattedMessage {...messages.howItWorks6ImageAlt}>{msg => msg}</FormattedMessage>,
				excerpt: <FormattedMessage {...messages.howItWorks6Excerpt}>{msg => msg}</FormattedMessage>,
				copy: <FormattedMessage {...messages.howItWorks6Copy}>{msg => msg}</FormattedMessage>
			},
			{
				id: 5,
				image: howItWorks5,
				imageExtanded: howItWorksExtanded5,
				imageAlt: <FormattedMessage {...messages.howItWorks5ImageAlt}>{msg => msg}</FormattedMessage>,
				excerpt: <FormattedMessage {...messages.howItWorks5Excerpt}>{msg => msg}</FormattedMessage>,
				copy: <FormattedMessage {...messages.howItWorks5Copy}>{msg => msg}</FormattedMessage>
			},
			{
				id: 6,
				image: howItWorks6,
				imageExtanded: howItWorksExtanded6,
				imageAlt: <FormattedMessage {...messages.howItWorks4ImageAlt}>{msg => msg}</FormattedMessage>,
				excerpt: <FormattedMessage {...messages.howItWorks4Excerpt}>{msg => msg}</FormattedMessage>,
				copy: <FormattedMessage {...messages.howItWorks4Copy}>{msg => msg}</FormattedMessage>
			},
		];

		return (
			<section id="how-it-works" className={`section section-01 how-it-works ${state.isVisible  ? 'section-active' : ''}`} ref={this.myRef}>
				<div className="section-content">
					<div className="section-decor">
						<span>01</span>
					</div>
					<header className="section-header">
						<h3 className="title">
							<FormattedMessage {...messages.sectionTitle}>{msg => msg}</FormattedMessage>
						</h3>
					</header>
					<div className="inner-content">
						<div className={`how-it-works-content ${state.stepOpened ? 'opened' : ''}`}>
							<Slider {...settings}>
								{
									item.map(item =>
										<div className={`how-it-works-item item-${item.id} ${state.stepId === item.id ? 'item-active' : ''}`} key={item.id}>
											<div className="image">
												<img src={state.stepOpened ? item.imageExtanded : item.image} alt={item.imageAlt} />
												<span className="show-more" onClick={this.showMore.bind(this, item.id)}></span>
											</div>
											<div className="content">
												<div className="id">
													{item.id}/6
												</div>
												{
													state.stepId === item.id ?
													<div className="copy">
														{item.copy}
													</div>
													:
													<div className="copy">
														{item.excerpt}
													</div>
												}
											</div>
										</div>
									)
								}
							</Slider>
						</div>
						<FormattedMessage {...pathnames.features}>
							{ msg =>
								<Button id='how-it-works-cta-btn' onClick={this.buttonOnClick.bind(this, msg)} className={'red'} >
									<FormattedMessage {...messages.sectionBtnCtaCopy}>{msg => msg}</FormattedMessage>
								</Button>
							}
						</FormattedMessage>
					</div>
				</div>
			</section>
		);
	}
}

// Listen to store value
const mapStateToProps = createStructuredSelector({
	pageLanguage: selector.pageLanguage()
});

export default connect(mapStateToProps, null)(HowItWorks);
