import styled from 'styled-components';

// Styles
export const FaqSection = styled.div`
    color: #20272A;
    font-style: normal;
    font-weight: 300;
    text-align: left;
    width: 100%;
    padding-bottom: 10px;

    @media screen and (min-width: 941px) {
        padding-bottom: 30px;
    }
`
export const FaqTitle = styled.h3`
    border-bottom: 1px solid #CCCCCC;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 20px !important;
    padding: 15px 0;
    position: relative;
    padding-right: 40px;

    @media screen and (min-width: 941px) {
        font-size: 40px;
    }
`
