import { defineMessages } from 'react-intl';

export default defineMessages({
	home: {
		id: 'page.Nav.Home',
		defaultMessage: 'Home Page'
	},
	features: {
		id: 'page.Nav.Features',
		defaultMessage: 'Features'
	},
	tmps: {
		id: 'page.Nav.TPMS',
		defaultMessage: 'TPMS'
	},
	faqs: {
		id: 'page.Nav.FAQs',
		defaultMessage: 'FAQs'
	},
	logIn: {
		id: 'page.Nav.LogIn',
		defaultMessage: 'Log in'
	},
	signUp: {
		id: 'page.Nav.SignUp',
		defaultMessage: 'Sign up'
	}
});