import { defineMessages } from 'react-intl';

export default defineMessages({
	metaTitle: {
		id: 'page.TPMS.Meta.Title',
		defaultMessage: 'FleetPulse Tyre Pressure Monitoring System (TPMS) | Bridgestone'
	},
	metaDescription: {
		id: 'page.TPMS.Meta.Description',
		defaultMessage: 'Discover how tyre pressuring monitoring works and how it can reduce maintenance costs. Our TPMS sensors let you wirelessly take pressure readings and run your fleet at optimum tyre pressure levels, for fewer breakdowns andmore time on the road.'
	},
	metaKeywords: {
		id: 'page.TPMS.Meta.Keywords',
		defaultMessage: 'TPMS, Bridgestone, how does tyre pressure monitoringwork, TPMS sensors, breakdown'
	},

	headerTitle: {
		id: 'page.Tpms.Header.Title',
		defaultMessage: 'The simplest way to monitor tyre pressure for a more efficient fleet'
	},
	headerSubTitle: {
		id: 'page.Tpms.Header.SubTitle',
		defaultMessage: 'Tyre Pressure Monitoring System (TPMS)'
	},
	headerBtnCopy: {
		id: 'page.Tpms.Header.BtnCopy',
		defaultMessage: 'Start Using FleetPulse'
	},

	section01Statistic1: {
		id: 'page.Tpms.Section01.Statistic1',
		defaultMessage: 'of breakdowns are caused by low tyre pressure'
	},
	section01Statistic2: {
		id: 'page.Tpms.Section01.Statistic2',
		defaultMessage: 'of tyre related breakdowns are caused by slow leakers'
	},
	section01Copy: {
		id: 'page.Tpms.Section01.Copy',
		defaultMessage: 'And yet <strong>20<small>%</small></strong> of commercial tyres on the road are significantly underinflated'
	},

	section02MobileTeaserTitle: {
		id: 'page.Tpms.Section02.MobileTeaser.Title',
		defaultMessage: 'TPMS makes keeping your tyre pressure at optimum levels incredibly easy – reducing your fleet’s tyre costs, fuel consumption and number of breakdowns.'
	},
	section02MobileTeaserBox1Copy: {
		id: 'page.Tpms.Section02.MobileTeaser.Box1.Copy',
		defaultMessage: 'Using Tirematics sensing technology, it automatically records tyre pressure as your drivers complete their daily walk arounds. If any readings fall outside theset range, they’ll get an alert and you’ll get an email so that immediate action can be taken.<br/><br/>Practical and cost-effective, TPMS helps you spot issues earlier to keep your fleet on the road for longer.'
	},
	section02MobileTeaserItem1: {
		id: 'page.Tpms.Section03.MobileTeaser.Item1',
		defaultMessage: 'A centralised</br>website for you'
	},
	section02MobileTeaserItem2: {
		id: 'page.Tpms.Section04.MobileTeaser.Item2',
		defaultMessage: 'An app for</br>your drivers'
	},
	section02MobileTeaserItem3: {
		id: 'page.Tpms.Section05.MobileTeaser.Item3',
		defaultMessage: 'TPMS hardware</br>for your vehicles'
	},

	section03Box1Title: {
		id: 'page.Tpms.Section03.Box1.Title',
		defaultMessage: 'Our TPMS pays for itself within one year'
	},
	section03Box1Copy: {
		id: 'page.Tpms.Section03.Box1.Copy',
		defaultMessage: 'A typical tractor and trailer combination running at the correct tyre pressure can save you, on average, up to:'
	},
	section03Box1OtherCopy: {
		id: 'page.Tpms.Section03.Box1.OtherCopy',
		defaultMessage: 'With prices starting at just €329 for tractors and €199 for trailers, our TPMS easily pays for itself within one year. See here for example prices.'
	},
	section03Box1Item1: {
		id: 'page.Tpms.Section03.Box1.Item1',
		defaultMessage: '<strong>€500</strong> a year in fuel consumption'
	},
	section03Box1Item2: {
		id: 'page.Tpms.Section03.Box1.Item2',
		defaultMessage: '<strong>€200</strong> a year in tyre costs'
	},
	section03Box1Item3: {
		id: 'page.Tpms.Section03.Box1.Item3',
		defaultMessage: '<strong>€800</strong> over two years in breakdown costs'
	},

	section04Box1Title: {
		id: 'page.Tpms.Section04.Box1.Title',
		defaultMessage: 'The benefits of FleetPulse TPMS'
	},
	section04Box1Item1: {
		id: 'page.Tpms.Section04.Box1.Item1',
		defaultMessage: 'TPMS sensors easily fit on each tyre'
	},
	section04Box1Item2: {
		id: 'page.Tpms.Section04.Box1.Item2',
		defaultMessage: 'Three year battery life (even with daily usage)'
	},
	section04Box1Item3: {
		id: 'page.Tpms.Section04.Box1.Item3',
		defaultMessage: 'Works with all commercial tyre brands'
	},
	section04Box1Item4: {
		id: 'page.Tpms.Section04.Box1.Item4',
		defaultMessage: 'Seamlessly fits into drivers daily walkarounds'
	},

	section05Title: {
		id: 'page.Tpms.Section05.Title',
		defaultMessage: 'What you’ll need'
	},
	section05Box1Title: {
		id: 'page.Tpms.Section05.Box1.Title',
		defaultMessage: 'TPMS Box'
	},
	section05Box1Copy: {
		id: 'page.Tpms.Section05.Box1.Copy',
		defaultMessage: 'To use TPMS within the app, you’ll need to buy one of our TPMS boxes which contain everything you need to get started – including Tirematics sensors and a FleetPulse dongle. These will soon be available online and at selected dealers in UK, France, Spain, Belgium and Germany but contact us if would like to buy them now.'
	},
	section05Box1BtnCopy: {
		id: 'page.Tpms.Section05.Box1.BtnCopy',
		defaultMessage: 'Contact us'
	},
	section05Box2Title: {
		id: 'page.Tpms.Section05.Box2.Title',
		defaultMessage: 'Accessories'
	},
	section05Box2Copy: {
		id: 'page.Tpms.Section05.Box2.Copy',
		defaultMessage: 'You can also buy extra sensors, dongles, clamps (for both steel and aluminium alcoa-type rims), plus extra fitting tools if you need them.'
	},
	section05Box2BtnCopy: {
		id: 'page.Tpms.Section05.Box2.BtnCopy',
		defaultMessage: 'Contact us'
	},

	section06Title: {
		id: 'page.Tpms.Section06.Title',
		defaultMessage: 'How it work'
	},
	section06Box1Title: {
		id: 'page.Tpms.Section06.Box1.Title',
		defaultMessage: 'Getting started'
	},
	section06Box1Copy: {
		id: 'page.Tpms.Section06.Box1.Copy',
		defaultMessage: 'Add a TPMS sensor to each tyre. Then, using the FleetPulse app and dongle, simply tap on your phone to configure the sensors to that vehicle – this only needs to be done once.'
	},
	section06Box1BtnCopy: {
		id: 'page.Tpms.Section06.Box1.BtnCopy',
		defaultMessage: 'Watch Fleetpulse in action'
	},
	section06Box2Title: {
		id: 'page.Tpms.Section06.Box2.Title',
		defaultMessage: 'Taking a readin'
	},
	section06Box2Copy: {
		id: 'page.Tpms.Section06.Box2.Copy',
		defaultMessage: 'Drivers open the FleetPulse app and plug the dongle into their phone. Whenthey walk around their vehicle, the app and sensors wirelessly connect and readings are automatically taken.'
	},
	section06Box3Title: {
		id: 'page.Tpms.Section06.Box3.Title',
		defaultMessage: 'Alerts'
	},
	section06Box3Copy: {
		id: 'page.Tpms.Section06.Box3.Copy',
		defaultMessage: 'If the tyre pressure is outside the ideal range, the app will alert the driver andsend you an email. The range can be set and changed within the app.'
	},
	section06Box4Title: {
		id: 'page.Tpms.Section06.Box4.Title',
		defaultMessage: 'Automatic upload'
	},
	section06Box4Copy: {
		id: 'page.Tpms.Section06.Box4.Copy',
		defaultMessage: 'When the driver’s phone has signal or connects to WiFi, the readings are automatically uploaded to your FleetPulse dashboard, so you can remotely monitor and analyse the data in real-time.'
	},

	availableForTopCopy: {
		id: 'page.AvailableFor.Copy',
		defaultMessage: 'Available for the following vehicle types'
	},
	availableForBtnCopy: {
		id: 'page.AvailableFor.BtnCopy',
		defaultMessage: 'Start using FleetPulse'
	}
});