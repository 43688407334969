// React libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SlideDown } from 'react-slidedown'

// Import Components
import { ExpandCollapseButton } from './../ExpandCollapseButton'

// Import Styled Components
 import { FaqSection, FaqTitle } from './styles';

/* Component */
export const FaqSectionComponent = props => (    
    <FaqSection>
        { 
            props.showButton ?
            <FaqTitle id={`section-${props.sectionid ? props.sectionid : 0}`} onClick={props.expand}>
                <FormattedMessage {...props.title}></FormattedMessage>
                <ExpandCollapseButton 
                    elementid={props.sectionid}
                    sectionButton
                    expanded={props.expanded}
                />
            </FaqTitle> 
            :
            <FaqTitle>
                <FormattedMessage {...props.title}></FormattedMessage>
            </FaqTitle>
        }
        <SlideDown>
            {props.expanded && props.children}
        </SlideDown> 
    </FaqSection>
);
