import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

// Component
import { FaqComponent } from './../../components/Faq';

// Import Helpers
import GA from './../../ga';


/** Container */
export class FaqContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };

        this.expand = this.expand.bind(this);
    }

    expand() {
        if (!this.state.expanded) {
            GA.event('FAQs Page (Q&A)', `Expand - ${this.props.questionGA}`);
        } else {
            GA.event('FAQs Page (Q&A)', `Collapse - ${this.props.questionGA}`);
        }

        this.setState({
            expanded: !this.state.expanded
        });
    }

	render() {
        const { ...props } = this.props;
        const { ...state } = this.state;
		
		return (
            <FaqComponent 
                scrollRef={props.scrollRef}
                question={props.question}
                answer={props.answer}
                expanded={state.expanded}
                expand={this.expand}
                gaOnClick={props.gaOnClick}
                openVideoModal={props.openVideoModal}
                questionid={props.questionid}
            />
		);
	}
}

export default FaqContainer;
