// Needed for redux-saga es6 generator support
import 'babel-polyfill';

// React imports
import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'react-router-redux';
import { Provider } from 'react-redux';

// Import other component, containers, functions
import history from './history';
import initializeStore from './configureStore';
// import registerServiceWorker from './registerServiceWorker';
import { translationMessages } from './i18n';
import LanguageProvider from './containers/LanguageProvider';
import { load } from './utils/localStorage';
import * as helper from './helpers';
import './global-styles';

// Import pages
import AppRouting from './routing';


// Create default constants
const 
    initialState = load(helper.appStoreName),
    store = initializeStore(initialState, history),
    APP_CONTAINER = document.getElementById('app');


ReactDOM.render(
    <Provider store={store}>
        <LanguageProvider messages={translationMessages} >
            <ConnectedRouter history={history}>
                <AppRouting />
            </ConnectedRouter>
        </LanguageProvider>
    </Provider>,
    APP_CONTAINER
);

  
// registerServiceWorker();