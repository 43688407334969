/*
 * This is tricky one ; )
 * Selectors are functions to select state from
 * our redux store. It's help us clean code and
 * end write less on our components. 
 * 
 * For example;
 * 
 * const selectGlobal = state => state.get('global');
 * const makeSelectLoading = () => createSelector(
 *    selectGlobal,
 *    globalState => globalState.get('loading')
 * );
 * export {
 *    selectGlobal,
 *    makeSelectLoading
 * };
 * Gets from store object coled global
 * then search there loading object.
 */
import { createSelector } from 'reselect';


const selectPageLanguage = state => state.get('language');


// SELECTOR - pageLanguage
export const pageLanguage = () => createSelector(
    selectPageLanguage,
    languageState => languageState.get('name')
);