import React, { Component } from 'react';

// Component
import { FaqSectionComponent } from './../../components/FaqSection';


/** Container */
export class FaqSectionContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            showButton: false,
            collapsedAllForMobile: false
        };

        this.expand = this.expand.bind(this);
    }

	componentDidMount() {
        window.addEventListener('resize', this.setStateBasedOnScreenSizeAndPreselection.bind(this));
        this.setStateBasedOnScreenSizeAndPreselection();
    }

    componentWillUnmount() {
		window.removeEventListener('resize', this.setStateBasedOnScreenSizeAndPreselection.bind(this));
	}

    setStateBasedOnScreenSizeAndPreselection(e) {
        if (window.innerWidth >= 940 || this.props.selected) {
            this.setState({ 
                expanded: true
            });
        } else {
            if (!this.state.collapsedAllForMobile) {
                this.setState({ 
                    expanded: false,
                    collapsedAllForMobile: true
                });
            }
        }
        if (window.innerWidth <= 940) {
            this.setState({ 
                showButton: true
            });
        } else {
            this.setState({ 
                showButton: false
            });
        }
	}

    expand() {
        this.setState({
            expanded: !this.state.expanded
        });
    }

	render() {
        const { ...props } = this.props;
        const { ...state } = this.state;
		
		return (
            <FaqSectionComponent 
                title={props.title}
                expanded={state.expanded}
                showButton={state.showButton}
                expand={this.expand}
                sectionid={props.sectionid}
            >
            {props.children}
            </FaqSectionComponent>
		);
	}
}

export default FaqSectionContainer;
