import { defineMessages } from 'react-intl';

export default defineMessages({
	metaTitle: {
		id: 'page.Features.Meta.Title',
		defaultMessage: 'FleetPulse features and benefits for simplified fleet management | Bridgestone'
	},
	metaDescription: {
		id: 'page.Features.Meta.Description',
		defaultMessage: 'Intuitive features streamline the vehicle inspection process for drivers, Fleet Managers and Maintenance Managers. And makes vehicle maintenance easier as you can easily spot reoccurring issues and organise service bookings.'
	},
	metaKeywords: {
		id: 'page.Features.Meta.Keywords',
		defaultMessage: 'fleet management, Bridgestone, Fleet Manager,Maintenance Manager, vehicle maintenance, service bookings'
	},

	headerTitle: {
		id: 'page.Features.Header.Title',
		defaultMessage: 'From streamlining daily inspections to managing defects and service bookings, FleetPulse has everything your fleet needs'
	},
	headerSubTitle: {
		id: 'page.Features.Header.SubTitle',
		defaultMessage: 'Top features'
	},
	headerBtnCopy: {
		id: 'page.Features.Header.BtnCopy',
		defaultMessage: 'Start Using FleetPulse'
	},

	featuresIcon1: {
		id: 'page.Features.Icon1.Name',
		defaultMessage: 'Vehicle  Checklists'
	},
	featuresIcon2: {
		id: 'page.Features.Icon2.Name',
		defaultMessage: 'Vehicle  Management'
	},
	featuresIcon3: {
		id: 'page.Features.Icon3.Name',
		defaultMessage: 'Service bookings'
	},
	featuresIcon4: {
		id: 'page.Features.Icon4.Name',
		defaultMessage: 'TPMS'
	},

	section01Title: {
		id: 'page.Features.Section01.Title',
		defaultMessage: 'Vehicle Checklists'
	},
	section01Subtitle: {
		id: 'page.Features.Section01.Subtitle',
		defaultMessage: 'Make sure your vehicles are roadworthy'
	},
	section01Box1Title: {
		id: 'page.Features.Section01.Box1.Title',
		defaultMessage: 'INTUITIVE CHECKLISTS'
	},
	section01Box1Item1: {
		id: 'page.Features.Section01.Box1.Item1',
		defaultMessage: 'Easy for drivers to complete daily checks and flag defects'
	},
	section01Box1Item2: {
		id: 'page.Features.Section01.Box1.Item2',
		defaultMessage: 'Receive automated alerts about any issues'
	},
	section01Box1Item3: {
		id: 'page.Features.Section01.Box1.Item3',
		defaultMessage: 'Customise checklists to suit your needs (add, remove or rename fields)'
	},
	section01Box1Item4: {
		id: 'page.Features.Section01.Box1.Item4',
		defaultMessage: 'Paperless audit trail'
	},

	section02Title: {
		id: 'page.Features.Section02.Title',
		defaultMessage: 'Vehicle management'
	},
	section02Subtitle: {
		id: 'page.Features.Section02.Subtitle',
		defaultMessage: 'Monitor issues from a centralised point and review past checklists and defects'
	},
	section02Box1Title: {
		id: 'page.Features.Section02.Box1.Title',
		defaultMessage: 'Online dashboard'
	},
	section02Box1Item1: {
		id: 'page.Features.Section02.Box1.Item1',
		defaultMessage: 'View and analyse your fleet in real-time'
	},
	section02Box1Item2: {
		id: 'page.Features.Section02.Box1.Item2',
		defaultMessage: 'Check how many vehicles are on the road'
	},
	section02Box1Item3: {
		id: 'page.Features.Section02.Box1.Item3',
		defaultMessage: 'View upcoming service bookings'
	},
	section02Box1Item4: {
		id: 'page.Features.Section02.Box1.Item4',
		defaultMessage: 'See number of current defects'
	},
	section02Box2Title: {
		id: 'page.Features.Section02.Box2.Title',
		defaultMessage: 'VEHICLE DEFECT MANAGEMENT'
	},
	section02Box2Item1: {
		id: 'page.Features.Section02.Box2.Item1',
		defaultMessage: 'Drivers can flag problems with notes and photos'
	},
	section02Box2Item2: {
		id: 'page.Features.Section02.Box2.Item2',
		defaultMessage: 'Quickly identify reoccurring defects'
	},
	section02Box2Item3: {
		id: 'page.Features.Section02.Box2.Item3',
		defaultMessage: 'Mark issues as resolved when fixed'
	},
	section02Box2Item4: {
		id: 'page.Features.Section01.Box2.Item4',
		defaultMessage: 'See full history of each vehicle'
	},

	section03Title: {
		id: 'page.Features.Section03.Title',
		defaultMessage: 'Service bookings'
	},
	section03Subtitle: {
		id: 'page.Features.Section03.Subtitle',
		defaultMessage: 'Schedule services more efficiently'
	},
	section03Box1Title: {
		id: 'page.Features.Section03.Box1.Title',
		defaultMessage: 'ADD SERVICE APPOINTMENTS'
	},
	section03Box1Item1: {
		id: 'page.Features.Section03.Box1.Item1',
		defaultMessage: 'See all live defects to arrange services in priority order'
	},
	section03Box1Item2: {
		id: 'page.Features.Section03.Box1.Item2',
		defaultMessage: 'Automatically send the driver booking details'
	},
	section03Box1Item3: {
		id: 'page.Features.Section03.Box1.Item3',
		defaultMessage: 'Add notes for the driver to share with the mechanic'
	},

	section04Title: {
		id: 'page.Features.Section04.Title',
		defaultMessage: 'Tyre Pressure Monitoring System'
	},
	section04Box1Title: {
		id: 'page.Features.Section04.Box1.Title',
		defaultMessage: 'Want to monitor your tyre pressure too?'
	},
	section04Box1Copy: {
		id: 'page.Features.Section04.Box1.Copy',
		defaultMessage: 'Reduce maintenance costs, breakdown expenses and fuel inefficiencies even more with FleetPulse TPMS.'
	},
	section04Box1BtnCopy: {
		id: 'page.Features.Section04.Box1.BtnCopy',
		defaultMessage: 'Show me TPMS'
	},

	availableForTopCopy: {
		id: 'page.AvailableFor.Copy',
		defaultMessage: 'Available for the following vehicle types'
	},
	availableForBtnCopy: {
		id: 'page.AvailableFor.BtnCopy',
		defaultMessage: 'Start using FleetPulse'
	}
});