// React librarys
import React from "react";
import Select from "react-select";
import { FormattedMessage } from "react-intl";
import { countryCodeOptions } from "./../../helpers";

// Styles
import "./style.css";

// Import Meta and Messages
import messages from "./messages";

const CountryCodeSelector = props => {
  const onCountryCodeChange = selectedValue => {
    props.selectOnChange("countryFlagCode", selectedValue);
  };

  const value = props.countryFlagCode
    ? props.countryFlagCode
    : props.initialCountryFlagCode;

  if (props.initialCountryFlagCode && !props.countryFlagCode) {
    props.selectOnChange("countryFlagCode", props.initialCountryFlagCode);
  }

  return (
    <div className="country-code-selector">
      {/* <label>
        <FormattedMessage {...messages.phoneCountryCode}>
          {i => i}
        </FormattedMessage>{" "}
        {props.required ? "*" : ""}
      </label> */}
      {/* <div className="right"> */}
      {/* {props.withHide && !props.selectDisabled && (
          <div className="input-text">
            {props.countryPhoneCode ? props.countryPhoneCode : "-"}
          </div>
        )} */}
      {/* {props.withHide && props.selectDisabled && (
          <Select
            className={`select ${
              props.countryPhoneCodeError != "" ? "select-error" : ""
            }`}
            clearable={false}
            value={props.countryFlagCode}
            onChange={onCountryCodeChange}
            options={countryCodeOptionsWithFlag}
            placeholder={
              <FormattedMessage {...messages.selectPlaceholder}>
                {i => i}
              </FormattedMessage>
            }
          />
        )} */}
      {/* {!props.withHide && (
          <Select
            className={`select ${
              props.countryPhoneCodeError != "" ? "select-error" : ""
            }`}
            clearable={false}
            value={props.countryFlagCode}
            onChange={onCountryCodeChange}
            options={countryCodeOptionsWithFlag}
            placeholder={
              <FormattedMessage {...messages.selectPlaceholder}>
                {i => i}
              </FormattedMessage>
            }
          />
        )} */}
      {/* {props.countryFlagCode ? (
          <img
            className={"country-flag"}
            src={`/flags/${props.countryFlagCode}.svg`}
          />
        ) : (
          <span className="no-flag">?</span>
        )} */}
      <Select
        className={`select ${
          props.countryPhoneCodeError != "" ? "select-error" : ""
        }`}
        clearable={false}
        value={value}
        onChange={onCountryCodeChange}
        options={countryCodeOptions}
      />
      {value && <img className={"country-flag"} src={`/flags/${value}.svg`} />}
      {props.countryPhoneCodeError != "" && (
        <span className="select-error-msg select-error-msg-ccs">
          <FormattedMessage {...messages.errorRequired}>
            {i => i}
          </FormattedMessage>
        </span>
      )}
      {/* </div> */}
    </div>
  );
};

export default CountryCodeSelector;
