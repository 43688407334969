// React libraries
import React from 'react';
// Import Styled Components
 import { ExpandCollapseButtonWrapper, HorizontalLine, VerticalLine } from './styles';

/* Component */
export const ExpandCollapseButton = props => (
    <ExpandCollapseButtonWrapper id={`expand-button-${props.elementid? props.elementid : 0}`} sectionButton={props.sectionButton}>
        <HorizontalLine/>
        {   
            !props.expanded &&
            <VerticalLine/>
        }
    </ExpandCollapseButtonWrapper>
);
